// change\
.change{
    .container {
        .row-change {
          .col-change {
            .title-change {
              margin-bottom: 40px;
              .title-content {
                font-family: "Montserrat", sans-serif;
                font-size: 30px;
                font-weight: bold;
                color: #272727;
                .title-content-1 {
                  color: #1572a1;
                }
              }
            }
            .card-change {
              border: none;
              .card-body {
                margin-left: -15px;
                margin-top: -20px;
                width: 78%;
                
                .old-password {
                  margin-top: 10px;
                  .form-label {
                    font-family: "Nunito Sans", sans-serif;
                    font-size: 16px;
                    color: #0c0c0c;
                    font-weight: 600;
                  }
                  .grup-password {
                    border-radius: 10px;
                    .form-password {
                      border: 1px solid #1572a1;
                      border-right: none;
                      border-top-left-radius: 10px;
                      border-bottom-left-radius: 10px;
                    }
                    .eye {
                      padding-top: 6px;
                      padding-left: 9px;
                      padding-right: 9px;
                      border-left: none;
                      border: 1px solid #1572a1;
                      border-top-right-radius: 10px;
                      border-bottom-right-radius: 10px;
                    }
                    .eye1 {
                      padding-top: 6px;
                      padding-left: 8px;
                      padding-right: 8px;
                      border-left: none;
                      border: 1px solid #1572a1;
                      border-top-right-radius: 10px;
                      border-bottom-right-radius: 10px;
                    }
                  }
                }

                .new-password {
                    margin-top: 10px;
                    .form-label {
                      font-family: "Nunito Sans", sans-serif;
                      font-size: 16px;
                      color: #0c0c0c;
                      font-weight: 600;
                    }
                    .grup-password {
                      border-radius: 10px;
                      .form-password {
                        border: 1px solid #1572a1;
                        border-right: none;
                        border-top-left-radius: 10px;
                        border-bottom-left-radius: 10px;
                      }
                      .eye {
                        padding-top: 6px;
                        padding-left: 9px;
                        padding-right: 9px;
                        border-left: none;
                        border: 1px solid #1572a1;
                        border-top-right-radius: 10px;
                        border-bottom-right-radius: 10px;
                      }
                      .eye1 {
                        padding-top: 6px;
                        padding-left: 8px;
                        padding-right: 8px;
                        border-left: none;
                        border: 1px solid #1572a1;
                        border-top-right-radius: 10px;
                        border-bottom-right-radius: 10px;
                      }
                    }
                  }

                  .confirm-password {
                    margin-top: 10px;
                    .form-label {
                      font-family: "Nunito Sans", sans-serif;
                      font-size: 16px;
                      color: #0c0c0c;
                      font-weight: 600;
                    }
                    .grup-password {
                      border-radius: 10px;
                      .form-password {
                        border: 1px solid #1572a1;
                        border-right: none;
                        border-top-left-radius: 10px;
                        border-bottom-left-radius: 10px;
                      }
                      .eye {
                        padding-top: 6px;
                        padding-left: 9px;
                        padding-right: 9px;
                        border-left: none;
                        border: 1px solid #1572a1;
                        border-top-right-radius: 10px;
                        border-bottom-right-radius: 10px;
                      }
                      .eye1 {
                        padding-top: 6px;
                        padding-left: 8px;
                        padding-right: 8px;
                        border-left: none;
                        border: 1px solid #1572a1;
                        border-top-right-radius: 10px;
                        border-bottom-right-radius: 10px;
                      }
                    }
                  }

                
                .row-btn {
                    margin-top: 20px;
                  .col-btn {
                    .button-change {
                      .btn-change {
                        background-color: #1572a1;
                        width: 100%;
                        border-radius: 10px;
                        .title-change {
                          font-family: "Nunito Sans", sans-serif;
                          color: #f9f9f9;
                          font-size: 16px;
                          font-weight: bold;
                        }
                      }
                      .btn-change:hover {  
                        background-color: #064463;
                        width: 100%;
                        border-radius: 10px;
                        .title-change {
                          font-family: "Nunito Sans", sans-serif;
                          color: #f9f9f9;
                          font-size: 16px;
                          font-weight: bold;
                        }
                      }
                    }
                  }
                  .col-btn {
                    .button-signup {
                      .btn-signup {
                        background-color: #f6f6f6;
                        width: 100%;
                        border-radius: 10px;
                        border: 1px solid #1572a1;
                        .title-signup {
                            text-decoration: none;
                            font-family: "Nunito Sans", sans-serif;
                            color: #1572a1;
                            font-size: 16px;
                            font-weight: bold;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .col-img-change {
            .img-change {
              .img-change-content {
                height: relative;
                width: 100%;
              }
            }
          }
        }
    }
}


/*reponsive*/
@media screen and (min-width: 240px) and (max-width: 580px) {
// change
.change{
    .container {
        .row-change {
          margin-top: -40px;
          .col-change {
            .title-change {
              margin-bottom: 40px;
              .title-content {
                font-family: "Montserrat", sans-serif;
                font-size: 30px;
                font-weight: bold;
                color: #272727;
                text-align: center;
                .title-content-1 {
                  color: #1572a1;
                }
              }
            }
            .card-change {
              border: none;
              width: 100%;
              .card-body {
                margin-top: -20px;
                width: 90%;
                margin: 0 auto;

                .old-password {
                  margin-top: 30px;
                  .form-label {
                    font-family: "Nunito Sans", sans-serif;
                    font-size: 16px;
                    color: #0c0c0c;
                    font-weight: 600;
                  }
                  .grup-password {
                    border-radius: 10px;
                    .form-password {
                      border: 1px solid #1572a1;
                      border-right: none;
                      border-top-left-radius: 10px;
                      border-bottom-left-radius: 10px;
                    }
                    .eye {
                      padding-top: 6px;
                      padding-left: 9px;
                      padding-right: 9px;
                      border-left: none;
                      border: 1px solid #1572a1;
                      border-top-right-radius: 10px;
                      border-bottom-right-radius: 10px;
                    }
                    .eye1 {
                      padding-top: 6px;
                      padding-left: 8px;
                      padding-right: 8px;
                      border-left: none;
                      border: 1px solid #1572a1;
                      border-top-right-radius: 10px;
                      border-bottom-right-radius: 10px;
                    }
                  }
                }

                .new-password {
                    margin-top: 30px;
                    .form-label {
                      font-family: "Nunito Sans", sans-serif;
                      font-size: 16px;
                      color: #0c0c0c;
                      font-weight: 600;
                    }
                    .grup-password {
                      border-radius: 10px;
                      .form-password {
                        border: 1px solid #1572a1;
                        border-right: none;
                        border-top-left-radius: 10px;
                        border-bottom-left-radius: 10px;
                      }
                      .eye {
                        padding-top: 6px;
                        padding-left: 9px;
                        padding-right: 9px;
                        border-left: none;
                        border: 1px solid #1572a1;
                        border-top-right-radius: 10px;
                        border-bottom-right-radius: 10px;
                      }
                      .eye1 {
                        padding-top: 6px;
                        padding-left: 8px;
                        padding-right: 8px;
                        border-left: none;
                        border: 1px solid #1572a1;
                        border-top-right-radius: 10px;
                        border-bottom-right-radius: 10px;
                      }
                    }
                  }

                  .confirm-password {
                    margin-top: 30px;
                    .form-label {
                      font-family: "Nunito Sans", sans-serif;
                      font-size: 16px;
                      color: #0c0c0c;
                      font-weight: 600;
                    }
                    .grup-password {
                      border-radius: 10px;
                      .form-password {
                        border: 1px solid #1572a1;
                        border-right: none;
                        border-top-left-radius: 10px;
                        border-bottom-left-radius: 10px;
                      }
                      .eye {
                        padding-top: 6px;
                        padding-left: 9px;
                        padding-right: 9px;
                        border-left: none;
                        border: 1px solid #1572a1;
                        border-top-right-radius: 10px;
                        border-bottom-right-radius: 10px;
                      }
                      .eye1 {
                        padding-top: 6px;
                        padding-left: 8px;
                        padding-right: 8px;
                        border-left: none;
                        border: 1px solid #1572a1;
                        border-top-right-radius: 10px;
                        border-bottom-right-radius: 10px;
                      }
                    }
                  }
            
                .row-btn {
                  .col-btn {
                    .button-change {
                      .btn-change {
                        background-color: #1572a1;
                        width: 100%;
                        border-radius: 10px;
                        margin-bottom: 10px;
                        .title-change {
                          font-family: "Nunito Sans", sans-serif;
                          color: #f9f9f9;
                          font-size: 16px;
                          font-weight: bold;
                        }
                      }
                      .btn-change:hover {
                        background-color: #064463;
                        width: 100%;
                        border-radius: 10px;
                        .title-change {
                          font-family: "Nunito Sans", sans-serif;
                          color: #f9f9f9;
                          font-size: 16px;
                          font-weight: bold;
                        }
                      }
                    }
                  }
                  .col-btn {
                    .button-signup {
                      .btn-signup {
                        background-color: #f6f6f6;
                        width: 100%;
                        border-radius: 10px;
                        border: 1px solid #1572a1;
                        .title-signup {
                          .sign-up {
                            text-decoration: none;
                            font-family: "Nunito Sans", sans-serif;
                            color: #1572a1;
                            font-size: 16px;
                            font-weight: bold;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .col-img-change {
            .img-change {
              .img-change-content {
                height: 400px;
                width: 100%;
                padding-left: 5px;
                padding-right: 5px;
                padding-top: 20px;
                display: block;
              }
            }
          }
        }
  }
}
}
  
@media screen and (min-width: 580px) and (max-width: 750px) {
// change
.change{
    .container {
        .row-change {
          .col-change {
            .title-change {
              margin-bottom: 40px;
              .title-content {
                font-family: "Montserrat", sans-serif;
                font-size: 30px;
                font-weight: bold;
                color: #272727;
                text-align: center;
                .title-content-1 {
                  color: #1572a1;
                }
              }
            }
            .card-change {
              border: none;
              width: 100%;
              .card-body {
                margin-top: -20px;
                width: 90%;
                margin: 0 auto;
               
                .old-password {
                  margin-top: 30px;
                  .form-label {
                    font-family: "Nunito Sans", sans-serif;
                    font-size: 16px;
                    color: #0c0c0c;
                    font-weight: 600;
                  }
                  .grup-password {
                    border-radius: 10px;
                    .form-password {
                      border: 1px solid #1572a1;
                      border-right: none;
                      border-top-left-radius: 10px;
                      border-bottom-left-radius: 10px;
                    }
                    .eye {
                      padding-top: 6px;
                      padding-left: 9px;
                      padding-right: 9px;
                      border-left: none;
                      border: 1px solid #1572a1;
                      border-top-right-radius: 10px;
                      border-bottom-right-radius: 10px;
                    }
                    .eye1 {
                      padding-top: 6px;
                      padding-left: 8px;
                      padding-right: 8px;
                      border-left: none;
                      border: 1px solid #1572a1;
                      border-top-right-radius: 10px;
                      border-bottom-right-radius: 10px;
                    }
                  }
                }
               
                .new-password {
                    margin-top: 30px;
                    .form-label {
                      font-family: "Nunito Sans", sans-serif;
                      font-size: 16px;
                      color: #0c0c0c;
                      font-weight: 600;
                    }
                    .grup-password {
                      border-radius: 10px;
                      .form-password {
                        border: 1px solid #1572a1;
                        border-right: none;
                        border-top-left-radius: 10px;
                        border-bottom-left-radius: 10px;
                      }
                      .eye {
                        padding-top: 6px;
                        padding-left: 9px;
                        padding-right: 9px;
                        border-left: none;
                        border: 1px solid #1572a1;
                        border-top-right-radius: 10px;
                        border-bottom-right-radius: 10px;
                      }
                      .eye1 {
                        padding-top: 6px;
                        padding-left: 8px;
                        padding-right: 8px;
                        border-left: none;
                        border: 1px solid #1572a1;
                        border-top-right-radius: 10px;
                        border-bottom-right-radius: 10px;
                      }
                    }
                  }

                  .confirm-password {
                    margin-top: 30px;
                    .form-label {
                      font-family: "Nunito Sans", sans-serif;
                      font-size: 16px;
                      color: #0c0c0c;
                      font-weight: 600;
                    }
                    .grup-password {
                      border-radius: 10px;
                      .form-password {
                        border: 1px solid #1572a1;
                        border-right: none;
                        border-top-left-radius: 10px;
                        border-bottom-left-radius: 10px;
                      }
                      .eye {
                        padding-top: 6px;
                        padding-left: 9px;
                        padding-right: 9px;
                        border-left: none;
                        border: 1px solid #1572a1;
                        border-top-right-radius: 10px;
                        border-bottom-right-radius: 10px;
                      }
                      .eye1 {
                        padding-top: 6px;
                        padding-left: 8px;
                        padding-right: 8px;
                        border-left: none;
                        border: 1px solid #1572a1;
                        border-top-right-radius: 10px;
                        border-bottom-right-radius: 10px;
                      }
                    }
                  }

                .row-btn {
                  .col-btn {
                    .button-change {
                      .btn-change {
                        background-color: #1572a1;
                        width: 100%;
                        border-radius: 10px;
                        margin-bottom: 10px;
                        .title-change {
                          font-family: "Nunito Sans", sans-serif;
                          color: #f9f9f9;
                          font-size: 16px;
                          font-weight: bold;
                        }
                      }
                      .btn-change:hover {
                        background-color: #064463;
                        width: 100%;
                        border-radius: 10px;
                        .title-change {
                          font-family: "Nunito Sans", sans-serif;
                          color: #f9f9f9;
                          font-size: 16px;
                          font-weight: bold;
                        }
                      }
                    }
                  }
                  .col-btn {
                    .button-signup {
                      .btn-signup {
                        background-color: #f6f6f6;
                        width: 100%;
                        border-radius: 10px;
                        border: 1px solid #1572a1;
                        .title-signup {
                          .sign-up {
                            text-decoration: none;
                            font-family: "Nunito Sans", sans-serif;
                            color: #1572a1;
                            font-size: 16px;
                            font-weight: bold;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .col-img-change {
            .img-change {
              .img-change-content {
                height: 320px;
                width: 100%;
                padding-left: 50px;
                padding-right: 50px;
                padding-top: 20px;
                display: block;
              }
            }
          }
        }
  }
}
}
  
@media screen and (min-width: 750px) and (max-width: 1199px) {
// change
.change{
    .container {
        .row-change {
          .col-change {
            .title-change {
              margin-bottom: 40px;
              .title-content {
                font-family: "Montserrat", sans-serif;
                font-size: 30px;
                font-weight: bold;
                color: #272727;
                text-align: center;
                .title-content-1 {
                  color: #1572a1;
                }
              }
            }
            .card-change {
              border: none;
              width: 100%;
              .card-body {
                margin-top: -20px;
                width: 90%;
                margin: 0 auto;
                
                .old-password {
                  margin-top: 30px;
                  .form-label {
                    font-family: "Nunito Sans", sans-serif;
                    font-size: 16px;
                    color: #0c0c0c;
                    font-weight: 600;
                  }
                  .grup-password {
                    border-radius: 10px;
                    .form-password {
                      border: 1px solid #1572a1;
                      border-right: none;
                      border-top-left-radius: 10px;
                      border-bottom-left-radius: 10px;
                    }
                    .eye {
                      padding-top: 6px;
                      padding-left: 9px;
                      padding-right: 9px;
                      border-left: none;
                      border: 1px solid #1572a1;
                      border-top-right-radius: 10px;
                      border-bottom-right-radius: 10px;
                    }
                    .eye1 {
                      padding-top: 6px;
                      padding-left: 8px;
                      padding-right: 8px;
                      border-left: none;
                      border: 1px solid #1572a1;
                      border-top-right-radius: 10px;
                      border-bottom-right-radius: 10px;
                    }
                  }
                }

                .new-password {
                    margin-top: 30px;
                    .form-label {
                      font-family: "Nunito Sans", sans-serif;
                      font-size: 16px;
                      color: #0c0c0c;
                      font-weight: 600;
                    }
                    .grup-password {
                      border-radius: 10px;
                      .form-password {
                        border: 1px solid #1572a1;
                        border-right: none;
                        border-top-left-radius: 10px;
                        border-bottom-left-radius: 10px;
                      }
                      .eye {
                        padding-top: 6px;
                        padding-left: 9px;
                        padding-right: 9px;
                        border-left: none;
                        border: 1px solid #1572a1;
                        border-top-right-radius: 10px;
                        border-bottom-right-radius: 10px;
                      }
                      .eye1 {
                        padding-top: 6px;
                        padding-left: 8px;
                        padding-right: 8px;
                        border-left: none;
                        border: 1px solid #1572a1;
                        border-top-right-radius: 10px;
                        border-bottom-right-radius: 10px;
                      }
                    }
                  }

                  .confirm-password {
                    margin-top: 30px;
                    .form-label {
                      font-family: "Nunito Sans", sans-serif;
                      font-size: 16px;
                      color: #0c0c0c;
                      font-weight: 600;
                    }
                    .grup-password {
                      border-radius: 10px;
                      .form-password {
                        border: 1px solid #1572a1;
                        border-right: none;
                        border-top-left-radius: 10px;
                        border-bottom-left-radius: 10px;
                      }
                      .eye {
                        padding-top: 6px;
                        padding-left: 9px;
                        padding-right: 9px;
                        border-left: none;
                        border: 1px solid #1572a1;
                        border-top-right-radius: 10px;
                        border-bottom-right-radius: 10px;
                      }
                      .eye1 {
                        padding-top: 6px;
                        padding-left: 8px;
                        padding-right: 8px;
                        border-left: none;
                        border: 1px solid #1572a1;
                        border-top-right-radius: 10px;
                        border-bottom-right-radius: 10px;
                      }
                    }
                  }


               
                .row-btn {
                  .col-btn {
                    .button-change {
                      .btn-change {
                        background-color: #1572a1;
                        width: 100%;
                        border-radius: 10px;
                        margin-bottom: 10px;
                        .title-change {
                          font-family: "Nunito Sans", sans-serif;
                          color: #f9f9f9;
                          font-size: 16px;
                          font-weight: bold;
                        }
                      }
                      .btn-change:hover {
                        background-color: #064463;
                        width: 100%;
                        border-radius: 10px;
                        .title-change {
                          font-family: "Nunito Sans", sans-serif;
                          color: #f9f9f9;
                          font-size: 16px;
                          font-weight: bold;
                        }
                      }
                    }
                  }
                  .col-btn {
                    .button-signup {
                      .btn-signup {
                        background-color: #f6f6f6;
                        width: 100%;
                        border-radius: 10px;
                        border: 1px solid #1572a1;
                        .title-signup {
                          .sign-up {
                            text-decoration: none;
                            font-family: "Nunito Sans", sans-serif;
                            color: #1572a1;
                            font-size: 16px;
                            font-weight: bold;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .col-img-change {
            .img-change {
              .img-change-content {
                height: 420px;
                width: 100%;
                padding-top: 20px;
                display: block;
              }
            }
          }
        }
  }
}  
} 
  
