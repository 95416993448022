/**footer*/
.footer {
    height: 250px;
    width: 100%;
    background-color: #e3f6ff;
    margin-top: 150px;
    padding-top: 50px;
    .footer-content {
      .container {
        .row-footer {
          .col-footer-1 {
            .title-footer1 {
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              margin-left: 33px;
              color: #272727;
            }
            .list1 li {
              list-style: none;
              .list-content {
                text-decoration: none;
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: -0.019em;
                color: #999999;
              }
            }
          }
          .col-footer-2 {
            .title-footer2 {
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              margin-left: 33px;
              color: #272727;
            }
            .list2 li {
              list-style: none;
              .list-content {
                text-decoration: none;
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: -0.019em;
                color: #999999;
              }
            }
          }
          .col-footer-3 {
            .title-footer3 {
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              margin-left: 33px;
              color: #272727;
            }
            .list3 li {
              list-style: none;
              .list-content {
                text-decoration: none;
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: -0.019em;
                color: #999999;
              }
            }
          }
        }
      }
    }
    .footer-last {
      height: 30px;
      margin-top: 20px;
      width: 100%;
      background-color: #1572a1;
      text-align: center;
      .title-last {
        font-family: "Nunito Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #ffffff;
      }
    }
}

/*reponsive*/
@media screen and (min-width: 240px) and (max-width: 580px) {
/*footer*/
.footer {
      text-align: center;
      height: 100%;
      width: 100%;
      background-color: #e3f6ff;
      margin-top: 150px;
      padding-top: 40px;
      .footer-content {
        .container {
          padding-right: 30px;
          .row-footer {
            .col-footer-1 {
              .title-footer1 {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                color: #272727;
              }
              .list1 li {
                list-style: none;
                .list-content {
                  text-decoration: none;
                  font-family: "Nunito Sans";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 12px;
                  color: #999999;
                }
              }
            }
            .col-footer-2 {
              .title-footer2 {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                color: #272727;
              }
              .list2 li {
                list-style: none;
                .list-content {
                  text-decoration: none;
                  font-family: "Nunito Sans";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 12px;
                  color: #999999;
                }
              }
            }
            .col-footer-3 {
              .title-footer3 {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                color: #272727;
              }
              .list3 li {
                list-style: none;
                .list-content {
                  text-decoration: none;
                  font-family: "Nunito Sans";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 12px;
                  color: #999999;
                }
              }
            }
            .col-footer-4 {
              .maps {
                padding-left: 20px;
                padding-right: 0;
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
      .footer-last {
        height: 30px;
        margin-top: 20px;
        width: 100%;
        background-color: #1572a1;
        text-align: center;
        .title-last {
          font-family: "Nunito Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #ffffff;
        }
      }
}
}
  
@media screen and (min-width: 580px) and (max-width: 750px) {
/*footer*/
.footer {
      text-align: center;
      height: 100%;
      width: 100%;
      background-color: #e3f6ff;
      margin-top: 150px;
      padding-top: 40px;
      .footer-content {
        .container {
          padding-right: 30px;
          .row-footer {
            .col-footer-1 {
              .title-footer1 {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                color: #272727;
              }
              .list1 li {
                list-style: none;
                .list-content {
                  text-decoration: none;
                  font-family: "Nunito Sans";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 12px;
                  color: #999999;
                }
              }
            }
            .col-footer-2 {
              .title-footer2 {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                color: #272727;
              }
              .list2 li {
                list-style: none;
                .list-content {
                  text-decoration: none;
                  font-family: "Nunito Sans";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 12px;
                  color: #999999;
                }
              }
            }
            .col-footer-3 {
              .title-footer3 {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                color: #272727;
              }
              .list3 li {
                list-style: none;
                .list-content {
                  text-decoration: none;
                  font-family: "Nunito Sans";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 12px;
                  color: #999999;
                }
              }
            }
            .col-footer-4 {
              .maps {
                padding-left: 20px;
                padding-right: 0;
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
      .footer-last {
        height: 30px;
        margin-top: 20px;
        width: 100%;
        background-color: #1572a1;
        text-align: center;
        .title-last {
          font-family: "Nunito Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #ffffff;
        }
      }
}
}
  
@media screen and (min-width: 750px) and (max-width: 1199px) {
/*footer*/
.footer {
      text-align: center;
      height: 100%;
      width: 100%;
      background-color: #e3f6ff;
      margin-top: 150px;
      padding-top: 40px;
      .footer-content {
        .container {
          padding-right: 30px;
          .row-footer {
            .col-footer-1 {
              .title-footer1 {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                color: #272727;
              }
              .list1 li {
                list-style: none;
                .list-content {
                  text-decoration: none;
                  font-family: "Nunito Sans";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 12px;
                  color: #999999;
                }
              }
            }
            .col-footer-2 {
              .title-footer2 {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                color: #272727;
              }
              .list2 li {
                list-style: none;
                .list-content {
                  text-decoration: none;
                  font-family: "Nunito Sans";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 12px;
                  color: #999999;
                }
              }
            }
            .col-footer-3 {
              .title-footer3 {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                color: #272727;
              }
              .list3 li {
                list-style: none;
                .list-content {
                  text-decoration: none;
                  font-family: "Nunito Sans";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 12px;
                  color: #999999;
                }
              }
            }
            .col-footer-4 {
              .maps {
                padding-left: 20px;
                padding-right: 0;
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
      .footer-last {
        height: 30px;
        margin-top: 20px;
        width: 100%;
        background-color: #1572a1;
        text-align: center;
        .title-last {
          font-family: "Nunito Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #ffffff;
        }
      }
}
}
