// font-family: 'Montserrat', sans-serif;
// font-family: 'Nunito Sans', sans-serif;
// font-family: 'Poppins', sans-serif;

//spinn
.fp-container{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #ffffff;
  z-index: 1000;
  .fp-loader{
    top: 40%;
    left: 48%;
    z-index: 1000;
    position: absolute;
    
.swapping-squares-spinner, .swapping-squares-spinner * {
  box-sizing: border-box;
}

.swapping-squares-spinner {
  height: 65px;
  width: 65px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.swapping-squares-spinner .square {
  height: calc(65px * 0.25 / 1.3);
  width:  calc(65px * 0.25 / 1.3);
  animation-duration: 1000ms;
  border: calc(65px * 0.04 / 1.3) solid #044f9f;
  margin-right: auto;
  margin-left: auto;
  position: absolute;
  animation-iteration-count: infinite;
}

.swapping-squares-spinner .square:nth-child(1) {
  animation-name: swapping-squares-animation-child-1;
  animation-delay: 500ms;
}

.swapping-squares-spinner .square:nth-child(2) {
  animation-name: swapping-squares-animation-child-2;
  animation-delay: 0ms;
}

.swapping-squares-spinner .square:nth-child(3) {
  animation-name: swapping-squares-animation-child-3;
  animation-delay: 500ms;
}

.swapping-squares-spinner .square:nth-child(4) {
  animation-name: swapping-squares-animation-child-4;
  animation-delay: 0ms;
}

@keyframes swapping-squares-animation-child-1 {
  50% {
    transform: translate(150%,150%) scale(2,2);
  }
}

@keyframes swapping-squares-animation-child-2 {
  50% {
    transform: translate(-150%,150%) scale(2,2);
  }
}

@keyframes swapping-squares-animation-child-3 {
  50% {
    transform: translate(-150%,-150%) scale(2,2);
  }
}

@keyframes swapping-squares-animation-child-4 {
  50% {
    transform: translate(150%,-150%) scale(2,2);
  }
}

  }
}
//akhir spinn

.app {
  background-color: #ffffff;
  height: relative;
}

.error-text {
  color: #e92424;
}

/*reponsive*/
@media screen and (min-width: 240px) and (max-width: 580px) {
.fp-container{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #ffffff;
    z-index: 1000;
    .fp-loader{
      top: 45%;
      left: 40%;
      z-index: 1000;
      position: absolute;
      
  .swapping-squares-spinner, .swapping-squares-spinner * {
    box-sizing: border-box;
  }
  
  .swapping-squares-spinner {
    height: 65px;
    width: 65px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .swapping-squares-spinner .square {
    height: calc(65px * 0.25 / 1.3);
    width:  calc(65px * 0.25 / 1.3);
    animation-duration: 1000ms;
    border: calc(65px * 0.04 / 1.3) solid #044f9f;
    margin-right: auto;
    margin-left: auto;
    position: absolute;
    animation-iteration-count: infinite;
  }
  
  .swapping-squares-spinner .square:nth-child(1) {
    animation-name: swapping-squares-animation-child-1;
    animation-delay: 500ms;
  }
  
  .swapping-squares-spinner .square:nth-child(2) {
    animation-name: swapping-squares-animation-child-2;
    animation-delay: 0ms;
  }
  
  .swapping-squares-spinner .square:nth-child(3) {
    animation-name: swapping-squares-animation-child-3;
    animation-delay: 500ms;
  }
  
  .swapping-squares-spinner .square:nth-child(4) {
    animation-name: swapping-squares-animation-child-4;
    animation-delay: 0ms;
  }
  
  @keyframes swapping-squares-animation-child-1 {
    50% {
      transform: translate(150%,150%) scale(2,2);
    }
  }
  
  @keyframes swapping-squares-animation-child-2 {
    50% {
      transform: translate(-150%,150%) scale(2,2);
    }
  }
  
  @keyframes swapping-squares-animation-child-3 {
    50% {
      transform: translate(-150%,-150%) scale(2,2);
    }
  }
  
  @keyframes swapping-squares-animation-child-4 {
    50% {
      transform: translate(150%,-150%) scale(2,2);
    }
  }
  
    }
}
}

/*vacancy*/
.vacancy {
  margin-top: 80px;
  margin-bottom: 150px;
  .vacancy-content {
    .vacancy-title {
      .vacancy-title-1 {
        margin-top: 25px;
        font-family: "Nunito Sans", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 33px;
        color: #161616;
        .vacancy-garis {
          border-bottom: 3px solid #1572a1;
          width: relative;
        }
      }
    }

    .vac-not{
      text-align: center;
      margin-top: 50px;
      margin-bottom: -80px;
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 33px;
      color: #000000;
    }

    .row-wish-form{
      margin-top: 30px;
      margin-bottom: 30px;
      .col-wish-form{
        .wishlist-button{
          .btn-wishlist{
            background-color: #1572A1;
            border: none;
            border-radius: 5px;
            width: 100%;
            .text-wishlist{
              font-family: 'Nunito Sans';
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 19px;
              text-align: center;
              color: #EDEDED;
              padding-left:33px;
              padding-right:33px;
              margin-top: 5px;
              margin-bottom: 5px;
              position: relative;
              .img-wishlist{
                height: 30px;
              }
            }
       
          }
        }
        .form-vacancy {
          .row-vacancy {
            .col-vacancy-2 {
              margin-bottom: 10px;
              .search-2 {
                height: 40px;
                width: 100%;
                box-sizing: border-box;
                border: 2px solid #ccc;
                font-size: 14px;
                background-color: transparent;
                background-image: url("../img/location.png");
                background-position: 5px 5px;
                background-repeat: no-repeat;
                padding-left: 40px;
                -webkit-transition: width 0.4s ease-in-out;
                transition: width 0.4s ease-in-out;
              }
            }
            .col-vacancy-4 {
              .btn-search {
                width: 100%;
                background-color: #1572a1;
                border: none;
                height: 40px;
                .text-search {
                  color: #ededed;
                  font-size: 16px;
                  font-weight: bold;
                  font-family: "Nunito Sans", sans-serif;
                  margin-top: 5px;
                  margin-bottom: auto;
                  position: relative;
                }
              }
            }
          }
        }
      }
    }
  

    .row-vacancy-all {
      margin-top: 16px;
      .col-vacancy-all {
        .card-vacancy-all {
          padding: 10px;
          border: 2px solid #1572a1;
          border-radius: 10px;
          background-color: #eefaff;
          .card-content-vacancy {
           
            .img-icon {
             display: block;
             margin: 0 auto;
              width: 100%;
              height: 45px;
            }
          }
          .title {
            margin-top: 12px;
            .title-1 {
              font-family: "Nunito Sans";
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              color: #000000;
            }
            .title-2 {
              font-family: "Nunito Sans";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              color: #000000;
            }
          }
        }
        .card-vacancy-all:hover {
          padding: 10px;
          border: 2px solid #58bdf0;
          border-radius: 10px;
          background-color: #58bdf0;
          .card-content-vacancy {
            .img-icon {
             display: block;
             margin: 0 auto;
              width: 100%;
              height: 45px;
            }
          }
          .title {
            margin-top: 12px;
            .title-1 {
              font-family: "Nunito Sans";
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              color: #000000;
            }
            .title-2 {
              font-family: "Nunito Sans";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              color: #000000;
            }
          }
        }
       
      }
    }

    .row-vac-all {
      .col-vac-all {
        margin-top: 20px;
      
        .card-vac-all {
          border-bottom: 1px solid #1572a1;
          border-top: none;
          border-right: none;
          border-left: none;
          // border: 1px solid #1572a1;
          border-radius: 2px;
          height: 100%;
          padding-right: 5px;
          padding-left: 5px;
          padding-bottom: 5px;
          padding-top: 5px;
          
          .row-card-vac-all{
            .col-card-vac-all-icon{
              display: block;
              margin: auto 0;
              .card-content-vac {
                .img-vac {
               
                  width: 100%;
                  height: 45px;
                }
              }
            }
            .col-card-vac-all-text{
              .title-vac {
                .title-1 {
                  font-family: "Nunito Sans";
                  font-style: normal;
                  font-weight: 700;
                  font-size: 15px;
                  color: #000000;
                  margin-top: 14px;
                  text-decoration-line: none;
                  padding-left: 5px;
                }
            
                .title-2 {
                  font-family: "Nunito Sans";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 13px;
                  color: #9d9d9d;
                  margin-top: 16px;
                  .img-vac {
                    height: 22px;
                    margin-bottom: 2px;
                  }
                }
              }
            }
            .col-card-vac-all-btn{
              display: block;
              margin:auto 0;
              .btn-detail{
                border: none;
                background-color: #1572a1;
                .text-detail{
                  padding:3px 20px;
                  margin: 0 auto;
                  color: #fff;
                  font-family: "Nunito Sans";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 15px;
                }
              }
              .btn-detail:hover{
                border: none;
                background-color: #064463;
                .text-detail{
                  padding:3px 20px;
                  margin: 0 auto;
                  color: #fff;
                  font-family: "Nunito Sans";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 15px;
                }
              }
            }
          }
         
        
         
        }
      }
    }
  }
}

/*reponsive*/
@media screen and (min-width: 240px) and (max-width: 580px) {
/*vacancy*/
.vacancy {
margin-top: 80px;
margin-bottom: 150px;
.vacancy-content {
  .vacancy-title {
    .vacancy-title-1 {
      margin-top: 25px;
      font-family: "Nunito Sans", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 33px;
      color: #161616;
      .vacancy-garis {
        border-bottom: 3px solid #1572a1;
        width: relative;
      }
    }
  }

  .row-wish-form{
    margin-top: 30px;
    margin-bottom: 30px;
    .col-wish-form{
      margin-bottom: 10px;
      .wishlist-button{
        .btn-wishlist{
          background-color: #1572A1;
          border: none;
          border-radius: 5px;
          width: 100%;
          .text-wishlist{
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 19px;
            text-align: center;
            color: #EDEDED;
            padding-left:33px;
            padding-right:33px;
            margin-top: 5px;
            margin-bottom: 5px;
            position: relative;
            .img-wishlist{
              height: 30px;
            }
          }
     
        }
      }
      .form-vacancy {
        .row-vacancy {
          .col-vacancy-2 {
            margin-bottom: 10px;
            .search-2 {
              height: 40px;
              width: 100%;
              box-sizing: border-box;
              border: 2px solid #ccc;
              font-size: 14px;
              background-color: transparent;
              background-image: url("../img/location.png");
              background-position: 5px 5px;
              background-repeat: no-repeat;
              padding-left: 40px;
              -webkit-transition: width 0.4s ease-in-out;
              transition: width 0.4s ease-in-out;
            }
          }
          .col-vacancy-4 {
            .btn-search {
              width: 100%;
              background-color: #1572a1;
              border: none;
              height: 40px;
              .text-search {
                color: #ededed;
                font-size: 16px;
                font-weight: bold;
                font-family: "Nunito Sans", sans-serif;
                margin-top: 5px;
                margin-bottom: auto;
                position: relative;
              }
            }
          }
        }
      }
    }
  }


  .row-vacancy-all {
    margin-top: 16px;
    .col-vacancy-all {
      .card-vacancy-all {
        padding: 10px;
        border: 2px solid #1572a1;
        border-radius: 10px;
        background-color: #eefaff;
        margin-bottom: 25px;
        .card-content-vacancy {
          .img-icon {
           display: block;
           margin: 0 auto;
            width: 100%;
            height: 55px;
          }
        }
        .title {
          margin-top: 12px;
          .title-1 {
            font-family: "Nunito Sans";
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            color: #000000;
          }
          .title-2 {
            font-family: "Nunito Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #000000;
          }
        }
      }
      .card-vacancy-all:hover {
        padding: 10px;
        border: 2px solid #58bdf0;
        border-radius: 10px;
        background-color: #58bdf0;
        margin-bottom: 25px;
        .card-content-vacancy {
          .img-icon {
           display: block;
           margin: 0 auto;
            width: 100%;
            height: 55px;
          }
        }
        .title {
          margin-top: 12px;
          .title-1 {
            font-family: "Nunito Sans";
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            color: #000000;
          }
          .title-2 {
            font-family: "Nunito Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #000000;
          }
        }
      }
     
    }
  }

  .row-vac-all {
    .col-vac-all {
      margin-top: 20px;
      .card-vac-all {
        border-bottom: 1px solid #1572a1;
        border-top: none;
        border-right: none;
        border-left: none;
        border-radius: 2px;
        height: 100%;
        padding-right: 5px;
        padding-left: 5px;
        padding-bottom: 5px;
        padding-top: 5px;
        
        .row-card-vac-all{
          .col-card-vac-all-icon{
            .card-content-vac {
              .img-vac {
                width: 120px;
                height: 40px;
                display: block;
                margin: 0 auto;
                
              }
            }
          }
          .col-card-vac-all-text{
            .title-vac {
              text-align: center;
              .title-1 {
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 700;
                font-size: 15px;
                color: #000000;
                margin-top: 14px;
                text-decoration-line: none;
              
              }
          
              .title-2 {
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                color: #9d9d9d;
                margin-top: 16px;
                .img-vac {
                  height: 22px;
                  margin-bottom: 2px;
                }
              }
            }
          }
          .col-card-vac-all-btn{
          
            .btn-detail{
              display: block;
              margin:0 auto;
              border: none;
              width: 100px;
              margin-bottom: 10px;
              background-color: #1572a1;
              .text-detail{
                padding:5px 5px;
                margin: 0 auto;
                color: #fff;
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
              }
            }
            .btn-detail:hover{
              border: none;
              background-color: #064463;
              .text-detail{
                padding:5px 5px;
                margin: 0 auto;
                color: #fff;
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
              }
            }
          }
        }
       
      
       
      }
    }
  }
}
}
}

@media screen and (min-width: 580px) and (max-width: 768px) {
/*vacancy*/
.vacancy {
margin-top: 80px;
margin-bottom: 150px;
.vacancy-content {
  .vacancy-title {
    .vacancy-title-1 {
      margin-top: 25px;
      font-family: "Nunito Sans", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 33px;
      color: #161616;
      .vacancy-garis {
        border-bottom: 3px solid #1572a1;
        width: relative;
      }
    }
  }

  .row-wish-form{
    margin-top: 30px;
    margin-bottom: 30px;
    .col-wish-form{
      margin-bottom: 10px;
      .wishlist-button{
        .btn-wishlist{
          background-color: #1572A1;
          border: none;
          border-radius: 5px;
          width: 40%;
          .text-wishlist{
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 19px;
            text-align: center;
            color: #EDEDED;
            padding-left:33px;
            padding-right:33px;
            margin-top: 5px;
            margin-bottom: 5px;
            position: relative;
            .img-wishlist{
              height: 30px;
            }
          }
     
        }
      }
      .form-vacancy {
        .row-vacancy {
          .col-vacancy-2 {
            margin-bottom: 10px;
            .search-2 {
              height: 40px;
              width: 100%;
              box-sizing: border-box;
              border: 2px solid #ccc;
              font-size: 14px;
              background-color: transparent;
              background-image: url("../img/location.png");
              background-position: 5px 5px;
              background-repeat: no-repeat;
              padding-left: 40px;
              -webkit-transition: width 0.4s ease-in-out;
              transition: width 0.4s ease-in-out;
            }
          }
          .col-vacancy-4 {
            .btn-search {
              width: 100%;
              background-color: #1572a1;
              border: none;
              height: 40px;
              .text-search {
                color: #ededed;
                font-size: 16px;
                font-weight: bold;
                font-family: "Nunito Sans", sans-serif;
                margin-top: 5px;
                margin-bottom: auto;
                position: relative;
              }
            }
          }
        }
      }
    }
  }


  .row-vacancy-all {
    margin-top: 16px;
    .col-vacancy-all {
      .card-vacancy-all {
        padding: 10px;
        border: 2px solid #1572a1;
        border-radius: 10px;
        background-color: #eefaff;
        margin-bottom: 25px;
        .card-content-vacancy {
          .img-icon {
           display: block;
           margin: 0 auto;
            width: 100%;
            height: 55px;
          }
        }
        .title {
          margin-top: 12px;
          .title-1 {
            font-family: "Nunito Sans";
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            color: #000000;
          }
          .title-2 {
            font-family: "Nunito Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #000000;
          }
        }
      }
      .card-vacancy-all:hover {
        padding: 10px;
        border: 2px solid #58bdf0;
        border-radius: 10px;
        background-color: #58bdf0;
        margin-bottom: 25px;
        .card-content-vacancy {
          .img-icon {
           display: block;
           margin: 0 auto;
            width: 100%;
            height: 55px;
          }
        }
        .title {
          margin-top: 12px;
          .title-1 {
            font-family: "Nunito Sans";
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            color: #000000;
          }
          .title-2 {
            font-family: "Nunito Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #000000;
          }
        }
      }
     
    }
  }

  .row-vac-all {
    .col-vac-all {
      margin-top: 20px;
    
      .card-vac-all {
        border-bottom: 1px solid #1572a1;
        border-top: none;
        border-right: none;
        border-left: none;
        border-radius: 2px;
        height: 100%;
        padding-right: 5px;
        padding-left: 5px;
        padding-bottom: 5px;
        padding-top: 5px;
        
        .row-card-vac-all{
          .col-card-vac-all-icon{
            display: block;
            margin: auto 0;
            .card-content-vac {
              .img-vac {
             
                width: 100%;
                height: 35px;
              }
            }
          }
          .col-card-vac-all-text{
            .title-vac {
              .title-1 {
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 700;
                font-size: 15px;
                color: #000000;
                margin-top: 14px;
                text-decoration-line: none;
                padding-left: 5px;
              }
          
              .title-2 {
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                color: #9d9d9d;
                margin-top: 16px;
                .img-vac {
                  height: 22px;
                  margin-bottom: 2px;
                }
              }
            }
          }
          .col-card-vac-all-btn{
            display: block;
            margin:auto 0;
            .btn-detail{
              border: none;
              background-color: #1572a1;
              .text-detail{
                padding:3px 15px;
                margin: 0 auto;
                color: #fff;
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
              }
            }
            .btn-detail:hover{
              border: none;
              background-color: #064463;
              .text-detail{
                padding:3px 15px;
                margin: 0 auto;
                color: #fff;
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
              }
            }
          }
        }
       
      
       
      }
    }
  }
}
}
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
/*vacancy*/
.vacancy {
margin-top: 80px;
margin-bottom: 150px;
.vacancy-content {
  .vacancy-title {
    .vacancy-title-1 {
      margin-top: 25px;
      font-family: "Nunito Sans", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 33px;
      color: #161616;
      .vacancy-garis {
        border-bottom: 3px solid #1572a1;
        width: relative;
      }
    }
  }

  .row-wish-form{
    margin-top: 30px;
    margin-bottom: 30px;
    .col-wish-form{
      margin-bottom: 10px;
      .wishlist-button{
        .btn-wishlist{
          background-color: #1572A1;
          border: none;
          border-radius: 5px;
          width: 100%;
          .text-wishlist{
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 19px;
            text-align: center;
            color: #EDEDED;
            padding-left:33px;
            padding-right:33px;
            margin-top: 5px;
            margin-bottom: 5px;
            position: relative;
            .img-wishlist{
              height: 30px;
            }
          }
     
        }
      }
      .form-vacancy {
        .row-vacancy {
          .col-vacancy-2 {
            margin-bottom: 10px;
            .search-2 {
              height: 40px;
              width: 100%;
              box-sizing: border-box;
              border: 2px solid #ccc;
              font-size: 14px;
              background-color: transparent;
              background-image: url("../img/location.png");
              background-position: 5px 5px;
              background-repeat: no-repeat;
              padding-left: 40px;
              -webkit-transition: width 0.4s ease-in-out;
              transition: width 0.4s ease-in-out;
            }
          }
          .col-vacancy-4 {
            .btn-search {
              width: 100%;
              background-color: #1572a1;
              border: none;
              height: 40px;
              .text-search {
                color: #ededed;
                font-size: 16px;
                font-weight: bold;
                font-family: "Nunito Sans", sans-serif;
                margin-top: 5px;
                margin-bottom: auto;
                position: relative;
              }
            }
          }
        }
      }
    }
  }


  .row-vacancy-all {
    margin-top: 16px;
    .col-vacancy-all {
      .card-vacancy-all {
        padding: 10px;
        border: 2px solid #1572a1;
        border-radius: 10px;
        background-color: #eefaff;
        margin-bottom: 25px;
        .card-content-vacancy {
          .img-icon {
           display: block;
           margin: 0 auto;
            width: 100%;
            height: 55px;
          }
        }
        .title {
          margin-top: 12px;
          .title-1 {
            font-family: "Nunito Sans";
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            color: #000000;
          }
          .title-2 {
            font-family: "Nunito Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #000000;
          }
        }
      }
      .card-vacancy-all:hover {
        padding: 10px;
        border: 2px solid #58bdf0;
        border-radius: 10px;
        background-color: #58bdf0;
        margin-bottom: 25px;
        .card-content-vacancy {
          .img-icon {
           display: block;
           margin: 0 auto;
            width: 100%;
            height: 55px;
          }
        }
        .title {
          margin-top: 12px;
          .title-1 {
            font-family: "Nunito Sans";
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            color: #000000;
          }
          .title-2 {
            font-family: "Nunito Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #000000;
          }
        }
      }
     
    }
  }

  .row-vac-all {
    .col-vac-all {
      margin-top: 20px;
    
      .card-vac-all {
        border-bottom: 1px solid #1572a1;
        border-top: none;
        border-right: none;
        border-left: none;
        border-radius: 2px;
        height: 100%;
        padding-right: 5px;
        padding-left: 5px;
        padding-bottom: 5px;
        padding-top: 5px;
        
        .row-card-vac-all{
          .col-card-vac-all-icon{
            display: block;
            margin: auto 0;
            .card-content-vac {
              .img-vac {
                width: 100%;
                height: 35px;
              }
            }
          }
          .col-card-vac-all-text{
            .title-vac {
              .title-1 {
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 700;
                font-size: 15px;
                color: #000000;
                margin-top: 14px;
                text-decoration-line: none;
                padding-left: 5px;
              }
          
              .title-2 {
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                color: #9d9d9d;
                margin-top: 16px;
                .img-vac {
                  height: 22px;
                  margin-bottom: 2px;
                }
              }
            }
          }
          .col-card-vac-all-btn{
            display: block;
            margin:auto 0;
            .btn-detail{
              border: none;
              background-color: #1572a1;
              .text-detail{
                padding:3px 25px;
                margin: 0 auto;
                color: #fff;
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 600;
                font-size: 13px;
              }
            }
            .btn-detail:hover{
              border: none;
              background-color: #064463;
              .text-detail{
                padding:3px 25px;
                margin: 0 auto;
                color: #fff;
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 600;
                font-size: 13px;
              }
            }
          }
        }
       
      
       
      }
    }
  }
}
}

} 

/*akhir vacancy*/

// awal scroll

.top-to-btm{
    position: relative;
    .icon-position{
        position: fixed;
        bottom: 40px;
        right: 25px;
        z-index: 20;
      }
      .icon-style{
        background-color: #1572a1;
        border: 2px solid #fff;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        color: #fff;
        cursor: pointer;
        animation: movebtn 5s ease-in-out infinite;
        transition: all .4s ease-in-out;
      }
      .icon-style:hover{
        animation: none;
        background: #fff;
        color: #1572a1;
        border: 2px solid #1572a1;
      }
      .ikon{
        margin-top: 10px;
        position: absolute;
        margin-left: 17px;
      }
      @keyframes movebtn {
        0%{
          transform: translateY(0px);
        }
        25%{
          transform: translateY(20px);
        }
        50%{
          transform: translateY(0px);
        }
        75%{
          transform: translateY(-20px);
        }
        100%{
          transform: translateY(0px);
        }
      }
  }

  @media screen and (min-width: 240px) and (max-width: 575px) {
    .top-to-btm{
        position: relative;
        .icon-position{
            position: fixed;
            bottom: 40px;
            right: 25px;
            z-index: 20;
          }
          .icon-style{
            background-color: #1572a1;
            border: 2px solid #fff;
            border-radius: 50%;
            height: 30px;
            width: 30px;
            color: #fff;
            cursor: pointer;
            animation: movebtn 10s ease-in-out infinite;
            transition: all .10s ease-in-out;
          }
          .icon-style:hover{
            animation: none;
            background: #fff;
            color: #1572a1;
            border: 2px solid #1572a1;
          }
          .ikon{
            margin-top: 2px;
            position: absolute;
            margin-left: 7px;
          }
          @keyframes movebtn {
            0%{
              transform: translateY(0px);
            }
            25%{
              transform: translateY(20px);
            }
            50%{
              transform: translateY(0px);
            }
            75%{
              transform: translateY(-20px);
            }
            100%{
              transform: translateY(0px);
            }
          }
      }
  }

  @media screen and (min-width: 575px) and (max-width: 750px) {
    .top-to-btm{
        position: relative;
        .icon-position{
            position: fixed;
            bottom: 40px;
            right: 25px;
            z-index: 20;
          }
          .icon-style{
            background-color: #1572a1;
            border: 2px solid #fff;
            border-radius: 50%;
            height: 40px;
            width: 40px;
            color: #fff;
            cursor: pointer;
            animation: movebtn 5s ease-in-out infinite;
            transition: all .4s ease-in-out;
          }
          .icon-style:hover{
            animation: none;
            background: #fff;
            color: #1572a1;
            border: 2px solid #1572a1;
          }
          .ikon{
            margin-top: 5px;
            position: absolute;
            margin-left: 11px;
          }
          @keyframes movebtn {
            0%{
              transform: translateY(0px);
            }
            25%{
              transform: translateY(20px);
            }
            50%{
              transform: translateY(0px);
            }
            75%{
              transform: translateY(-20px);
            }
            100%{
              transform: translateY(0px);
            }
          }
      }
  }
  @media screen and (min-width: 750px) and (max-width: 1199px) {
    .top-to-btm{
        position: relative;
        .icon-position{
            position: fixed;
            bottom: 40px;
            right: 25px;
            z-index: 20;
          }
          .icon-style{
            background-color: #1572a1;
            border: 2px solid #fff;
            border-radius: 50%;
            height: 40px;
            width: 40px;
            color: #fff;
            cursor: pointer;
            animation: movebtn 5s ease-in-out infinite;
            transition: all .4s ease-in-out;
          }
          .icon-style:hover{
            animation: none;
            background: #fff;
            color: #1572a1;
            border: 2px solid #1572a1;
          }
          .ikon{
            margin-top: 5px;
            position: absolute;
            margin-left: 11px;
          }
          @keyframes movebtn {
            0%{
              transform: translateY(0px);
            }
            25%{
              transform: translateY(20px);
            }
            50%{
              transform: translateY(0px);
            }
            75%{
              transform: translateY(-20px);
            }
            100%{
              transform: translateY(0px);
            }
          }
      }
  }

  // akhir scroll


  .autocomplete-options {
    list-style: none;
    margin: 0;
    padding: 0;
    border: 1px solid #ccc;
    border-top: none;
    max-height: 150px;
    overflow-y: auto;
    position: absolute;
    background-color: #fff;
    width: 100%;
}

.autocomplete-options li {
    padding: 8px;
    cursor: pointer;
}

.autocomplete-options li:hover {
    background-color: #f0f0f0;
}

.datalist-options {
  list-style: none;
  margin: 0;
  padding: 0;
  border: 1px solid #ccc;
  border-top: none;
  max-height: 150px;
  overflow-y: auto;
  position: absolute;
  background-color: #fff;
  width: 100%;
}

.datalist-options li {
  padding: 8px;
  cursor: pointer;
}

.datalist-options li:hover {
  background-color: #f0f0f0;
}
