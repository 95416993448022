/*register*/
.register {
    margin-top: 80px;
    .register-label .label {
      margin-top: 25px;
      font-family: "Nunito Sans", sans-serif;
  
      .register-title {
        font-weight: bold;
        font-weight: 24px;
        .register-garis {
          border-bottom: 3px solid #1572a1;
          width: relative;
        }
      }
  
      .register-title-content-1 {
        margin-top: 10px;
        font-size: 16px;
        font-weight: 600;
      }
      .register-title-content-2 {
        margin-top: 8px;
        font-size: 16px;
        font-style: italic;
      }
      .register-garis-1 {
        border-bottom: 1.5px solid #000;
      }
    }
  
    .register-label .content {
      margin-top: -5px;
      .form-row {
        .form-col {
          .card-title {
            margin-top: 21px;
            margin-left: 40px;
            margin-right: 40px;
            height: 38px;
            background-color: #1572a1;
            font-size: 16px;
            font-weight: 400;
            color: #fff;
            padding-top: 8px;
            padding-left: 16px;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
          }
          .card {
            border: 1px solid #6d6f71;
            height: relative;
            margin-left: 40px;
            margin-right: 40px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            border-top-right-radius: 0px;
            border-top-left-radius: 0px;
            border-top: none;
            margin-top: -9px;
  
            .col-form-label {
              font-size: 16px;
              font-weight: 400;
  
              .wajib {
                color: red;
                margin-left: 5px;
                font-weight: bold;
              }
            }
            .col-form-input .input {
              border: 1px solid #000;
            }
            .card-body {
              .row-password {
                .col-form-input {
                  .grup-password {
                    .input {
                      border-right: none;
                    }
                    .eye {
                      padding-top: 3px;
                      padding-left: 9px;
                      padding-right: 9px;
                      border-left: none;
                      border: 1px solid #000;
                      border-top-right-radius: 5px;
                      border-bottom-right-radius: 5px;
                      background-color: #e4e4e4;
                    }
                    .eye1 {
                      padding-top: 3px;
                      padding-left: 8px;
                      padding-right: 8px;
                      border-left: none;
                      border: 1px solid #000;
                      border-top-right-radius: 5px;
                      border-bottom-right-radius: 5px;
                      background-color: #e4e4e4;
                    }
                  }
                }
              }
            }
          }
        }
  
        .form-noted {
          .card {
            background-color: #e9a53b;
            border: none;
            height: relative;
            margin-left: 40px;
            margin-right: 40px;
            margin-top: 16px;
            .card-body {
              font-family: "Nunito Sans", sans-serif;
              .span-1 {
                font-size: 16px;
                font-weight: 400;
              }
              .span-bold {
                font-size: 16px;
                font-weight: 600;
              }
            }
          }
        }
  
        .form-button {
          .button-register {
            height: 40px;
            width: 15%;
            background-color: #044f9f;
            border-radius: 5px;
            border: none;
            margin-top: 16px;
  
            .regis {
              color: #fff;
              font-family: "Nunito Sans", sans-serif;
              font-weight: bold;
              text-decoration: none;
              font-size: 14px;
              padding-top: 16px;
              padding-bottom: 16px;
            }
          }
  
          .button-register:hover {
            height: 40px;
            width: 15%;
            background-color: #022d72;
            border-radius: 5px;
            border: none;
            margin-top: 16px;
          }
        }
      }
    }
}

/*reponsive*/
@media screen and (min-width: 240px) and (max-width: 580px) {
/*register*/
.register {
      margin-top: 80px;
      .register-label .label {
        margin-top: 25px;
        font-family: "Nunito Sans", sans-serif;
  
        .register-title {
          font-weight: bold;
          font-weight: 24px;
          .register-garis {
            border-bottom: 3px solid #1572a1;
            width: relative;
          }
        }
  
        .register-title-content-1 {
          margin-top: 10px;
          font-size: 16px;
          font-weight: 600;
        }
        .register-title-content-2 {
          margin-top: 8px;
          font-size: 16px;
          font-style: italic;
        }
        .register-garis-1 {
          border-bottom: 1.5px solid #000;
        }
      }
  
      .register-label .content {
        margin-top: -5px;
        .form-row {
          .form-col {
            .card-title {
              margin-top: 21px;
              margin-left: 0px;
              margin-right: 0px;
              height: 38px;
              background-color: #1572a1;
              font-size: 16px;
              font-weight: 400;
              color: #fff;
              padding-top: 8px;
              padding-left: 16px;
              border-top-right-radius: 10px;
              border-top-left-radius: 10px;
            }
            .card {
              border: 1px solid #6d6f71;
              height: relative;
              margin-left: 0px;
              margin-right: 0px;
              border-bottom-left-radius: 10px;
              border-bottom-right-radius: 10px;
              border-top-right-radius: 0px;
              border-top-left-radius: 0px;
              border-top: none;
              margin-top: -9px;
  
              .col-form-label {
                font-size: 16px;
                font-weight: 400;
  
                .wajib {
                  color: red;
                  margin-left: 5px;
                  font-weight: bold;
                }
              }
              .col-form-input .input {
                border: 1px solid #000;
              }
              .card-body {
                .row-password {
                  .col-form-input {
                    .grup-password {
                      .input {
                        border-right: none;
                      }
                      .eye {
                        padding-top: 3px;
                        padding-left: 9px;
                        padding-right: 9px;
                        border-left: none;
                        border: 1px solid #000;
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                        background-color: #e4e4e4;
                      }
                      .eye1 {
                        padding-top: 3px;
                        padding-left: 8px;
                        padding-right: 8px;
                        border-left: none;
                        border: 1px solid #000;
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                        background-color: #e4e4e4;
                      }
                    }
                  }
                }
              }
            }
          }
  
          .form-noted {
            .card {
              background-color: #e9a53b;
              border: none;
              height: relative;
              margin-left: 0px;
              margin-right: 0px;
              margin-top: 16px;
              .card-body {
                font-family: "Nunito Sans", sans-serif;
                .span-1 {
                  font-size: 12px;
                  font-weight: 400;
                }
                .span-2 {
                  font-size: 12px;
                  font-weight: 400;
                }
                .span-bold {
                  font-size: 12px;
                  font-weight: 600;
                }
              }
            }
          }
  
          .form-button {
            .button-register {
              height: 40px;
              width: 50%;
              background-color: #044f9f;
              border-radius: 5px;
              border: none;
              margin-top: 16px;
  
              .regis {
                color: #fff;
                font-family: "Nunito Sans", sans-serif;
                font-weight: bold;
                text-decoration: none;
                font-size: 14px;
                padding-top: 16px;
                padding-bottom: 16px;
              }
            }
  
            .button-register:hover {
              height: 40px;
              width: 50%;
              background-color: #022d72;
              border-radius: 5px;
              border: none;
              margin-top: 16px;
            }
          }
        }
      }
}
  
}
  
@media screen and (min-width: 580px) and (max-width: 750px) {
/*register*/
.register {
    margin-top: 80px;
    .register-label .label {
      margin-top: 25px;
      font-family: "Nunito Sans", sans-serif;
  
      .register-title {
        font-weight: bold;
        font-weight: 24px;
        .register-garis {
          border-bottom: 3px solid #1572a1;
          width: relative;
        }
      }
  
      .register-title-content-1 {
        margin-top: 10px;
        font-size: 16px;
        font-weight: 600;
      }
      .register-title-content-2 {
        margin-top: 8px;
        font-size: 16px;
        font-style: italic;
      }
      .register-garis-1 {
        border-bottom: 1.5px solid #000;
      }
    }
  
    .register-label .content {
      margin-top: -5px;
      .form-row {
        .form-col {
          .card-title {
            margin-top: 21px;
            margin-left: 0px;
            margin-right: 0px;
            height: 38px;
            background-color: #1572a1;
            font-size: 16px;
            font-weight: 400;
            color: #fff;
            padding-top: 8px;
            padding-left: 16px;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
          }
          .card {
            border: 1px solid #6d6f71;
            height: relative;
            margin-left: 0px;
            margin-right: 0px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            border-top-right-radius: 0px;
            border-top-left-radius: 0px;
            border-top: none;
            margin-top: -9px;
  
            .col-form-label {
              font-size: 16px;
              font-weight: 400;
  
              .wajib {
                color: red;
                margin-left: 5px;
                font-weight: bold;
              }
            }
            .col-form-input .input {
              border: 1px solid #000;
            }
            .card-body {
              .row-password {
                .col-form-input {
                  .grup-password {
                    .input {
                      border-right: none;
                    }
                    .eye {
                      padding-top: 3px;
                      padding-left: 9px;
                      padding-right: 9px;
                      border-left: none;
                      border: 1px solid #000;
                      border-top-right-radius: 5px;
                      border-bottom-right-radius: 5px;
                      background-color: #e4e4e4;
                    }
                    .eye1 {
                      padding-top: 3px;
                      padding-left: 8px;
                      padding-right: 8px;
                      border-left: none;
                      border: 1px solid #000;
                      border-top-right-radius: 5px;
                      border-bottom-right-radius: 5px;
                      background-color: #e4e4e4;
                    }
                  }
                }
              }
            }
          }
        }
  
        .form-noted {
          .card {
            background-color: #e9a53b;
            border: none;
            height: relative;
            margin-left: 0px;
            margin-right: 0px;
            margin-top: 16px;
            .card-body {
              font-family: "Nunito Sans", sans-serif;
              .span-1 {
                font-size: 14px;
                font-weight: 400;
              }
              .span-2 {
                font-size: 14px;
                font-weight: 400;
              }
              .span-bold {
                font-size: 14px;
                font-weight: 600;
              }
            }
          }
        }
  
        .form-button {
          .button-register {
            height: 40px;
            width: 50%;
            background-color: #044f9f;
            border-radius: 5px;
            border: none;
            margin-top: 16px;
  
            .regis {
              color: #fff;
              font-family: "Nunito Sans", sans-serif;
              font-weight: bold;
              text-decoration: none;
              font-size: 14px;
              padding-top: 16px;
              padding-bottom: 16px;
            }
          }
  
          .button-register:hover {
            height: 40px;
            width: 50%;
            background-color: #022d72;
            border-radius: 5px;
            border: none;
            margin-top: 16px;
          }
        }
      }
    }
}

}
  
@media screen and (min-width: 750px) and (max-width: 1199px) {
/*register*/
.register {
    margin-top: 80px;
    .register-label .label {
      margin-top: 25px;
      font-family: "Nunito Sans", sans-serif;
  
      .register-title {
        font-weight: bold;
        font-weight: 24px;
        .register-garis {
          border-bottom: 3px solid #1572a1;
          width: relative;
        }
      }
  
      .register-title-content-1 {
        margin-top: 10px;
        font-size: 16px;
        font-weight: 600;
      }
      .register-title-content-2 {
        margin-top: 8px;
        font-size: 16px;
        font-style: italic;
      }
      .register-garis-1 {
        border-bottom: 1.5px solid #000;
      }
    }
  
    .register-label .content {
      margin-top: -5px;
      .form-row {
        .form-col {
          .card-title {
            margin-top: 21px;
            margin-left: 40px;
            margin-right: 40px;
            height: 38px;
            background-color: #1572a1;
            font-size: 16px;
            font-weight: 400;
            color: #fff;
            padding-top: 8px;
            padding-left: 16px;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
          }
          .card {
            border: 1px solid #6d6f71;
            height: relative;
            margin-left: 40px;
            margin-right: 40px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            border-top-right-radius: 0px;
            border-top-left-radius: 0px;
            border-top: none;
            margin-top: -9px;
  
            .col-form-label {
              font-size: 16px;
              font-weight: 400;
  
              .wajib {
                color: red;
                margin-left: 5px;
                font-weight: bold;
              }
            }
            .col-form-input .input {
              border: 1px solid #000;
            }
            .card-body {
              .row-password {
                .col-form-input {
                  .grup-password {
                    .input {
                      border-right: none;
                    }
                    .eye {
                      padding-top: 3px;
                      padding-left: 9px;
                      padding-right: 9px;
                      border-left: none;
                      border: 1px solid #000;
                      border-top-right-radius: 5px;
                      border-bottom-right-radius: 5px;
                      background-color: #e4e4e4;
                    }
                    .eye1 {
                      padding-top: 3px;
                      padding-left: 8px;
                      padding-right: 8px;
                      border-left: none;
                      border: 1px solid #000;
                      border-top-right-radius: 5px;
                      border-bottom-right-radius: 5px;
                      background-color: #e4e4e4;
                    }
                  }
                }
              }
            }
          }
        }
  
        .form-noted {
          .card {
            background-color: #e9a53b;
            border: none;
            height: relative;
            margin-left: 40px;
            margin-right: 40px;
            margin-top: 16px;
            .card-body {
              font-family: "Nunito Sans", sans-serif;
              .span-1 {
                font-size: 14px;
                font-weight: 400;
              }
              .span-2 {
                font-size: 14px;
                font-weight: 400;
              }
              .span-bold {
                font-size: 14px;
                font-weight: 600;
              }
            }
          }
        }
  
        .form-button {
          .button-register {
            height: 40px;
            width: 50%;
            background-color: #044f9f;
            border-radius: 5px;
            border: none;
            margin-top: 16px;
  
            .regis {
              color: #fff;
              font-family: "Nunito Sans", sans-serif;
              font-weight: bold;
              text-decoration: none;
              font-size: 14px;
              padding-top: 16px;
              padding-bottom: 16px;
            }
          }
  
          .button-register:hover {
            height: 40px;
            width: 50%;
            background-color: #022d72;
            border-radius: 5px;
            border: none;
            margin-top: 16px;
          }
        }
      }
    }
}

}  
