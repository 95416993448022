/*edit profile*/
.edit-profile {
    margin-top: 100px;
    .container-edit-profile {
      .row-edit-profile {
        .upload-file{
          cursor: pointer;
          font-family: "Nunito Sans";
          background-color: #1572A1;
          border-radius: 50%;
          text-align: center;
          color: rgb(255, 255, 255);
          width: 35px;
          height: 35px;
          padding: 5px 0px;
          margin-top: -25px;
          font-size: 16px;
          border: 1px solid #1572a1;
          background-color: #1572a1;
        }
        .col-img {
          .bord{
           border: 56px solid rgb(255, 255, 255);
           height: 260px;
           width: 260px;
           border-radius: 50%;
           margin-bottom: -215px;
           z-index: -9999;
           margin-left: auto;
           margin-right: auto;
           display: block;
          } 
           .picture {
             .bord1{
               border: 5px solid #1572a1;
               height: 170px;
               width: 170px;
               margin-left: auto;
               margin-right: auto;
               display: block;
               border-radius: 50%;
               margin-bottom: -180px;
              }
   
             .img-profile {
               width: 150px;
               height: 215px;
               display: block;
               margin-left: auto;
               margin-right: auto;
               margin-top: 6px;
               margin-bottom: 6px;
               z-index: -999;
               position: relative;
               border-radius: 36%;
               border: 1px solid #ffffff;
             }
             .img-profile1 {
              width: 152px;
              height: 152px;
              display: block;
              margin-left: auto;
              margin-right: auto;
              margin-top: -208px;
              margin-bottom: 6px;
              border-radius: 50%;
              border: 1px solid #ffffff;
            }
         
      
           
           }
         }

         .col-img1 {
      margin-top: -260px;
          .bord{
           border: 56px solid rgb(255, 255, 255);
           height: 260px;
           width: 260px;
           border-radius: 50%;
           margin-bottom: -215px;
           z-index: -9999;
           margin-left: auto;
           margin-right: auto;
           display: block;
          } 
           .picture {
             .bord1{
               border: 5px solid #1572a1;
               height: 170px;
               width: 170px;
               margin-left: auto;
               margin-right: auto;
               display: block;
               border-radius: 50%;
               margin-bottom: -180px;
              }
   
             .img-profile1 {
               width: 150px;
               height: 215px;
               display: block;
               margin-left: auto;
               margin-right: auto;
               margin-top: 6px;
               margin-bottom: 6px;
               z-index: -999;
               position: relative;
               border-radius: 36%;
               border: 1px solid #ffffff;
             }
       
           }
      
         }
       
      }

      .row-data {
        margin-top: 20px;
        margin-bottom: 20px;
      .col-data{
        .card-data {
          min-height: 400px;
          padding-left: 10px;
          padding-right: 10px;
          padding-top: 30px;
          padding-bottom: 30px;
          margin-bottom: 20px;
          border-radius: 10px;
          border: 2px solid #999999;
          .container-data{
            .title-data {
              font-family: "Nunito Sans";
              font-style: normal;
              font-weight: 700;
              font-size: 20px;
         
              color: #1a1a1a;
            }
            .col-input{
              .titel{
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                color: #999999;
              }
              .input{
                margin-bottom: 12px;
              }

            }
          }
        }
      }
     
     
      }

      .row-btn-profile {
  
        .col-btn {
          margin-bottom: 10px;
          margin-top: 10px;
          color: #000;
          .btn-cancel {
            float: right;
            border: 1px solid #EE0E0E;
            background-color: #fff;
            border-radius: 10px;
            
            .text-cancel {
              font-family: "Nunito Sans";
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 19px;
              text-align: center;
              color: #EE0E0E;
              margin-top: 10px;
              margin-left: 15px;
              margin-right: 15px;
              .img-cancel {
                margin-top: -2px;
                margin-right: 5px;
              }
            }
          }
          .btn-update {
            border: 1px solid #1572a1;
            background-color: #1572a1;
            border-radius: 10px;
            .text-update {
              font-family: "Nunito Sans";
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 19px;
              text-align: center;
              color: #ededed;
              margin-top: 10px;
              margin-left: 15px;
              margin-right: 15px;
              .img-update {
                margin-top: -2px;
                margin-right: 5px;
              }
            }
          }
          .btn-pwd {
            border: 1px solid #1572a1;
            background-color: #1572a1;
            border-radius: 10px;
            .text-update {
              font-family: "Nunito Sans";
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 19px;
              text-align: center;
              color: #ededed;
              margin-top: 10px;
              margin-left: 15px;
              margin-right: 15px;
              .img-update {
                margin-top: -2px;
                margin-right: 5px;
              }
            }
          }
        }
        .col-profile-text {
          .profile-text {
            font-family: "Nunito Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            letter-spacing: -0.014em;
            color: #999999;
          }
        }
      }
  
     
    }
}

/*reponsive*/
@media screen and (min-width: 240px) and (max-width: 575px) {
/*edit profile*/
.edit-profile {
    margin-top: 100px;
    .container-edit-profile {
      .row-edit-profile {
        .col-img {
          .bord{
           border: 56px solid rgb(255, 255, 255);
           height: 260px;
           width: 260px;
           border-radius: 50%;
           margin-bottom: -215px;
           z-index: -9999;
           margin-left: auto;
           margin-right: auto;
           display: block;
          } 
           .picture {
             .bord1{
               border: 5px solid #1572a1;
               height: 170px;
               width: 170px;
               margin-left: auto;
               margin-right: auto;
               display: block;
               border-radius: 50%;
               margin-bottom: -180px;
              }
   
             .img-profile {
               width: 150px;
               height: 215px;
               display: block;
               margin-left: auto;
               margin-right: auto;
               margin-top: 6px;
               margin-bottom: 6px;
               z-index: -999;
               position: relative;
               border-radius: 36%;
               border: 1px solid #ffffff;
             }
             .img-profile1 {
              width: 152px;
              height: 152px;
              display: block;
              margin-left: auto;
              margin-right: auto;
              margin-top: -208px;
              margin-bottom: 6px;
              border-radius: 50%;
              border: 1px solid #ffffff;
            }

           }
         }

         .col-img1 {
       
          margin-left: 0px;
          margin-top: -265px;
          .bord{
           border: 56px solid rgb(255, 255, 255);
           height: 260px;
           width: 260px;
           border-radius: 50%;
           margin-bottom: -215px;
           z-index: -9999;
           margin-left: auto;
           margin-right: auto;
           display: block;
          } 
           .picture {
             .bord1{
               border: 5px solid #1572a1;
               height: 170px;
               width: 170px;
               margin-left: auto;
               margin-right: auto;
               display: block;
               border-radius: 50%;
               margin-bottom: -180px;
              }
   
             .img-profile1 {
               width: 150px;
               height: 215px;
               display: block;
               margin-left: auto;
               margin-right: auto;
               margin-top: 6px;
               margin-bottom: 6px;
               z-index: -999;
               position: relative;
               border-radius: 36%;
               border: 1px solid #ffffff;
             }
             .upload-file{
              cursor: pointer;
              font-family: "Nunito Sans";
              background-color: #1572A1;
              float: right;
              z-index: 999;
              border-radius: 50%;
              text-align: center;
              color: rgb(255, 255, 255);
              width: 35px;
              position: relative;
              padding: 5px 0px;
              font-size: 16px;
              border: 1px solid #1572a1;
              background-color: #1572a1;
            }
           }
      
         }
      }
      .row-btn-profile {
        .col-btn {
          border: 10px;
          margin-bottom: 10px;
          margin-top: 10px;
          color: #000;
          .btn-cancel {
            float: right;
            border: 1px solid #EE0E0E;
            background-color: #fff;
            border-radius: 10px;
            width: 100%;
            .text-cancel {
              font-family: "Nunito Sans";
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 19px;
              text-align: center;
              color: #EE0E0E;
              margin-top: 10px;
              margin-left: 15px;
              margin-right: 15px;
              .img-cancel {
                margin-top: -2px;
                margin-right: 5px;
              }
            }
          }
          .btn-update {
            border: 1px solid #1572a1;
            background-color: #1572a1;
            border-radius: 10px;
            width: 100%;
            .text-update {
              font-family: "Nunito Sans";
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 19px;
              text-align: center;
              color: #ededed;
              margin-top: 10px;
              margin-left: 15px;
              margin-right: 15px;
              .img-update {
                margin-top: -2px;
                margin-right: 5px;
              }
            }
          }
          .btn-pwd {
            border: 1px solid #1572a1;
            background-color: #1572a1;
            border-radius: 10px;
            width: 100%;
            .text-update {
              font-family: "Nunito Sans";
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 19px;
              text-align: center;
              color: #ededed;
              margin-top: 10px;
              margin-left: 15px;
              margin-right: 15px;
              .img-update {
                margin-top: -2px;
                margin-right: 5px;
              }
            }
          }
        }
        .col-profile-text {
          .profile-text {
            font-family: "Nunito Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            letter-spacing: -0.014em;
            color: #999999;
          }
        }
      }
  
      .row-data {
        margin-top: 20px;
        margin-bottom: 20px;
        .title-data {
          font-family: "Nunito Sans";
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 25px;
          color: #1a1a1a;
        }
        .col-label-data {
          font-family: "Nunito Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: -0.014em;
          color: #999999;
          margin-bottom: 5px;
          width: 45%;
        }
        .col-content-data {
          width: 55%;
          .input {
            border-radius: 5px;
            margin-bottom: 10px;
          }
        }
      }
    }
}
}
  
@media screen and (min-width: 575px) and (max-width: 750px) {
/*edit profile*/
.edit-profile {
  margin-top: 100px;
  .container-edit-profile {
    .row-edit-profile {
      .col-img {
      
        .bord{
         border: 56px solid rgb(255, 255, 255);
         height: 260px;
         width: 260px;
         border-radius: 50%;
         margin-bottom: -215px;
         z-index: -9999;
         margin-left: auto;
         margin-right: auto;
         display: block;
        } 
         .picture {
           .bord1{
             border: 5px solid #1572a1;
             height: 170px;
             width: 170px;
             margin-left: auto;
             margin-right: auto;
             display: block;
             border-radius: 50%;
             margin-bottom: -180px;
            }
 
           .img-profile {
             width: 150px;
             height: 215px;
             display: block;
             margin-left: auto;
             margin-right: auto;
             margin-top: 6px;
             margin-bottom: 6px;
             z-index: -999;
             position: relative;
             border-radius: 36%;
             border: 1px solid #ffffff;
           }
           .img-profile1 {
            width: 152px;
            height: 152px;
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-top: -208px;
            margin-bottom: 6px;
            border-radius: 50%;
            border: 1px solid #ffffff;
          }
       
    
         
         }
       }

       .col-img1 {

        margin-left: 0px;
        margin-top: -265px;
        .bord{
         border: 56px solid rgb(255, 255, 255);
         height: 260px;
         width: 260px;
         border-radius: 50%;
         margin-bottom: -215px;
         z-index: -9999;
         margin-left: auto;
         margin-right: auto;
         display: block;
        } 
         .picture {
           .bord1{
             border: 5px solid #1572a1;
             height: 170px;
             width: 170px;
             margin-left: auto;
             margin-right: auto;
             display: block;
             border-radius: 50%;
             margin-bottom: -180px;
            }
 
           .img-profile1 {
             width: 150px;
             height: 215px;
             display: block;
             margin-left: auto;
             margin-right: auto;
             margin-top: 6px;
             margin-bottom: 6px;
             z-index: -999;
             position: relative;
             border-radius: 36%;
             border: 1px solid #ffffff;
           }
           .upload-file{
            cursor: pointer;
            font-family: "Nunito Sans";
            background-color: #1572A1;
            float: right;
            z-index: 999;
            border-radius: 50%;
            text-align: center;
            color: rgb(255, 255, 255);
            width: 35px;
            position: relative;
            padding: 5px 0px;
            font-size: 16px;
            border: 1px solid #1572a1;
            background-color: #1572a1;
          }
         }
    
       }
    }
    .row-btn-profile {
      .col-btn {
        border: 10px;
        margin-bottom: 10px;
        margin-top: 10px;
        color: #000;
        .btn-cancel {
          float: right;
          border: 1px solid #EE0E0E;
          background-color: #fff;
          border-radius: 10px;
          width: 100%;
          .text-cancel {
            font-family: "Nunito Sans";
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 19px;
            text-align: center;
            color: #EE0E0E;
            margin-top: 10px;
            margin-left: 15px;
            margin-right: 15px;
            .img-cancel {
              margin-top: -2px;
              margin-right: 5px;
            }
          }
        }
        .btn-update {
          border: 1px solid #1572a1;
          background-color: #1572a1;
          border-radius: 10px;
          width: 100%;
          .text-update {
            font-family: "Nunito Sans";
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 19px;
            text-align: center;
            color: #ededed;
            margin-top: 10px;
            margin-left: 15px;
            margin-right: 15px;
            .img-update {
              margin-top: -2px;
              margin-right: 5px;
            }
          }
        }
        .btn-pwd {
          border: 1px solid #1572a1;
          background-color: #1572a1;
          border-radius: 10px;
          width: 100%;
          .text-update {
            font-family: "Nunito Sans";
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 19px;
            text-align: center;
            color: #ededed;
            margin-top: 10px;
            margin-left: 15px;
            margin-right: 15px;
            .img-update {
              margin-top: -2px;
              margin-right: 5px;
            }
          }
        }
      }
      .col-profile-text {
        .profile-text {
          font-family: "Nunito Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 22px;
          text-align: center;
          letter-spacing: -0.014em;
          color: #999999;
        }
      }
    }

    .row-data {
      margin-top: 20px;
      margin-bottom: 20px;
    .col-data{
      .card-data {
        min-height: 400px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 30px;
        padding-bottom: 30px;
        margin-bottom: 20px;
        border-radius: 10px;
        border: 2px solid #999999;
        .container-data{
          .title-data {
            font-family: "Nunito Sans";
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
       
            color: #1a1a1a;
          }
          .col-input{
            .titel{
              font-family: "Nunito Sans";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              color: #999999;
            }
            .input{
              margin-bottom: 12px;
            }

          }
        }
      }
    }
   
   
    }
  }
}
  }

@media screen and (min-width: 750px) and (max-width: 1199px) {
    /*edit profile*/
    .edit-profile {
      margin-top: 100px;
      .container-edit-profile {
        .row-edit-profile {
          .col-img {
           
            .bord{
             border: 56px solid rgb(255, 255, 255);
             height: 260px;
             width: 260px;
             border-radius: 50%;
             margin-bottom: -215px;
             z-index: -9999;
             margin-left: auto;
             margin-right: auto;
             display: block;
            } 
             .picture {
               .bord1{
                 border: 5px solid #1572a1;
                 height: 170px;
                 width: 170px;
                 margin-left: auto;
                 margin-right: auto;
                 display: block;
                 border-radius: 50%;
                 margin-bottom: -180px;
                }
     
               .img-profile {
                 width: 150px;
                 height: 215px;
                 display: block;
                 margin-left: auto;
                 margin-right: auto;
                 margin-top: 6px;
                 margin-bottom: 6px;
                 z-index: -999;
                 position: relative;
                 border-radius: 36%;
                 border: 1px solid #ffffff;
               }
               .img-profile1 {
                width: 152px;
                height: 152px;
                display: block;
                margin-left: auto;
                margin-right: auto;
                margin-top: -208px;
                margin-bottom: 6px;
                border-radius: 50%;
                border: 1px solid #ffffff;
              }
           
        
             
             }
           }
    
           .col-img1 {

            margin-left: 0px;
            margin-top: -265px;
            .bord{
             border: 56px solid rgb(255, 255, 255);
             height: 260px;
             width: 260px;
             border-radius: 50%;
             margin-bottom: -215px;
             z-index: -9999;
             margin-left: auto;
             margin-right: auto;
             display: block;
            } 
             .picture {
               .bord1{
                 border: 5px solid #1572a1;
                 height: 170px;
                 width: 170px;
                 margin-left: auto;
                 margin-right: auto;
                 display: block;
                 border-radius: 50%;
                 margin-bottom: -180px;
                }
     
               .img-profile1 {
                 width: 150px;
                 height: 215px;
                 display: block;
                 margin-left: auto;
                 margin-right: auto;
                 margin-top: 6px;
                 margin-bottom: 6px;
                 z-index: -999;
                 position: relative;
                 border-radius: 36%;
                 border: 1px solid #ffffff;
               }
               .upload-file{
                cursor: pointer;
                font-family: "Nunito Sans";
                background-color: #1572A1;
                float: right;
                z-index: 999;
                border-radius: 50%;
                text-align: center;
                color: rgb(255, 255, 255);
                width: 35px;
                position: relative;
                padding: 5px 0px;
                font-size: 16px;
                border: 1px solid #1572a1;
                background-color: #1572a1;
              }
             }
        
           }
        }
        .row-btn-profile {
          .col-btn {
            border: 10px;
            margin-bottom: 10px;
            margin-top: 10px;
            color: #000;
            .btn-cancel {
              float: right;
              border: 1px solid #EE0E0E;
              background-color: #fff;
              border-radius: 10px;
              width: 100%;
              .text-cancel {
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 19px;
                text-align: center;
                color: #EE0E0E;
                margin-top: 10px;
                margin-left: 15px;
                margin-right: 15px;
                .img-cancel {
                  margin-top: -2px;
                  margin-right: 5px;
                }
              }
            }
            .btn-update {
              border: 1px solid #1572a1;
              background-color: #1572a1;
              border-radius: 10px;
              width: 100%;
              .text-update {
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 19px;
                text-align: center;
                color: #ededed;
                margin-top: 10px;
                margin-left: 15px;
                margin-right: 15px;
                .img-update {
                  margin-top: -2px;
                  margin-right: 5px;
                }
              }
            }
            .btn-pwd {
              border: 1px solid #1572a1;
              background-color: #1572a1;
              border-radius: 10px;
              width: 100%;
              .text-update {
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 19px;
                text-align: center;
                color: #ededed;
                margin-top: 10px;
                margin-left: 15px;
                margin-right: 15px;
                .img-update {
                  margin-top: -2px;
                  margin-right: 5px;
                }
              }
            }
          }
          .col-profile-text {
            .profile-text {
              font-family: "Nunito Sans";
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 22px;
              text-align: center;
              letter-spacing: -0.014em;
              color: #999999;
            }
          }
        }
    
        .row-data {
          margin-top: 20px;
          margin-bottom: 20px;
        .col-data{
          .card-data {
            min-height: 630px;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 30px;
            padding-bottom: 30px;
            margin-bottom: 20px;
            border-radius: 10px;
            border: 2px solid #999999;
            .container-data{
              .title-data {
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
           
                color: #1a1a1a;
              }
              .col-input{
                .titel{
                  font-family: "Nunito Sans";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  color: #999999;
                }
                .input{
                  margin-bottom: 12px;
                }
  
              }
            }
          }
        }
       
       
        }
      }
    }
} 
