// reset
.container {
  .row-reset {
    .col-reset {
      .title-reset {
        margin-bottom: 40px;
        .title-content {
          font-family: "Montserrat", sans-serif;
          font-size: 30px;
          font-weight: bold;
          color: #272727;
          .title-content-1 {
            color: #1572a1;
          }
          .title-content-2 {
           color: #7D7D7Da1;
           font-size: 14px;
         }
        }
      }
      .card-reset {
        border: none;
        .card-body {
          margin-left: -15px;
          margin-top: -20px;
          width: 78%;
          .email {
            .form-label {
              font-family: "Nunito Sans", sans-serif;
              font-size: 16px;
              color: #0c0c0c;
              font-weight: 600;
            }
            .form-email {
              border: 1px solid #1572a1;
              border-radius: 10px;
            }
          }
          .code {
            margin-top: 10px;
            .form-label {
              font-family: "Nunito Sans", sans-serif;
              font-size: 16px;
              color: #0c0c0c;
              font-weight: 600;
            }
            .form-code {
             border: 1px solid #1572a1;
             border-radius: 10px;
           }
          }
          .col-pass {
            .forgot-password {
              font-family: "Nunito Sans", sans-serif;
              font-size: 14px;
              font-weight: 400;
              text-decoration: none;
              float: right;
              color: #1572a1;
              margin-bottom: 30px;
            }
          }
          .row-btn {
            .col-btn {
             margin-top: 20px;
              .button-reset {
                .btn-reset {
                  background-color: #1572a1;
                  width: 100%;
                  border-radius: 10px;
                  .title-reset {
                    font-family: "Nunito Sans", sans-serif;
                    color: #f9f9f9;
                    font-size: 16px;
                    font-weight: bold;
                  }
                }
                .btn-reset:hover {  
                  background-color: #064463;
                  width: 100%;
                  border-radius: 10px;
                  .title-reset {
                    font-family: "Nunito Sans", sans-serif;
                    color: #f9f9f9;
                    font-size: 16px;
                    font-weight: bold;
                  }
                }
              }
            }
            .col-btn {
              .button-signup {
                .btn-signup {
                  background-color: #f6f6f6;
                  width: 100%;
                  border-radius: 10px;
                  border: 1px solid #1572a1;
                  .title-signup {
                      text-decoration: none;
                      font-family: "Nunito Sans", sans-serif;
                      color: #1572a1;
                      font-size: 16px;
                      font-weight: bold;
                  }
                }
              }
            }
          }
        }
      }
    }
    .col-img-reset {
      .img-reset {
        .img-reset-content {
          height: 400px;
          width: 480px;
        
        }
      }
    }
  }
 }
 
 /*reponsive*/
 @media screen and (min-width: 240px) and (max-width: 580px) {
 // reset
 .container {
    .row-reset {
      margin-top: -60px;
      .col-reset {
        .title-reset {
          margin-bottom: 10px;
          .title-content {
            font-family: "Montserrat", sans-serif;
            font-size: 25px;
            font-weight: bold;
            color: #272727;
            text-align: center;
            .title-content-1 {
              color: #1572a1;
            }
          }
        }
        .card-reset {
          border: none;
          width: 100%;
          .card-body {
            margin-top: -20px;
            width: 90%;
            margin: 0 auto;
            .email {
              .form-label {
                font-family: "Nunito Sans", sans-serif;
                font-size: 16px;
                color: #0c0c0c;
                font-weight: 600;
              }
              .form-email {
                border: 1px solid #1572a1;
                border-radius: 10px;
              }
            }
            .password {
              margin-top: 30px;
              .form-label {
                font-family: "Nunito Sans", sans-serif;
                font-size: 16px;
                color: #0c0c0c;
                font-weight: 600;
              }
              .grup-password {
                border-radius: 10px;
                .form-password {
                  border: 1px solid #1572a1;
                  border-right: none;
                  border-top-left-radius: 10px;
                  border-bottom-left-radius: 10px;
                }
                .eye {
                  padding-top: 6px;
                  padding-left: 9px;
                  padding-right: 9px;
                  border-left: none;
                  border: 1px solid #1572a1;
                  border-top-right-radius: 10px;
                  border-bottom-right-radius: 10px;
                }
                .eye1 {
                  padding-top: 6px;
                  padding-left: 8px;
                  padding-right: 8px;
                  border-left: none;
                  border: 1px solid #1572a1;
                  border-top-right-radius: 10px;
                  border-bottom-right-radius: 10px;
                }
              }
            }
            .col-pass {
              .forgot-password {
                font-family: "Nunito Sans", sans-serif;
                font-size: 14px;
                font-weight: 400;
                text-decoration: none;
                float: right;
                color: #1572a1;
                margin-bottom: 30px;
              }
            }
            .row-btn {
              .col-btn {
                .button-reset {
                  .btn-reset {
                    background-color: #1572a1;
                    width: 100%;
                    border-radius: 10px;
                    margin-bottom: 10px;
                    .title-reset {
                      font-family: "Nunito Sans", sans-serif;
                      color: #f9f9f9;
                      font-size: 16px;
                      font-weight: bold;
                    }
                  }
                  .btn-reset:hover {
                    background-color: #064463;
                    width: 100%;
                    border-radius: 10px;
                    .title-reset {
                      font-family: "Nunito Sans", sans-serif;
                      color: #f9f9f9;
                      font-size: 16px;
                      font-weight: bold;
                    }
                  }
                }
              }
              .col-btn {
                .button-signup {
                  .btn-signup {
                    background-color: #f6f6f6;
                    width: 100%;
                    border-radius: 10px;
                    border: 1px solid #1572a1;
                    .title-signup {
                      .sign-up {
                        text-decoration: none;
                        font-family: "Nunito Sans", sans-serif;
                        color: #1572a1;
                        font-size: 16px;
                        font-weight: bold;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .col-img-reset {
        .img-reset {
          .img-reset-content {
            height: 160px;
            width: 220px;
            margin-left: 70px;
         
            display: block;
          }
        }
      }
    }
 }
 
 }
 
 @media screen and (min-width: 580px) and (max-width: 750px) {
 // login
 .container {
    .row-login {
      .col-login {
        .title-login {
          margin-bottom: 40px;
          .title-content {
            font-family: "Montserrat", sans-serif;
            font-size: 30px;
            font-weight: bold;
            color: #272727;
            text-align: center;
            .title-content-1 {
              color: #1572a1;
            }
          }
        }
        .card-login {
          border: none;
          width: 100%;
          .card-body {
            margin-top: -20px;
            width: 90%;
            margin: 0 auto;
            .email {
              .form-label {
                font-family: "Nunito Sans", sans-serif;
                font-size: 16px;
                color: #0c0c0c;
                font-weight: 600;
              }
              .form-email {
                border: 1px solid #1572a1;
                border-radius: 10px;
              }
            }
            .password {
              margin-top: 30px;
              .form-label {
                font-family: "Nunito Sans", sans-serif;
                font-size: 16px;
                color: #0c0c0c;
                font-weight: 600;
              }
              .grup-password {
                border-radius: 10px;
                .form-password {
                  border: 1px solid #1572a1;
                  border-right: none;
                  border-top-left-radius: 10px;
                  border-bottom-left-radius: 10px;
                }
                .eye {
                  padding-top: 6px;
                  padding-left: 9px;
                  padding-right: 9px;
                  border-left: none;
                  border: 1px solid #1572a1;
                  border-top-right-radius: 10px;
                  border-bottom-right-radius: 10px;
                }
                .eye1 {
                  padding-top: 6px;
                  padding-left: 8px;
                  padding-right: 8px;
                  border-left: none;
                  border: 1px solid #1572a1;
                  border-top-right-radius: 10px;
                  border-bottom-right-radius: 10px;
                }
              }
            }
            .col-pass {
              .forgot-password {
                font-family: "Nunito Sans", sans-serif;
                font-size: 14px;
                font-weight: 400;
                text-decoration: none;
                float: right;
                color: #1572a1;
                margin-bottom: 30px;
              }
            }
            .row-btn {
              .col-btn {
                .button-login {
                  .btn-login {
                    background-color: #1572a1;
                    width: 100%;
                    border-radius: 10px;
                    margin-bottom: 10px;
                    .title-login {
                      font-family: "Nunito Sans", sans-serif;
                      color: #f9f9f9;
                      font-size: 16px;
                      font-weight: bold;
                    }
                  }
                  .btn-login:hover {
                    background-color: #064463;
                    width: 100%;
                    border-radius: 10px;
                    .title-login {
                      font-family: "Nunito Sans", sans-serif;
                      color: #f9f9f9;
                      font-size: 16px;
                      font-weight: bold;
                    }
                  }
                }
              }
              .col-btn {
                .button-signup {
                  .btn-signup {
                    background-color: #f6f6f6;
                    width: 100%;
                    border-radius: 10px;
                    border: 1px solid #1572a1;
                    .title-signup {
                      .sign-up {
                        text-decoration: none;
                        font-family: "Nunito Sans", sans-serif;
                        color: #1572a1;
                        font-size: 16px;
                        font-weight: bold;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .col-img-login {
        .img-login {
          .img-login-content {
            height: 320px;
            width: 100%;
            padding-left: 50px;
            padding-right: 50px;
            padding-top: 20px;
            display: block;
          }
        }
      }
    }
 }
 
 }
 
 @media screen and (min-width: 750px) and (max-width: 1199px) {
 // login
 .container {
    .row-login {
      .col-login {
        .title-login {
          margin-bottom: 40px;
          .title-content {
            font-family: "Montserrat", sans-serif;
            font-size: 30px;
            font-weight: bold;
            color: #272727;
            text-align: center;
            .title-content-1 {
              color: #1572a1;
            }
          }
        }
        .card-login {
          border: none;
          width: 100%;
          .card-body {
            margin-top: -20px;
            width: 90%;
            margin: 0 auto;
            .email {
              .form-label {
                font-family: "Nunito Sans", sans-serif;
                font-size: 16px;
                color: #0c0c0c;
                font-weight: 600;
              }
              .form-email {
                border: 1px solid #1572a1;
                border-radius: 10px;
              }
            }
            .password {
              margin-top: 30px;
              .form-label {
                font-family: "Nunito Sans", sans-serif;
                font-size: 16px;
                color: #0c0c0c;
                font-weight: 600;
              }
              .grup-password {
                border-radius: 10px;
                .form-password {
                  border: 1px solid #1572a1;
                  border-right: none;
                  border-top-left-radius: 10px;
                  border-bottom-left-radius: 10px;
                }
                .eye {
                  padding-top: 6px;
                  padding-left: 9px;
                  padding-right: 9px;
                  border-left: none;
                  border: 1px solid #1572a1;
                  border-top-right-radius: 10px;
                  border-bottom-right-radius: 10px;
                }
                .eye1 {
                  padding-top: 6px;
                  padding-left: 8px;
                  padding-right: 8px;
                  border-left: none;
                  border: 1px solid #1572a1;
                  border-top-right-radius: 10px;
                  border-bottom-right-radius: 10px;
                }
              }
            }
            .col-pass {
              .forgot-password {
                font-family: "Nunito Sans", sans-serif;
                font-size: 14px;
                font-weight: 400;
                text-decoration: none;
                float: right;
                color: #1572a1;
                margin-bottom: 30px;
              }
            }
            .row-btn {
              .col-btn {
                .button-login {
                  .btn-login {
                    background-color: #1572a1;
                    width: 100%;
                    border-radius: 10px;
                    margin-bottom: 10px;
                    .title-login {
                      font-family: "Nunito Sans", sans-serif;
                      color: #f9f9f9;
                      font-size: 16px;
                      font-weight: bold;
                    }
                  }
                  .btn-login:hover {
                    background-color: #064463;
                    width: 100%;
                    border-radius: 10px;
                    .title-login {
                      font-family: "Nunito Sans", sans-serif;
                      color: #f9f9f9;
                      font-size: 16px;
                      font-weight: bold;
                    }
                  }
                }
              }
              .col-btn {
                .button-signup {
                  .btn-signup {
                    background-color: #f6f6f6;
                    width: 100%;
                    border-radius: 10px;
                    border: 1px solid #1572a1;
                    .title-signup {
                      .sign-up {
                        text-decoration: none;
                        font-family: "Nunito Sans", sans-serif;
                        color: #1572a1;
                        font-size: 16px;
                        font-weight: bold;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .col-img-login {
        .img-login {
          .img-login-content {
            height: 420px;
            width: 100%;
            padding-top: 20px;
            display: block;
          }
        }
      }
    }
 }
 
 } 
 
 