/*register*/
.datapribadi {
   margin-top: 80px;
   .datapribadi-label .label {
     margin-top: 25px;
     font-family: "Nunito Sans", sans-serif;
 
     .datapribadi-title {
       font-weight: bold;
       font-weight: 24px;
       .datapribadi-garis {
         border-bottom: 3px solid #1572a1;
         width: relative;
       }
     }
 
     .datapribadi-title-content-1 {
       margin-top: 10px;
       font-size: 16px;
       font-weight: 600;
     }
     .datapribadi-title-content-2 {
       margin-top: 8px;
       font-size: 16px;
       font-style: italic;
     }
     .datapribadi-garis-1 {
       border-bottom: 1.5px solid #000;
     }
   }
 
   .datapribadi-content {
      .content {
         margin-top: -5px;
         .form-row {
           .form-col {
             .card-title {
               margin-top: 21px;
               margin-bottom: 9px;
               margin-left: 40px;
               margin-right: 40px;
               height: 38px;
               background-color: #1572a1;
               font-size: 16px;
               font-weight: 400;
               color: #fff;
               padding-top: 8px;
               padding-left: 16px;
               border-top-right-radius: 10px;
               border-top-left-radius: 10px;
             }
             .card {
               border: 1px solid #6d6f71;
               height: relative;
               margin-left: 40px;
               margin-right: 40px;
               border-bottom-left-radius: 10px;
               border-bottom-right-radius: 10px;
               border-top-right-radius: 0px;
               border-top-left-radius: 0px;
               border-top: none;
               margin-top: -9px;
               .card-body {
                  .btn-add{
                     background-color: #1572A1;
                     color: #fff;
                     left: 0;
                     width: 40px;
                     float: right;
                  }
                  .content-table{
                     margin-top: 35px;
                     margin-bottom: 10px;
                     width: 100%; 
                     border-collapse: collapse; 
                     .table-content{
                        width: 100%; 
                        border-bottom: none;
                        .head-table-head{
                           .head-table{
                              font-size: 12px;
                              font-family: "Nunito Sans", sans-serif;
                              .anggota{
                                 width: 8%;
                              }
                              .name{
                                 width: 18%;
                              }
                              .gender{
                                 width: 2%;
                                 text-align: center;
                              }
                              .anak{
                                 width: 5%;
                                 text-align: center;
                              }
                              .tempat{
                                 width: 12%;
                              }
                              .tanggal{
                              }
                              .usia{
                                 width: 5%;
                              }
                              // .pendidikan{
                              //    text-align: center;
                              // }
                              // .pekerjaan{
                              //    text-align: center;
                              // }
                              // .keterangan{
                              //    text-align: center;
                              // }
                              .alamat{
                                 width: 15%;
                              }
                              .hp{
                                 width: 15%;
                              }
                              .hub{
                                 width: 15%;
                              }
                              // .riwayat-pendidikan{
                              //    text-align: center;
                              // }
                              // .riwayat-sekolah{
                              //    text-align: center;
                              // }
                              // .riwayat-jurusan{
                              //    text-align: center;
                              // }
                              // .riwayat-tempat{
                              //    text-align: center;
                              // }
                              // .riwayat-masuk{
                              //    text-align: center;
                              // }
                              // .riwayat-keluar{
                              //    text-align: center;
                              // }
                              // .riwayat-company{
                              //    text-align: center;
                              // }
                              // .riwayat-position{
                              //    text-align: center;
                              // }
                              // .riwayat-address{
                              //    text-align: center;
                              // }
                              // .riwayat-tanggalmasuk{
                              //    text-align: center;
                              // }
                              // .riwayat-tanggalkeluar{
                              //    text-align: center;
                              // }
                              // .riwayat-alasan{
                              //    text-align: center;
                              // }
                              // .riwayat-tipe{
                              //    text-align: center;
                              // }
                              // .riwayat-penyakit{
                              //    text-align: center;
                              // }
                              // .riwayat-penyembuhan{
                              //    text-align: center;
                              // }
                              // .riwayat-tahunkejadian{
                              //    text-align: center;
                              // }
                              // .riwayat-akibat{
                              //    text-align: center;
                              // }
                           }
                        }
                        .body-table-body{
                           .body-table{
                              .btn-hapus{
                                 float: left;
                                 color: #fff;
                                 background-color: #DA251C;
                                .icon{
                                 font-size: 14px;  
                                }
                              }
                           }
                        }
                        
                     }
                     
                  }
                  .note{
                     font-size: 11px;
                  }
                 
               }
             }
           }
     
           
           .form-button {
             .button-inputdata {
               height: 40px;
               width: 15%;
               background-color: #044f9f;
               border-radius: 5px;
               border: none;
               margin-top: 16px;
     
               .inputdata {
                 color: #fff;
                 font-family: "Nunito Sans", sans-serif;
                 font-weight: bold;
                 text-decoration: none;
                 font-size: 14px;
                 padding-top: 16px;
                 padding-bottom: 16px;
               }
             }
     
             .button-inputdata:hover {
               height: 40px;
               width: 15%;
               background-color: #022d72;
               border-radius: 5px;
               border: none;
               margin-top: 16px;
             }
           }
         }
       }
   }
   
}

/*reponsive*/
@media screen and (min-width: 240px) and (max-width: 580px) {
/*register*/
.inputdata {
     margin-top: 80px;
     .inputdata-label .label {
       margin-top: 25px;
       font-family: "Nunito Sans", sans-serif;
 
       .inputdata-title {
         font-weight: bold;
         font-weight: 24px;
         .inputdata-garis {
           border-bottom: 3px solid #1572a1;
           width: relative;
         }
       }
 
       .inputdata-title-content-1 {
         margin-top: 10px;
         font-size: 16px;
         font-weight: 600;
       }
       .inputdata-title-content-2 {
         margin-top: 8px;
         font-size: 16px;
         font-style: italic;
       }
       .inputdata-garis-1 {
         border-bottom: 1.5px solid #000;
       }
     }
 
     .inputdata-label .content {
       margin-top: -5px;
       .form-row {
         .form-col {
           .card-title {
             margin-top: 21px;
             margin-left: 0px;
             margin-right: 0px;
             height: 38px;
             background-color: #1572a1;
             font-size: 16px;
             font-weight: 400;
             color: #fff;
             padding-top: 8px;
             padding-left: 16px;
             border-top-right-radius: 10px;
             border-top-left-radius: 10px;
           }
           .card {
             border: 1px solid #6d6f71;
             height: relative;
             margin-left: 0px;
             margin-right: 0px;
             border-bottom-left-radius: 10px;
             border-bottom-right-radius: 10px;
             border-top-right-radius: 0px;
             border-top-left-radius: 0px;
             border-top: none;
             margin-top: -9px;
 
             .col-form-label {
               font-size: 16px;
               font-weight: 400;
 
               .wajib {
                 color: red;
                 margin-left: 5px;
                 font-weight: bold;
               }
             }
             .col-form-input .input {
               border: 1px solid #000;
             }
             .card-body {
               .row-password {
                 .col-form-input {
                   .grup-password {
                     .input {
                       border-right: none;
                     }
                     .eye {
                       padding-top: 3px;
                       padding-left: 9px;
                       padding-right: 9px;
                       border-left: none;
                       border: 1px solid #000;
                       border-top-right-radius: 5px;
                       border-bottom-right-radius: 5px;
                       background-color: #e4e4e4;
                     }
                     .eye1 {
                       padding-top: 3px;
                       padding-left: 8px;
                       padding-right: 8px;
                       border-left: none;
                       border: 1px solid #000;
                       border-top-right-radius: 5px;
                       border-bottom-right-radius: 5px;
                       background-color: #e4e4e4;
                     }
                   }
                 }
               }
             }
           }
         }
 
         .form-noted {
           .card {
             background-color: #e9a53b;
             border: none;
             height: relative;
             margin-left: 0px;
             margin-right: 0px;
             margin-top: 16px;
             .card-body {
               font-family: "Nunito Sans", sans-serif;
               .span-1 {
                 font-size: 12px;
                 font-weight: 400;
               }
               .span-2 {
                 font-size: 12px;
                 font-weight: 400;
               }
               .span-bold {
                 font-size: 12px;
                 font-weight: 600;
               }
             }
           }
         }
 
         .form-button {
           .button-inputdata {
             height: 40px;
             width: 50%;
             background-color: #044f9f;
             border-radius: 5px;
             border: none;
             margin-top: 16px;
 
             .inputdata {
               color: #fff;
               font-family: "Nunito Sans", sans-serif;
               font-weight: bold;
               text-decoration: none;
               font-size: 14px;
               padding-top: 16px;
               padding-bottom: 16px;
             }
           }
 
           .button-inputdata:hover {
             height: 40px;
             width: 50%;
             background-color: #022d72;
             border-radius: 5px;
             border: none;
             margin-top: 16px;
           }
         }
       }
     }
}
 
}
 
@media screen and (min-width: 580px) and (max-width: 750px) {
/*register*/
.inputdata {
   margin-top: 80px;
   .inputdata-label .label {
     margin-top: 25px;
     font-family: "Nunito Sans", sans-serif;
 
     .inputdata-title {
       font-weight: bold;
       font-weight: 24px;
       .inputdata-garis {
         border-bottom: 3px solid #1572a1;
         width: relative;
       }
     }
 
     .inputdata-title-content-1 {
       margin-top: 10px;
       font-size: 16px;
       font-weight: 600;
     }
     .inputdata-title-content-2 {
       margin-top: 8px;
       font-size: 16px;
       font-style: italic;
     }
     .inputdata-garis-1 {
       border-bottom: 1.5px solid #000;
     }
   }
 
   .inputdata-label .content {
     margin-top: -5px;
     .form-row {
       .form-col {
         .card-title {
           margin-top: 21px;
           margin-left: 0px;
           margin-right: 0px;
           height: 38px;
           background-color: #1572a1;
           font-size: 16px;
           font-weight: 400;
           color: #fff;
           padding-top: 8px;
           padding-left: 16px;
           border-top-right-radius: 10px;
           border-top-left-radius: 10px;
         }
         .card {
           border: 1px solid #6d6f71;
           height: relative;
           margin-left: 0px;
           margin-right: 0px;
           border-bottom-left-radius: 10px;
           border-bottom-right-radius: 10px;
           border-top-right-radius: 0px;
           border-top-left-radius: 0px;
           border-top: none;
           margin-top: -9px;
 
           .col-form-label {
             font-size: 16px;
             font-weight: 400;
 
             .wajib {
               color: red;
               margin-left: 5px;
               font-weight: bold;
             }
           }
           .col-form-input .input {
             border: 1px solid #000;
           }
           .card-body {
             .row-password {
               .col-form-input {
                 .grup-password {
                   .input {
                     border-right: none;
                   }
                   .eye {
                     padding-top: 3px;
                     padding-left: 9px;
                     padding-right: 9px;
                     border-left: none;
                     border: 1px solid #000;
                     border-top-right-radius: 5px;
                     border-bottom-right-radius: 5px;
                     background-color: #e4e4e4;
                   }
                   .eye1 {
                     padding-top: 3px;
                     padding-left: 8px;
                     padding-right: 8px;
                     border-left: none;
                     border: 1px solid #000;
                     border-top-right-radius: 5px;
                     border-bottom-right-radius: 5px;
                     background-color: #e4e4e4;
                   }
                 }
               }
             }
           }
         }
       }
 
       .form-noted {
         .card {
           background-color: #e9a53b;
           border: none;
           height: relative;
           margin-left: 0px;
           margin-right: 0px;
           margin-top: 16px;
           .card-body {
             font-family: "Nunito Sans", sans-serif;
             .span-1 {
               font-size: 14px;
               font-weight: 400;
             }
             .span-2 {
               font-size: 14px;
               font-weight: 400;
             }
             .span-bold {
               font-size: 14px;
               font-weight: 600;
             }
           }
         }
       }
 
       .form-button {
         .button-inputdata {
           height: 40px;
           width: 50%;
           background-color: #044f9f;
           border-radius: 5px;
           border: none;
           margin-top: 16px;
 
           .inputdata {
             color: #fff;
             font-family: "Nunito Sans", sans-serif;
             font-weight: bold;
             text-decoration: none;
             font-size: 14px;
             padding-top: 16px;
             padding-bottom: 16px;
           }
         }
 
         .button-inputdata:hover {
           height: 40px;
           width: 50%;
           background-color: #022d72;
           border-radius: 5px;
           border: none;
           margin-top: 16px;
         }
       }
     }
   }
}

}
 
@media screen and (min-width: 750px) and (max-width: 1199px) {
/*register*/
.inputdata {
   margin-top: 80px;
   .inputdata-label .label {
     margin-top: 25px;
     font-family: "Nunito Sans", sans-serif;
 
     .inputdata-title {
       font-weight: bold;
       font-weight: 24px;
       .inputdata-garis {
         border-bottom: 3px solid #1572a1;
         width: relative;
       }
     }
 
     .inputdata-title-content-1 {
       margin-top: 10px;
       font-size: 16px;
       font-weight: 600;
     }
     .inputdata-title-content-2 {
       margin-top: 8px;
       font-size: 16px;
       font-style: italic;
     }
     .inputdata-garis-1 {
       border-bottom: 1.5px solid #000;
     }
   }
 
   .inputdata-label .content {
     margin-top: -5px;
     .form-row {
       .form-col {
         .card-title {
           margin-top: 21px;
           margin-left: 40px;
           margin-right: 40px;
           height: 38px;
           background-color: #1572a1;
           font-size: 16px;
           font-weight: 400;
           color: #fff;
           padding-top: 8px;
           padding-left: 16px;
           border-top-right-radius: 10px;
           border-top-left-radius: 10px;
         }
         .card {
           border: 1px solid #6d6f71;
           height: relative;
           margin-left: 40px;
           margin-right: 40px;
           border-bottom-left-radius: 10px;
           border-bottom-right-radius: 10px;
           border-top-right-radius: 0px;
           border-top-left-radius: 0px;
           border-top: none;
           margin-top: -9px;
 
           .col-form-label {
             font-size: 16px;
             font-weight: 400;
 
             .wajib {
               color: red;
               margin-left: 5px;
               font-weight: bold;
             }
           }
           .col-form-input .input {
             border: 1px solid #000;
           }
           .card-body {
             .row-password {
               .col-form-input {
                 .grup-password {
                   .input {
                     border-right: none;
                   }
                   .eye {
                     padding-top: 3px;
                     padding-left: 9px;
                     padding-right: 9px;
                     border-left: none;
                     border: 1px solid #000;
                     border-top-right-radius: 5px;
                     border-bottom-right-radius: 5px;
                     background-color: #e4e4e4;
                   }
                   .eye1 {
                     padding-top: 3px;
                     padding-left: 8px;
                     padding-right: 8px;
                     border-left: none;
                     border: 1px solid #000;
                     border-top-right-radius: 5px;
                     border-bottom-right-radius: 5px;
                     background-color: #e4e4e4;
                   }
                 }
               }
             }
           }
         }
       }
 
       .form-noted {
         .card {
           background-color: #e9a53b;
           border: none;
           height: relative;
           margin-left: 40px;
           margin-right: 40px;
           margin-top: 16px;
           .card-body {
             font-family: "Nunito Sans", sans-serif;
             .span-1 {
               font-size: 14px;
               font-weight: 400;
             }
             .span-2 {
               font-size: 14px;
               font-weight: 400;
             }
             .span-bold {
               font-size: 14px;
               font-weight: 600;
             }
           }
         }
       }
 
       .form-button {
         .button-inputdata {
           height: 40px;
           width: 50%;
           background-color: #044f9f;
           border-radius: 5px;
           border: none;
           margin-top: 16px;
 
           .inputdata {
             color: #fff;
             font-family: "Nunito Sans", sans-serif;
             font-weight: bold;
             text-decoration: none;
             font-size: 14px;
             padding-top: 16px;
             padding-bottom: 16px;
           }
         }
 
         .button-inputdata:hover {
           height: 40px;
           width: 50%;
           background-color: #022d72;
           border-radius: 5px;
           border: none;
           margin-top: 16px;
         }
       }
     }
   }
}

}  


@media only screen and (max-width: 1199px),(min-device-width: 768px) and (max-device-width: 1024px)  {



	table, thead, tbody, th, td, tr { 
		display: block; 
      width: 100%;
      margin-top: 10px;
	}
	
	/* Hide table headers (but not display: none;, for accessibility) */
	thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	
	tr { 
      border: none;
      margin-bottom: 50px;
 
   }
	
	td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee; 
		position: relative;
		padding-left: 50%; 
      
	}
	
	td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: -12px;
		left: 10px;
		width: 45%; 
      font-size: 14px;
		padding-right: 10px; 
		white-space: nowrap;
	}
   .btn-hapus{
      float: left;
      margin-top: 5px;
      width: 100%;
      color: #fff;
      background-color: #DA251C;
     .icon{
      font-size: 14px;  
     }
   }
	/*
	Label the data
	*/
  .content-name:nth-of-type(1):before { content: "Name"; }
	.content-address:nth-of-type(2):before { content: "Address"; }
	.content-hndp:nth-of-type(3):before { content: "No Handphone"; }
	.content-family:nth-of-type(4):before { content: "Family relationship"; }

	.content-anggota:nth-of-type(1):before { content: "Family"; }
	.content-nama:nth-of-type(2):before { content: "Name"; }
	.content-gender:nth-of-type(3):before { content: "Gender"; }
	.content-anak:nth-of-type(4):before { content: "Child"; }
	.content-place:nth-of-type(5):before { content: "Place of Birth"; }
	.content-tanggal:nth-of-type(6):before { content: "Date of Birth"; }
	.content-usia:nth-of-type(7):before { content: "Age"; }
	.content-pendidikan:nth-of-type(8):before { content: "Last Education"; }
	.content-pekerjaan:nth-of-type(9):before { content: "Profession"; }
	.content-keterangan:nth-of-type(10):before { content: "Description"; }

   .content-riw-pendidikan:nth-of-type(1):before { content: "Education"; }
	.content-riw-sekolah:nth-of-type(2):before { content: "School name"; }
	.content-riw-jurusan:nth-of-type(3):before { content: "Majoring"; }
	.content-riw-tempat:nth-of-type(4):before { content: "Place"; }
	.content-riw-masuk:nth-of-type(5):before { content: "Year of entry"; }
	.content-riw-keluar:nth-of-type(6):before { content: "Year out"; }

   .content-riw-company:nth-of-type(1):before { content: "Company"; }
	.content-riw-position:nth-of-type(2):before { content: "Position"; }
	.content-riw-address:nth-of-type(3):before { content: "Address"; }
	.content-riw-tanggalmasuk:nth-of-type(4):before { content: "Date of entry"; }
	.content-riw-tanggalkeluar:nth-of-type(5):before { content: "Date of out"; }
	.content-riw-alasan:nth-of-type(6):before { content: "Reason to leave"; }

   .content-riw-tipe:nth-of-type(1):before { content: "Types of diseases"; }
	.content-riw-penyakit:nth-of-type(2):before { content: "Disease name"; }
	.content-riw-penyembuhan:nth-of-type(3):before { content: "Treatment"; }
	.content-riw-tahunkejadian:nth-of-type(4):before { content: "Year"; }
	.content-riw-akibat:nth-of-type(5):before { content: "Impact"; }
}