/*wishlist*/
.wishlist {
    margin-top: 80px;
    margin-bottom: 200px;
    .wishlist-container {
      .wishlist-title {
        .wishlist-title-1 {
          margin-top: 25px;
          font-family: "Nunito Sans", sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 33px;
          color: #161616;
          .wishlist-garis {
            border-bottom: 3px solid #1572a1;
            width: relative;
          }
        }
      }

      .wishlist-content{
        .row-wishlist{
          .col-wishlist{
            .dont-wish{
              .img-wishlist{
                display: block;
                margin: 0 auto;
                width: 60%;
                height: 350px;
              }
              .text-wishlist{
                text-align: center;
                font-size: 25px;
              }
            }
          

            .card-wishlist{
              border-bottom: 1px solid #999999;
              border-right: none;
              border-left: none;
              border-top: none;
              border-radius: 0px;
              padding-left: 20px;
              padding-right: 20px;
              padding-top: 20px;
              padding-bottom: 0px;
              .row-card-wishlist{
                .col-card-wishlist{
                  .title-wish{
                    display: block;
                    margin: 0 auto;
                    .title-1{
                      font-family: 'Nunito Sans';
                      font-style: normal;
                      font-weight: 700;
                      font-size: 18px;
                      line-height: 33px;
                      color: #000000;
                    }
                    .title-2{
                      font-family: 'Nunito Sans';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 22px;
                      letter-spacing: -0.014em;
                      color: #272727;
                      .img-wish{
                        height: 20px;
                        margin-top: -5px;
                      }
                    }
                  }
                }
                .col-btn-wishlist{
                  .btn-cancel{
                    border: 1px solid #EE0E0E;
                    border-radius: 5px;
                    display: block;
                    margin: 0 auto;
                    .text-delete{
                      font-family: 'Nunito Sans';
                      font-style: normal;
                      font-weight: 700;
                      font-size: 14px;
                      padding: 5px 0px;
                      text-align: center;
                      color: #EE0E0E;
                      margin:0 auto;
                    }
                  }
                  .btn-detail{
                    background: #1572A1;
                    border-radius: 5px;
                    display: block;
                    margin: 8px auto;
                    .text-detail{
                      font-family: 'Nunito Sans';
                      font-style: normal;
                      font-weight: 700;
                      font-size: 14px;
                      text-align: center;
                      color: #EDEDED;
                      margin:0 auto;
                      padding: 5px 0px;

                    }
                  }

                  .btn-detail:hover{
                    background: #015c80;
                    border-radius: 5px;
                    display: block;
                    margin: 8px auto;
                    .text-detail{
                      font-family: 'Nunito Sans';
                      font-style: normal;
                      font-weight: 700;
                      font-size: 14px;
                      text-align: center;
                      color: #EDEDED;
                      margin:0 auto;
                      padding: 5px 0px;

                    }
                  }
                }
              }
            }
          }
        }
      }
     
    }
  
}

.pp-container{
  height: 500px;
}

@media screen and (min-width: 230px) and (max-width: 580px){
  .wishlist {
    margin-top: 80px;
    margin-bottom: 150px;
    .wishlist-container {
      .wishlist-title {
        .wishlist-title-1 {
          margin-top: 25px;
          font-family: "Nunito Sans", sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 33px;
          color: #161616;
          .wishlist-garis {
            border-bottom: 3px solid #1572a1;
            width: relative;
          }
        }
      }

      .wishlist-content{
        .row-wishlist{
          .col-wishlist{
            .dont-wish{
              .img-wishlist{
                display: block;
                margin: 0 auto;
                width: 70%;
                height: 300px;
              }
              .text-wishlist{
                text-align: center;
                font-size: 20px;
              }
            }
            .card-wishlist{
              border-bottom: 1px solid #999999;
              border-right: none;
              border-left: none;
              border-top: none;
              border-radius: 0px;
              padding-left: 20px;
              padding-right: 20px;
              padding-top: 20px;
              padding-bottom: 10px;
              .row-card-wishlist{
                .col-card-wishlist{
                  .title-wish{
                    text-align: center;
                    .title-1{
                      font-family: 'Nunito Sans';
                      font-style: normal;
                      font-weight: 700;
                      font-size: 15px;
                      line-height: 33px;
                      color: #000000;
                    }
                    .title-2{
                      font-family: 'Nunito Sans';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 13px;
                      line-height: 22px;
                      letter-spacing: -0.014em;
                      color: #272727;
                      .img-wish{
                        height: 20px;
                        margin-top: -5px;
                      }
                    }
                  }
                }
                .col-btn-wishlist{
                  .btn-cancel{
                    border: 1px solid #EE0E0E;
                    border-radius: 5px;
                    display: block;
                    margin: 0 auto;
                    .text-delete{
                      font-family: 'Nunito Sans';
                      font-style: normal;
                      font-weight: 700;
                      font-size: 11px;
                      padding: 5px 0px;
                      text-align: center;
                      color: #EE0E0E;
                      margin:0 auto;
                     
                    }
                  }
                  .btn-detail{
                    background: #1572A1;
                    border-radius: 5px;
                    display: block;
                    margin: 0 auto;
                    width: 50%;
                    .text-detail{
                      font-family: 'Nunito Sans';
                      font-style: normal;
                      font-weight: 700;
                      font-size: 11px;
                      text-align: center;
                      color: #EDEDED;
                      margin:0 auto;
                      padding: 5px 0px;

                    }
                  }
                  .btn-detail:hover{
                    background: #015c80;
                    border-radius: 5px;
                    display: block;
                    margin: 0 auto;
                    .text-detail{
                      font-family: 'Nunito Sans';
                      font-style: normal;
                      font-weight: 700;
                      font-size: 11px;
                      text-align: center;
                      color: #EDEDED;
                      margin:0 auto;
                      padding: 5px 0px;

                    }
                  }
                  
                }
              }
            }
          }
        }
      }
     
    }
  
}
}

@media screen and (min-width: 580px) and (max-width: 768px){
  .wishlist {
    margin-top: 80px;
    margin-bottom: 150px;
    .wishlist-container {
      .wishlist-title {
        .wishlist-title-1 {
          margin-top: 25px;
          font-family: "Nunito Sans", sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 33px;
          color: #161616;
          .wishlist-garis {
            border-bottom: 3px solid #1572a1;
            width: relative;
          }
        }
      }

      .wishlist-content{
        .row-wishlist{
          .col-wishlist{
            .dont-wish{
              .img-wishlist{
                display: block;
                margin: 0 auto;
                width: 60%;
                height: 300px;
              }
              .text-wishlist{
                text-align: center;
                font-size: 20px;
              }
            }
            .card-wishlist{
              border-bottom: 1px solid #999999;
              border-right: none;
              border-left: none;
              border-top: none;
              border-radius: 0px;
              padding-left: 20px;
              padding-right: 20px;
              padding-top: 20px;
              padding-bottom: 0px;
              .row-card-wishlist{
                .col-card-wishlist{
                  .title-wish{
                    text-align: center;
                    .title-1{
                      font-family: 'Nunito Sans';
                      font-style: normal;
                      font-weight: 700;
                      font-size: 15px;
                      line-height: 33px;
                      color: #000000;
                    }
                    .title-2{
                      font-family: 'Nunito Sans';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 13px;
                      line-height: 22px;
                      letter-spacing: -0.014em;
                      color: #272727;
                      .img-wish{
                        height: 20px;
                        margin-top: -5px;
                      }
                    }
                  }
                }
                .col-btn-wishlist{
                  .btn-cancel{
                    border: 1px solid #EE0E0E;
                    border-radius: 5px;
                    display: block;
                    margin: 0 auto;
                    .text-delete{
                      font-family: 'Nunito Sans';
                      font-style: normal;
                      font-weight: 700;
                      font-size: 11px;
                      padding: 5px 0px;
                      text-align: center;
                      color: #EE0E0E;
                      margin:0 auto;
                     
                    }
                  }
                  .btn-detail{
                    background: #1572A1;
                    border-radius: 5px;
                    display: block;
                    margin: 8px auto;
                    .text-detail{
                      font-family: 'Nunito Sans';
                      font-style: normal;
                      font-weight: 700;
                      font-size: 11px;
                      text-align: center;
                      color: #EDEDED;
                      margin:0 auto;
                      padding: 5px 0px;

                    }
                  }
                  .btn-detail:hover{
                    background: #015c80;
                    border-radius: 5px;
                    display: block;
                    margin: 8px auto;
                    .text-detail{
                      font-family: 'Nunito Sans';
                      font-style: normal;
                      font-weight: 700;
                      font-size: 11px;
                      text-align: center;
                      color: #EDEDED;
                      margin:0 auto;
                      padding: 5px 0px;

                    }
                  }
                }
              }
            }
          }
        }
      }
     
    }
  
}
}

@media screen and (min-width: 768px) and (max-width: 1199px){
  .wishlist {
    margin-top: 80px;
    margin-bottom: 150px;
    .wishlist-container {
      .wishlist-title {
        .wishlist-title-1 {
          margin-top: 25px;
          font-family: "Nunito Sans", sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 33px;
          color: #161616;
          .wishlist-garis {
            border-bottom: 3px solid #1572a1;
            width: relative;
          }
        }
      }

      .wishlist-content{
        .row-wishlist{
          .col-wishlist{
            .dont-wish{
              .img-wishlist{
                display: block;
                margin: 0 auto;
                width: 80%;
                height: 300px;
              }
              .text-wishlist{
                text-align: center;
                font-size: 25px;
              }
            }
            .card-wishlist{
              border-bottom: 1px solid #999999;
              border-right: none;
              border-left: none;
              border-top: none;
              border-radius: 0px;
              padding-right: 20px;
              padding-top: 20px;
              padding-bottom: 0px;
              .row-card-wishlist{
                .col-card-wishlist{
                  .title-wish{
                    .title-1{
                      font-family: 'Nunito Sans';
                      font-style: normal;
                      font-weight: 700;
                      font-size: 15px;
                      line-height: 33px;
                      color: #000000;
                    }
                    .title-2{
                      font-family: 'Nunito Sans';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 13px;
                      line-height: 22px;
                      letter-spacing: -0.014em;
                      color: #272727;
                      .img-wish{
                        height: 20px;
                        margin-top: -5px;
                      }
                    }
                  }
                }
                .col-btn-wishlist{
                  .btn-cancel{
                    border: 1px solid #EE0E0E;
                    border-radius: 5px;
                    display: block;
                    margin: 0 auto;
         
                    .text-delete{
                      font-family: 'Nunito Sans';
                      font-style: normal;
                      font-weight: 700;
                      font-size: 11px;
                      padding: 5px 0px;
                      text-align: center;
                      color: #EE0E0E;
                      margin:0 auto;
                     
                    }
                  }
                  .btn-detail{
                    background: #1572A1;
                    border-radius: 5px;
                    display: block;
                    margin: 8px auto;
                    .text-detail{
                      font-family: 'Nunito Sans';
                      font-style: normal;
                      font-weight: 700;
                      font-size: 11px;
                      text-align: center;
                      color: #EDEDED;
                      margin:0 auto;
                      padding: 5px 0px;

                    }
                  }
                  .btn-detail:hover{
                    background: #015c80;
                    border-radius: 5px;
                    display: block;
                    margin: 8px auto;
                    .text-detail{
                      font-family: 'Nunito Sans';
                      font-style: normal;
                      font-weight: 700;
                      font-size: 11px;
                      text-align: center;
                      color: #EDEDED;
                      margin:0 auto;
                      padding: 5px 0px;

                    }
                  }
                }
              }
            }
          }
        }
      }
     
    }
  
}
}
