
.attedance{
    margin-top: 30px;
    .title-attedance{
        .title-text{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            line-height: 44px;
            letter-spacing: -0.022em;
            color: #272727;
            text-align: center;
            .title-span{
                color: #1572A1;
            }
        }
    }
    .waktu{
        .work-in{
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 15px;
            line-height: 20px;
            text-align: center;
            color: #000000;
        }
    }

    .waktu-attedance{
        .work-attedance{
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 15px;
            line-height: 20px;
            text-align: center;
            color: #000000;
            margin-bottom: 20px;
            .gambar-clock{
                height: 30px;
                margin-right: 10px;
            }
        }
    }

    .container-camera{
        .row-camera{
            .col-camera{
                .row-input{
                    .col-input{
                        .card-attedance{
                            width: 50%;
                            display: block;
                            margin: 0 auto;
                            border: none;
                            .label-attedance{
                                font-family: 'Nunito Sans';
                                font-style: normal;
                                font-weight: 700;
                                font-size: 16px;
                                line-height: 19px;
                                color: #000000;
                                margin-bottom: 10px;
                            }
                            .input-nik{
                                border: 1px solid #1572A1;
                            }
                            .btn-attedance{
                                background-color: #044F9F;
                                color: #fff;
                                display: block;
                                margin: 0 auto;
                                margin-top: 10px;
                                font-family: 'Nunito Sans';
                                font-style: normal;
                                font-weight: 700;
                                font-size: 14px;
                            }
                        }
                       
                    }
        
                }
            }
            .col-result{
                .row-result-1{
                    .marquee{
                        height: 400px;
                        white-space: nowrap;
                        overflow: hidden;
                        box-sizing: border-box;
                        .mark{
                            background-color: transparent;
                            animation: marquee 120s linear infinite;
                            .col-result-1{
                                    .card-result-1{
                                        border: none;
                                        border-radius: 10px;
                                        padding: 5px 10px;
                                        background-color: #D9D9D9;
                                        margin: 20px 0;
                                        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                                        backdrop-filter: blur(4px);
                                        .row-card-result-2{
                                            .col-card-result-2-1{
                                                .img-result{
                                                    height: 100px;
                                                    width: 100px;
                                                    border-radius: 50%;
                                                    margin-top: 20px;
                                                }
                                            }
            
                                            .col-card-result-2-2{
                                               .card-keterangan{
                                                border: 1px solid #D9D9D9;
                                                border-radius: 20px;
                                                padding: 5px 10px;
                                                background-color: #D9D9D9;
                                                margin: 5px 0;
                                                box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
                                                .title-name{
                                                    font-family: 'Nunito Sans';
                                                    font-style: normal;
                                                    font-weight: 700;
                                                    font-size: 16px;
                                                    line-height: 27px;
                                                    color: #000000;
                                                    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                                                    text-align: center;
                                                }
                                                .title-level{
                                                    font-family: 'Nunito Sans';
                                                    font-style: normal;
                                                    font-weight: 700;
                                                    font-size: 12px;
                                                    line-height: 14px;
                                                    color: #000000;
                                                    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                                                    .gambar-level{
                                                        height: 18px;
                                                        width: 18px;
                                                        margin-right: 10px
                                                    }
                                                }
                                                .title-time{
                                                    font-family: 'Nunito Sans';
                                                    font-style: normal;
                                                    font-weight: 700;
                                                    font-size: 12px;
                                                    line-height: 14px;
                                                    color: #000000;
                                                    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                                                    .gambar-time{
                                                        height: 18px;
                                                        width: 18px;
                                                        margin-right: 10px
                                                    }
                                                }
                                                .title-ket{
                                                    font-family: 'Nunito Sans';
                                                    font-style: normal;
                                                    font-weight: 700;
                                                    font-size: 14px;
                                                    line-height: 14px;
                                                    color: #000000;
                                                    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                                                    .gambar-ket{
                                                        height: 18px;
                                                        width: 18px;
                                                        margin-right: 10px;
                                                        
                                                    }
                                                }
                                               }
                                            }
                                        }
                                        
                                    }
                                }
                        }
                       
                    }
                    
                }
 
                
            }
            .col-result:hover{
                .row-result-1{
                    .marquee{
                        height: 400px;
                        white-space: nowrap;
                        overflow: hidden;
                        box-sizing: border-box;
                        .mark{
                            animation-play-state: paused;
                            .col-result-1{
                                
                                    .card-result-1{
                                        border: none;
                                        border-radius: 10px;
                                        padding: 5px 10px;
                                        background-color: #D9D9D9;
                                        margin: 20px 0;
                                        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                                        backdrop-filter: blur(4px);
                                        .row-card-result-2{
                                            .col-card-result-2-1{
                                                .img-result{
                                                    height: 100px;
                                                    width: 100px;
                                                    border-radius: 50%;
                                                    margin-top: 20px;
                                                }
                                            }
            
                                            .col-card-result-2-2{
                                               .card-keterangan{
                                                border: 1px solid #D9D9D9;
                                                border-radius: 20px;
                                                padding: 5px 10px;
                                                background-color: #D9D9D9;
                                                margin: 5px 0;
                                                box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
                                                .title-name{
                                                    font-family: 'Nunito Sans';
                                                    font-style: normal;
                                                    font-weight: 700;
                                                    font-size: 16px;
                                                    line-height: 27px;
                                                    color: #000000;
                                                    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                                                }
                                                .title-level{
                                                    font-family: 'Nunito Sans';
                                                    font-style: normal;
                                                    font-weight: 700;
                                                    font-size: 12px;
                                                    line-height: 14px;
                                                    color: #000000;
                                                    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                                                    .gambar-level{
                                                        height: 18px;
                                                        width: 18px;
                                                        margin-right: 10px
                                                    }
                                                }
                                                .title-time{
                                                    font-family: 'Nunito Sans';
                                                    font-style: normal;
                                                    font-weight: 700;
                                                    font-size: 12px;
                                                    line-height: 14px;
                                                    color: #000000;
                                                    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                                                    .gambar-time{
                                                        height: 18px;
                                                        width: 18px;
                                                        margin-right: 10px
                                                    }
                                                }
                                                .title-ket{
                                                    font-family: 'Nunito Sans';
                                                    font-style: normal;
                                                    font-weight: 700;
                                                    font-size: 14px;
                                                    line-height: 14px;
                                                    color: #000000;
                                                    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                                                    .gambar-ket{
                                                        height: 18px;
                                                        width: 18px;
                                                        margin-right: 10px;
                                                        
                                                    }
                                                }
                                               }
                                            }
                                        }
                                        
                                    }
                                }
                        }
                       
                    }
                    
                }
           
            }
        }
    }

    @-webkit-keyframes marquee {
        0%   { -webkit-transform: translate(0, 0); }
        100% { -webkit-transform: translate(0, -100%); }
      } 
      @keyframes marquee {
        0%   { -webkit-transform: translate(0, 0); }
        100% { -webkit-transform: translate(0, -100%); }
      } 
 
}

.c-camera-feed{
    text-align: center;
    .c-camera-feed__viewer{
        .video{
            width: 100%;
            height: 350px;
            border-radius: 5px;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
        }
       
    }
    .capture{
        border: none;
        border-radius: 5px;
        background-color: transparent;
        margin-top: -45px;
        margin-left: -15px;
        position: absolute;
        .icon-capture{
            color: rgb(255, 255, 255);
            font-size: 25px;
        }
    }
    .c-camera-feed__stage{
        margin-top: -207px;
        .priv{
            margin-top: -150px;
            border-radius: 5px;
            height: 350px;
            width: 470px;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
        }
    }

}

@media screen and (min-width: 240px) and (max-width: 580px) {
    .attedance{
        width: 100%;
        margin-top: 20px;
        .title-attedance{
            .title-text{
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 44px;
                letter-spacing: -0.022em;
                color: #272727;
                text-align: center;
                .title-span{
                    color: #1572A1;
                }
            }
        }
        .waktu{
            .work-in{
                font-family: 'Nunito Sans';
                font-style: normal;
                font-weight: 700;
                font-size: 15px;
                line-height: 20px;
                text-align: center;
                color: #000000;
            }
        }
    
        .waktu-attedance{
            .work-attedance{
                font-family: 'Nunito Sans';
                font-style: normal;
                font-weight: 700;
                font-size: 15px;
                line-height: 20px;
                text-align: center;
                color: #000000;
                margin-bottom: 20px;
                .gambar-clock{
                    height: 30px;
                    margin-right: 10px;
                }
            }
        }
    
        .container-camera{
            .row-camera{
                .col-camera{
                    .row-input{
                        .col-input{
                            .card-attedance{
                                width: 90%;
                                display: block;
                                margin: 0 auto;
                                border: none;
                                
                                .label-attedance{
                                    font-family: 'Nunito Sans';
                                    font-style: normal;
                                    font-weight: 700;
                                    font-size: 16px;
                                    line-height: 19px;
                                    color: #000000;
                                    margin-bottom: 10px;
                                }
                                .input-nik{
                                    border: 1px solid #1572A1;
                                }
                                .btn-attedance{
                                    background-color: #044F9F;
                                    color: #fff;
                                    display: block;
                                    margin: 0 auto;
                                    margin-top: 10px;
                                    font-family: 'Nunito Sans';
                                    font-style: normal;
                                    font-weight: 700;
                                    font-size: 14px;
                                }
                            }
                           
                        }
            
                    }
                }
                .col-result{
                    .row-result-1{
                        margin-top: 40px;
                        padding: 0 20px;
                        .marquee{
                            height: 400px;
                            white-space: nowrap;
                            overflow: hidden;
                            box-sizing: border-box;
                            .mark{
                                background-color: transparent;
                                animation: marquee 120s linear infinite;
                                .col-result-1{
                                    margin-top: 20px;
                                        .card-result-1{
                                            border: none;
                                            border-radius: 10px;
                                            padding: 5px 10px;
                                            background-color: #D9D9D9;
                                            margin: 5px 0;
                                            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                                            backdrop-filter: blur(4px);
                                            .row-card-result-2{
                                                .col-card-result-2-1{
                                                    .img-result{
                                                        height: 90px;
                                                        width: 90px;
                                                        border-radius: 50%;
                                                        margin-top: 20px;
                                                    }
                                                }
                
                                                .col-card-result-2-2{
                                                   .card-keterangan{
                                                    border: 1px solid #D9D9D9;
                                                    border-radius: 20px;
                                                    padding: 5px 10px;
                                                    background-color: #D9D9D9;
                                                    margin: 5px 0;
                                                    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
                                                    .title-name{
                                                        font-family: 'Nunito Sans';
                                                        font-style: normal;
                                                        font-weight: 700;
                                                        font-size: 14px;
                                                        line-height: 27px;
                                                        color: #000000;
                                                        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                                                    }
                                                    .title-level{
                                                        font-family: 'Nunito Sans';
                                                        font-style: normal;
                                                        font-weight: 700;
                                                        font-size: 12px;
                                                        line-height: 14px;
                                                        color: #000000;
                                                        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                                                        .gambar-level{
                                                            height: 18px;
                                                            width: 18px;
                                                            margin-right: 10px
                                                        }
                                                    }
                                                    .title-time{
                                                        font-family: 'Nunito Sans';
                                                        font-style: normal;
                                                        font-weight: 700;
                                                        font-size: 12px;
                                                        line-height: 14px;
                                                        color: #000000;
                                                        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                                                        .gambar-time{
                                                            height: 18px;
                                                            width: 18px;
                                                            margin-right: 10px
                                                        }
                                                    }
                                                    .title-ket{
                                                        font-family: 'Nunito Sans';
                                                        font-style: normal;
                                                        font-weight: 700;
                                                        font-size: 14px;
                                                        line-height: 14px;
                                                        color: #000000;
                                                        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                                                        .gambar-ket{
                                                            height: 18px;
                                                            width: 18px;
                                                            margin-right: 10px;
                                                            
                                                        }
                                                    }
                                                   }
                                                }
                                            }
                                            
                                        }
                                    }
                            }
                           
                        }
                        
                    }
                    
                }
                .col-result:hover{
                    .row-result-1{
                        .marquee{
                            height: 400px;
                            white-space: nowrap;
                            overflow: hidden;
                            box-sizing: border-box;
                            .mark{
                                animation-play-state: paused;
                                .col-result-1{
                                        .card-result-1{
                                            border: none;
                                            border-radius: 10px;
                                            padding: 5px 10px;
                                            background-color: #D9D9D9;
                                            margin: 5px 0;
                                            .row-card-result-2{
                                                .col-card-result-2-1{
                                                    .img-result{
                                                        height: 90px;
                                                        width: 90px;
                                                        border-radius: 50%;
                                                        margin-top: 20px;
                                                    }
                                                }
                
                                                .col-card-result-2-2{
                                                   .card-keterangan{
                                                    border: 1px solid #D9D9D9;
                                                    border-radius: 20px;
                                                    padding: 5px 10px;
                                                    background-color: #D9D9D9;
                                                    margin: 5px 0;
                                                    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
                                                    .title-name{
                                                        font-family: 'Nunito Sans';
                                                        font-style: normal;
                                                        font-weight: 700;
                                                        font-size: 14px;
                                                        line-height: 27px;
                                                        color: #000000;
                                                        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                                                    }
                                                    .title-level{
                                                        font-family: 'Nunito Sans';
                                                        font-style: normal;
                                                        font-weight: 700;
                                                        font-size: 12px;
                                                        line-height: 14px;
                                                        color: #000000;
                                                        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                                                        .gambar-level{
                                                            height: 18px;
                                                            width: 18px;
                                                            margin-right: 10px
                                                        }
                                                    }
                                                    .title-time{
                                                        font-family: 'Nunito Sans';
                                                        font-style: normal;
                                                        font-weight: 700;
                                                        font-size: 12px;
                                                        line-height: 14px;
                                                        color: #000000;
                                                        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                                                        .gambar-time{
                                                            height: 18px;
                                                            width: 18px;
                                                            margin-right: 10px
                                                        }
                                                    }
                                                    .title-ket{
                                                        font-family: 'Nunito Sans';
                                                        font-style: normal;
                                                        font-weight: 700;
                                                        font-size: 14px;
                                                        line-height: 14px;
                                                        color: #000000;
                                                        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                                                        .gambar-ket{
                                                            height: 18px;
                                                            width: 18px;
                                                            margin-right: 10px;
                                                            
                                                        }
                                                    }
                                                   }
                                                }
                                            }
                                            
                                        }
                                    }
                            }
                           
                        }
                        
                    }
                    
                }
            }
        }
        @-webkit-keyframes marquee {
            0%   { -webkit-transform: translate(0, 0); }
            100% { -webkit-transform: translate(0, -100%); }
          } 
          @keyframes marquee {
            0%   { -webkit-transform: translate(0, 0); }
            100% { -webkit-transform: translate(0, -100%); }
          } 
     
    }
    
    .c-camera-feed{
        text-align: center;
        .c-camera-feed__viewer{
            .video{
                width: 100%;    
                border-radius: 5px;
                -webkit-transform: scaleX(-1);
                transform: scaleX(-1);
            }
           
        }
        .capture{
            border: none;
            border-radius: 5px;
            background-color: transparent;
            margin-top: -45px;
            margin-left: -15px;
            position: absolute;
            .icon-capture{
                color: rgb(255, 255, 255);
                font-size: 25px;
            }
        }
        .c-camera-feed__stage{
            .priv{
                margin-top: -157px;
                border-radius: 5px;
                height: 360px;
                width: 100%;
                -webkit-transform: scaleX(-1);
                transform: scaleX(-1);
            }
        }
    
    }
}

@media screen and (min-width: 580px) and (max-width: 750px) {}

@media screen and (min-width: 750px) and (max-width: 1199px) {}