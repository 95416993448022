/*profile*/
.profile {
    margin-top: 100px;
    .container-profile {
      .row-profile {
       
        .name-picture {
          font-family: "Nunito Sans";
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 25px;
          text-align: center;
          color: #1a1a1a;
          margin-top: -25px;
        }
        .col-img {
         .bord{
          border: 56px solid rgb(255, 255, 255);
          height: 260px;
          width: 260px;
          border-radius: 50%;
          margin-bottom: -215px;
          z-index: -9999;
          margin-left: auto;
          margin-right: auto;
          display: block;
         } 
          .picture {
            .bord1{
              border: 5px solid #1572a1;
              height: 170px;
              width: 170px;
              margin-left: auto;
              margin-right: auto;
              display: block;
              border-radius: 100%;
              margin-bottom: -180px;
             }
  
            .img-profile {
              width: 150px;
              height: 215px;
              display: block;
              margin-left: auto;
              margin-right: auto;
              margin-top: 6px;
              margin-bottom: 6px;
              z-index: -999;
              position: relative;
              border-radius: 36%;
              border: 1px solid #ffffff;
            }
        
          }
         
        }
      }
  
      .row-btn-profile {
        .nav-tabs{
          margin-top: 30px;
          text-align: center;
          .nav-item{
            color: white;
            float: left;
            border: none;
            outline: none;
            cursor: pointer;
            padding: 5px 5px;
            font-size: 14px;
            width: 16.6%;
            font-family: "Nunito Sans";
              font-style: normal;
              font-weight: 700;
              .nav-link{
                background-color: #dfdfdf;
                padding: 10px 30px;
                color: #000;
                width: 100%;
                margin-bottom: -6px;
              }
              .nav-link:hover{
                background-color: #1572a1;
                padding: 10px 30px;
                color: #fff;
              }
              .nav-link.active{
                background-color: #1572a1;
                padding: 10px 30px;
                color: #fff;
              }
          }
        }
        .col-btn {
          margin-bottom: 10px;
          margin-top: 25px;
          color: #000;
          .btn-edit {
            border: 1px solid #1572a1;
            background-color: #1572a1;
            border-radius: 10px;
            display: block;
            margin: 0 auto;
            width: 100%;
            .text-edit {
              font-family: "Nunito Sans";
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 19px;
              text-align: center;
              color: #ededed;
              margin-top: 10px;
              margin-left: 15px;
              margin-right: 15px;
              .img-edit {
                margin-top: -2px;
                margin-right: 5px;
              }
            }
          }
          .btn-pwd {
            border: 1px solid #1572a1;
            background-color: #1572a1;
            border-radius: 10px;
            display: block;
            margin: 0 auto;
            width: 100%;
            .text-pwd {
              font-family: "Nunito Sans";
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 19px;
              text-align: center;
              color: #ededed;
              margin-top: 10px;
              margin-left: 15px;
              margin-right: 15px;
              .img-edit {
                margin-top: -2px;
                margin-right: 5px;
              }
            }
          }
        }
        .col-profile-text {
          .profile-text {
            font-family: "Nunito Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            letter-spacing: -0.014em;
            color: #999999;
          }
        }
      }
  
      .row-data {
        margin-bottom: 20px;
        .col-data{
          .card-data{
            border: 2px solid #999999;
            padding: 20px 20px;
            border-radius: 10px;
            margin-top: 20px;
            min-height: 320px;
            .title-data {
              font-family: "Nunito Sans";
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              line-height: 25px;
              margin-bottom: 10px;
              color: #1a1a1a;
            }
            .container-content{
              .row-content{
                .col-label-data {
                  font-family: "Nunito Sans";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 22px;
                  letter-spacing: -0.014em;
                  color: #050505;
                  margin-bottom: 5px;
                }
                .col-content-data {
                  .text-data {
                    font-family: "Nunito Sans";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 22px;
                    letter-spacing: -0.014em;
                    color: #000000;
                  }
                }
              }
            }
            .web{
              .table-data{
                font-family: "Nunito Sans";
                font-style: normal;
                font-size: 14px;
                .thead-table{
                  .tr-table{
                    .content-type_penyakit{
                      border: 2px solid;
                    }
                  }
                }
              }
            }
            .mob{
              display: none;
            }
            
            .card-body{
              .row-data{
                .col-form-label{
                  font-size: 14px;
                }
                .col-form-input{
                  .text-data{
                    padding-top: 8px;
                    font-size: 14px;
                  }
                }
              }
            }
          
          }
        }
       
        
        
      }
    }
}

/*reponsive*/
@media screen and (min-width: 240px) and (max-width: 575px) {
/*profile*/
.profile {
    margin-top: 100px;
    .container-profile {
      .row-profile {
        .name-picture {
          font-family: "Nunito Sans";
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 25px;
          text-align: center;
          color: #1a1a1a;
          margin-top: -25px;
        }
        .col-img {
         .bord{
          border: 56px solid rgb(255, 255, 255);
          height: 260px;
          width: 260px;
          border-radius: 50%;
          margin-bottom: -215px;
          z-index: -9999;
          margin-left: auto;
          margin-right: auto;
          display: block;
         } 
          .picture {
            .bord1{
              border: 5px solid #1572a1;
              height: 170px;
              width: 170px;
              margin-left: auto;
              margin-right: auto;
              display: block;
              border-radius: 50%;
              margin-bottom: -180px;
             }
  
            .img-profile {
              width: 150px;
              height: 215px;
              display: block;
              margin-left: auto;
              margin-right: auto;
              margin-top: 6px;
              margin-bottom: 6px;
              z-index: -999;
              position: relative;
              border-radius: 36%;
              border: 1px solid #ffffff;
            }
        
          }
         
        }
      }
  
      .row-btn-profile {
        .nav-tabs{
          margin-top: -15px;
          text-align: center;
          margin-bottom: 10px;
          .nav-item{
            color: white;
            float: left;
            border: none;
            outline: none;
            cursor: pointer;
            padding: 5px 5px;
            font-size: 14px;
            width: 50%;
            font-family: "Nunito Sans";
              font-style: normal;
              font-weight: 700;
              .nav-link{
                width: 100%;
                background-color: #dfdfdf;
                padding: 10px 30px;
                color: #000;
              }
              .nav-link.active{
                background-color: #1572a1;
                padding: 10px 30px;
                color: #fff;
              }
          }
        }
        .col-btn {
          // border: 1px solid #000;
          // border: 10px;
          margin-bottom: 10px;
          margin-top: 10px;
          color: #000;
          .btn-wishlist {
            float: right;
            width: 100%;
            border: 1px solid #1572a1;
            background-color: #fff;
            border-radius: 10px;
            
            .text-wishlist {
              font-family: "Nunito Sans";
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 19px;
              text-align: center;
              color: #1572a1;
              margin-top: 10px;
              margin-left: 15px;
              margin-right: 15px;
              .img-wishlist {
                margin-top: -2px;
                margin-right: 5px;
              }
            }
          }
          .btn-edit {
            border: 1px solid #1572a1;
            background-color: #1572a1;
            border-radius: 10px;
            width: 100%;
            .text-edit {
              font-family: "Nunito Sans";
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 19px;
              text-align: center;
              color: #ededed;
              margin-top: 10px;
              margin-left: 15px;
              margin-right: 15px;
              .img-edit {
                margin-top: -2px;
                margin-right: 5px;
              }
            }
          }
          .btn-pwd {
            border: 1px solid #1572a1;
            background-color: #1572a1;
            border-radius: 10px;
            width: 100%;
            .text-pwd {
              font-family: "Nunito Sans";
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 19px;
              text-align: center;
              color: #ededed;
              margin-top: 10px;
              margin-left: 15px;
              margin-right: 15px;
              .img-edit {
                margin-top: -2px;
                margin-right: 5px;
              }
            }
          }
        }
        .col-profile-text {
          .profile-text {
            font-family: "Nunito Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            letter-spacing: -0.014em;
            color: #999999;
          }
        }
      }
  
      .row-data {
        margin-bottom: 20px;
        .col-data{
          .card-data{
            border: 2px solid #999999;
            padding: 20px 20px;
            border-radius: 10px;
            margin-top: 20px;
            min-height: 320px;
            .title-data {
              font-family: "Nunito Sans";
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              line-height: 25px;
              color: #1a1a1a;
            }
            .web{
              display: none;
            }
            .mob{
              display: block;
              .table-data{
                font-family: "Nunito Sans";
                font-style: normal;
                font-size: 14px;
                .thead-table{
                  .tr-table{
                    .content-type_penyakit{
                      border: 2px solid;
                    }
                  }
                }
              }
          
            }
            .container-content{
              .row-content{
                .col-label-data {
                  font-family: "Nunito Sans";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 22px;
                  letter-spacing: -0.014em;
                  color: #050505;
                  margin-bottom: 5px;
                }
                .col-content-data {
                  .text-data {
                    font-family: "Nunito Sans";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 22px;
                    letter-spacing: -0.014em;
                    color: #000000;
                  }
                }
              }
            }
          }
        }
       
        
        
      }
    }
}

.content-anggota:nth-of-type(1):before { content: "Family"; }
.content-nama:nth-of-type(2):before { content: "Name"; }
.content-jenis_kelamin:nth-of-type(3):before { content: "Gender"; }
.content-urutan_anak:nth-of-type(4):before { content: "Child"; }
.content-tempat_lahir:nth-of-type(5):before { content: "Place of Birth"; }
.content-tanggal_lahir:nth-of-type(6):before { content: "Date of Birth"; }
.content-usia:nth-of-type(7):before { content: "Age"; }
.content-pendidikan:nth-of-type(8):before { content: "Education"; }
.content-pekerjaan:nth-of-type(9):before { content: "Profession"; }
.content-keterangan:nth-of-type(10):before { content: "Description"; }


.content-pendidikan:nth-of-type(1):before { content: "Education"; }
.content-institusi:nth-of-type(2):before { content: "School name"; }
.content-jurusan:nth-of-type(3):before { content: "Majoring"; }
.content-tempat:nth-of-type(4):before { content: "Place"; }
.content-tahun_masuk:nth-of-type(5):before { content: "Year of entry"; }
.content-tahun_keluar:nth-of-type(6):before { content: "Year out"; }
.content-nilai:nth-of-type(7):before { content: "GPA"; }
.content-keterangan:nth-of-type(8):before { content: "Description"; }


.content-perusahaan:nth-of-type(1):before { content: "Company"; }
.content-posisi:nth-of-type(2):before { content: "Position"; }
.content-alamat:nth-of-type(3):before { content: "Address"; }
.content-tanggal_masuk:nth-of-type(4):before { content: "Date of entry"; }
.content-tanggal_keluar:nth-of-type(5):before { content: "Date of out"; }
.content-keterangan:nth-of-type(6):before { content: "Reason to leave"; }


.content-type_penyakit:nth-of-type(1):before { content: "Types of diseases"; }
.content-nama_penyakit:nth-of-type(2):before { content: "Disease name"; }
.content-penyembuhan:nth-of-type(3):before { content: "Treatment"; }
.content-tahun_kejadian:nth-of-type(4):before { content: "Year"; }
.content-akibat:nth-of-type(5):before { content: "Impact"; }

}

@media screen and (min-width: 575px) and (max-width: 750px) {
/*profile*/
.profile {
    margin-top: 100px;
    .container-profile {
      .row-profile {
        .name-picture {
          font-family: "Nunito Sans";
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 25px;
          text-align: center;
          color: #1a1a1a;
          margin-top: -25px;
        }
        .col-img {
         .bord{
          border: 56px solid rgb(255, 255, 255);
          height: 260px;
          width: 260px;
          border-radius: 50%;
          margin-bottom: -215px;
          z-index: -9999;
          margin-left: auto;
          margin-right: auto;
          display: block;
         } 
          .picture {
            .bord1{
              border: 5px solid #1572a1;
              height: 170px;
              width: 170px;
              margin-left: auto;
              margin-right: auto;
              display: block;
              border-radius: 50%;
              margin-bottom: -180px;
             }
  
            .img-profile {
              width: 150px;
              height: 215px;
              display: block;
              margin-left: auto;
              margin-right: auto;
              margin-top: 6px;
              margin-bottom: 6px;
              z-index: -999;
              position: relative;
              border-radius: 36%;
              border: 1px solid #ffffff;
            }
        
          }
         
        }
      }
  
      .row-btn-profile {
        .nav-tabs{
          margin-top: -10px;
          text-align: center;
          margin-bottom: 10px;
          .nav-item{
            color: white;
            float: left;
            border: none;
            outline: none;
            cursor: pointer;
            padding: 5px 5px;
            font-size: 14px;
            width: 50%;
            font-family: "Nunito Sans";
              font-style: normal;
              font-weight: 700;
              .nav-link{
                width: 100%;
                background-color: #dfdfdf;
                padding: 10px 30px;
                color: #000;
              }
              .nav-link.active{
                background-color: #1572a1;
                padding: 10px 30px;
                color: #fff;
              }
          }
        }
        .col-btn {
          // border: 1px solid #000;
          // border: 10px;
          margin-bottom: 10px;
          margin-top: 10px;
          color: #000;
          .btn-wishlist {
            float: right;
            width: 100%;
            border: 1px solid #1572a1;
            background-color: #fff;
            border-radius: 10px;
            
            .text-wishlist {
              font-family: "Nunito Sans";
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 19px;
              text-align: center;
              color: #1572a1;
              margin-top: 10px;
              margin-left: 15px;
              margin-right: 15px;
              .img-wishlist {
                margin-top: -2px;
                margin-right: 5px;
              }
            }
          }
          .btn-edit {
            border: 1px solid #1572a1;
            background-color: #1572a1;
            border-radius: 10px;
            width: 100%;
            .text-edit {
              font-family: "Nunito Sans";
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 19px;
              text-align: center;
              color: #ededed;
              margin-top: 10px;
              margin-left: 15px;
              margin-right: 15px;
              .img-edit {
                margin-top: -2px;
                margin-right: 5px;
              }
            }
          }
          .btn-pwd {
            border: 1px solid #1572a1;
            background-color: #1572a1;
            border-radius: 10px;
            width: 100%;
            .text-pwd {
              font-family: "Nunito Sans";
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 19px;
              text-align: center;
              color: #ededed;
              margin-top: 10px;
              margin-left: 15px;
              margin-right: 15px;
              .img-edit {
                margin-top: -2px;
                margin-right: 5px;
              }
            }
          }
        }
        .col-profile-text {
          .profile-text {
            font-family: "Nunito Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            letter-spacing: -0.014em;
            color: #999999;
          }
        }
      }
  
      .row-data {
        margin-top: 20px;
        margin-bottom: 20px;
        .col-data{
          .card-data{
            border: 2px solid #999999;
            padding: 20px 20px;
            border-radius: 10px;
            margin-top: 20px;
            min-height: 320px;
            .title-data {
              font-family: "Nunito Sans";
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              line-height: 25px;
              color: #1a1a1a;
            }
            .web{
              display: none;
            }
            .mob{
              display: block;
              .table-data{
                font-family: "Nunito Sans";
                font-style: normal;
                font-size: 14px;
                .thead-table{
                  .tr-table{
                    .content-type_penyakit{
                      border: 2px solid;
                    }
                  }
                }
              }
          
            }
            .container-content{
              .row-content{
                .col-label-data {
                  font-family: "Nunito Sans";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 22px;
                  letter-spacing: -0.014em;
                  color: #050505;
                  margin-bottom: 5px;
                }
                .col-content-data {
                  .text-data {
                    font-family: "Nunito Sans";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 22px;
                    letter-spacing: -0.014em;
                    color: #000000;
                  }
                }
              }
            }
          }
        }
       
        
        
      }
    }
}

.content-anggota:nth-of-type(1):before { content: "Family"; }
.content-nama:nth-of-type(2):before { content: "Name"; }
.content-jenis_kelamin:nth-of-type(3):before { content: "Gender"; }
.content-urutan_anak:nth-of-type(4):before { content: "Child"; }
.content-tempat_lahir:nth-of-type(5):before { content: "Place of Birth"; }
.content-tanggal_lahir:nth-of-type(6):before { content: "Date of Birth"; }
.content-usia:nth-of-type(7):before { content: "Age"; }
.content-pendidikan:nth-of-type(8):before { content: "Education"; }
.content-pekerjaan:nth-of-type(9):before { content: "Profession"; }
.content-keterangan:nth-of-type(10):before { content: "Description"; }


.content-pendidikan:nth-of-type(1):before { content: "Education"; }
.content-institusi:nth-of-type(2):before { content: "School name"; }
.content-jurusan:nth-of-type(3):before { content: "Majoring"; }
.content-tempat:nth-of-type(4):before { content: "Place"; }
.content-tahun_masuk:nth-of-type(5):before { content: "Year of entry"; }
.content-tahun_keluar:nth-of-type(6):before { content: "Year out"; }
.content-nilai:nth-of-type(7):before { content: "GPA"; }
.content-keterangan:nth-of-type(8):before { content: "Description"; }


.content-perusahaan:nth-of-type(1):before { content: "Company"; }
.content-posisi:nth-of-type(2):before { content: "Position"; }
.content-alamat:nth-of-type(3):before { content: "Address"; }
.content-tanggal_masuk:nth-of-type(4):before { content: "Date of entry"; }
.content-tanggal_keluar:nth-of-type(5):before { content: "Date of out"; }
.content-keterangan:nth-of-type(6):before { content: "Reason to leave"; }


.content-type_penyakit:nth-of-type(1):before { content: "Types of diseases"; }
.content-nama_penyakit:nth-of-type(2):before { content: "Disease name"; }
.content-penyembuhan:nth-of-type(3):before { content: "Treatment"; }
.content-tahun_kejadian:nth-of-type(4):before { content: "Year"; }
.content-akibat:nth-of-type(5):before { content: "Impact"; }
}

@media screen and (min-width: 750px) and (max-width: 1190px) {
  /*profile*/
  .profile {
      margin-top: 100px;
      .container-profile {
        .row-profile {
          .name-picture {
            font-family: "Nunito Sans";
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 25px;
            text-align: center;
            color: #1a1a1a;
            margin-top: -25px;
          }
          .col-img {
           .bord{
            border: 56px solid rgb(255, 255, 255);
            height: 260px;
            width: 260px;
            border-radius: 50%;
            margin-bottom: -215px;
            z-index: -9999;
            margin-left: auto;
            margin-right: auto;
            display: block;
           } 
            .picture {
              .bord1{
                border: 5px solid #1572a1;
                height: 170px;
                width: 170px;
                margin-left: auto;
                margin-right: auto;
                display: block;
                border-radius: 50%;
                margin-bottom: -180px;
               }
    
              .img-profile {
                width: 150px;
                height: 215px;
                display: block;
                margin-left: auto;
                margin-right: auto;
                margin-top: 6px;
                margin-bottom: 6px;
                z-index: -999;
                position: relative;
                border-radius: 36%;
                border: 1px solid #ffffff;
              }
          
            }
           
          }
        }
    
        .row-btn-profile {
          .nav-tabs{
          margin-top: -15px;
          text-align: center;
          margin-bottom: 10px;
            .nav-item{
              color: white;
              float: left;
              border: none;
              outline: none;
              cursor: pointer;
              padding: 5px 5px;
              font-size: 14px;
              width: 25%;
              font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 700;
                .nav-link{
                  width: 100%;
                  background-color: #dfdfdf;
                  padding: 10px 30px;
                  color: #000;
                }
                .nav-link.active{
                  background-color: #1572a1;
                  padding: 10px 30px;
                  color: #fff;
                }
            }
          }
          .col-btn {
            // border: 1px solid #000;
            // border: 10px;
            margin-bottom: 10px;
            margin-top: 10px;
            color: #000;
            .btn-wishlist {
              float: right;
              width: 100%;
              border: 1px solid #1572a1;
              background-color: #fff;
              border-radius: 10px;
              
              .text-wishlist {
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 19px;
                text-align: center;
                color: #1572a1;
                margin-top: 10px;
                margin-left: 15px;
                margin-right: 15px;
                .img-wishlist {
                  margin-top: -2px;
                  margin-right: 5px;
                }
              }
            }
            .btn-edit {
              border: 1px solid #1572a1;
              background-color: #1572a1;
              border-radius: 10px;
              width: 100%;
              .text-edit {
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 19px;
                text-align: center;
                color: #ededed;
                margin-top: 10px;
                margin-left: 15px;
                margin-right: 15px;
                .img-edit {
                  margin-top: -2px;
                  margin-right: 5px;
                }
              }
            }
            .btn-pwd {
              border: 1px solid #1572a1;
              background-color: #1572a1;
              border-radius: 10px;
              width: 100%;
              .text-pwd {
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 19px;
                text-align: center;
                color: #ededed;
                margin-top: 10px;
                margin-left: 15px;
                margin-right: 15px;
                .img-edit {
                  margin-top: -2px;
                  margin-right: 5px;
                }
              }
            }
          }
          .col-profile-text {
            .profile-text {
              font-family: "Nunito Sans";
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 22px;
              text-align: center;
              letter-spacing: -0.014em;
              color: #999999;
            }
          }
        }
    
        .row-data {
          margin-top: 20px;
          margin-bottom: 20px;
          .col-data{
            .card-data{
              border: 2px solid #999999;
              padding: 20px 20px;
              border-radius: 10px;
              margin-top: 20px;
              min-height: 320px;
              .title-data {
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 25px;
                color: #1a1a1a;
              }
              .web{
                display: none;
              }
              .mob{
                display: block;
                .table-data{
                  font-family: "Nunito Sans";
                  font-style: normal;
                  font-size: 14px;
                  .thead-table{
                    .tr-table{
                      .content-type_penyakit{
                        border: 2px solid;
                      }
                    }
                  }
                }
            
              }
              .container-content{
                .row-content{
                  .col-label-data {
                    font-family: "Nunito Sans";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 22px;
                    letter-spacing: -0.014em;
                    color: #050505;
                    margin-bottom: 5px;
                  }
                  .col-content-data {
                    .text-data {
                      font-family: "Nunito Sans";
                      font-style: normal;
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 22px;
                      letter-spacing: -0.014em;
                      color: #000000;
                    }
                  }
                }
              }
            }
          }
         
          
          
        }
      }
  }

  .content-anggota:nth-of-type(1):before { content: "Family"; }
  .content-nama:nth-of-type(2):before { content: "Name"; }
  .content-jenis_kelamin:nth-of-type(3):before { content: "Gender"; }
  .content-urutan_anak:nth-of-type(4):before { content: "Child"; }
  .content-tempat_lahir:nth-of-type(5):before { content: "Place of Birth"; }
  .content-tanggal_lahir:nth-of-type(6):before { content: "Date of Birth"; }
  .content-usia:nth-of-type(7):before { content: "Age"; }
  .content-pendidikan:nth-of-type(8):before { content: "Education"; }
  .content-pekerjaan:nth-of-type(9):before { content: "Profession"; }
  .content-keterangan:nth-of-type(10):before { content: "Description"; }

  .content-pendidikan:nth-of-type(1):before { content: "Education"; }
  .content-institusi:nth-of-type(2):before { content: "School name"; }
  .content-jurusan:nth-of-type(3):before { content: "Majoring"; }
  .content-tempat:nth-of-type(4):before { content: "Place"; }
  .content-tahun_masuk:nth-of-type(5):before { content: "Year of entry"; }
  .content-tahun_keluar:nth-of-type(6):before { content: "Year out"; }
  .content-nilai:nth-of-type(7):before { content: "GPA"; }
  .content-keterangan:nth-of-type(8):before { content: "Description"; }


  .content-perusahaan:nth-of-type(1):before { content: "Company"; }
  .content-posisi:nth-of-type(2):before { content: "Position"; }
  .content-alamat:nth-of-type(3):before { content: "Address"; }
  .content-tanggal_masuk:nth-of-type(4):before { content: "Date of entry"; }
  .content-tanggal_keluar:nth-of-type(5):before { content: "Date of out"; }
  .content-keterangan:nth-of-type(6):before { content: "Reason to leave"; }

  .content-type_penyakit:nth-of-type(1):before { content: "Types of diseases"; }
  .content-nama_penyakit:nth-of-type(2):before { content: "Disease name"; }
  .content-penyembuhan:nth-of-type(3):before { content: "Treatment"; }
  .content-tahun_kejadian:nth-of-type(4):before { content: "Year"; }
  .content-akibat:nth-of-type(5):before { content: "Impact"; }
  }
  

