/*detail vacancy*/
.detail {
    margin-top: 74px;
    .img-detail {
      width: 100%;
      height: 355px;
  
    }
    .container-detail {
      margin-top: -50px;
      .detail-content {
        .row-detail { 
          .col-detail {
            .card-detail {
              border: none;
              width: 100%;
              box-shadow: 4px 4px 4px #e9e9e9;
              .img-detail-1 {
                padding-top: 30px;
                padding-bottom: 30px;
                padding-left: 20px;
                padding-right: 20px;
                height: 120px;
                width: 100%;
              }
            }
          }
        }
  
        .row-detail-title {
          .col-detail-title-1 {
            .title-head {
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 700;
              font-size: 24px;
              margin-top: 40px;
              color: #272727;
              margin-bottom: 16px;
            }
          }
  
          .col-detail-title-2 {
            .title-text-1 {
              font-family: "Nunito Sans";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 22px;
              letter-spacing: -0.014em;
              color: #272727;
              margin-bottom: 12px;
              .img-loc {
                height: 24px;
                width: 24px;
                margin-right: 10px;
                margin-top: -5px;
              }
            }
          }
          .col-detail-title-3 {
            .row-detail-title-3 {
              .col-title-text-1 {
                .title-text-1 {
                  font-family: "Nunito Sans";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 22px;
                  letter-spacing: -0.014em;
                  color: #272727;
                  margin-bottom: 12px;
                  .img-work {
                    height: 24px;
                    width: 24px;
                    margin-right: 10px;
                    margin-top: -5px;
                  }
                }
              }
              .col-title-text-2 {
                .title-text-2 {
                  font-family: "Nunito Sans";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 22px;
                  letter-spacing: -0.014em;
                  color: #272727;
                  margin-bottom: 12px;
                  float: right;
                  padding-right: 65px;
                  .img-work {
                    height: 24px;
                    width: 24px;
                    margin-right: 10px;
                    margin-top: -5px;
                  }
                }
              }
            }
          }
        }
  
        .row-button {
          margin-top: 10px;
          margin-bottom: 25px;
          .col-button-1 {
            .btn-aplly {
              background-color: #1572a1;
              border-radius: 10px;
              border: none;
              width: 25%;
              .text-aplly {
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 19px;
                text-align: center;
                color: #ededed;
                padding-top: 6px;
                padding-right: 20px;
                padding-left: 20px;
              }
              
            }
            .btn-apllied {
              background-color: #ffffff;
              border: none;
              width: 55%;
              height: 70px;
              .text-apllied {
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 19px;
                text-align: center;
                color: #009c41;
                padding-top: 20px;
                padding-right: 20px;
                padding-left: 20px;
              }
              
            }
            .text-danger{
              font-family: "Nunito Sans";
              font-style: bold;
              margin-top: 20px;
            }
          }
          .col-button-2 {
            .det-btn {
              float: right;
              .btn-save {
                background-color: #f6f6f6;
                border: none;
                border-radius: 10px;
                width: 150px;
                margin-right: 16px;
                .text-save {
                  font-family: "Nunito Sans";
                  font-style: normal;
                  font-weight: 700;
                  font-size: 14px;
                  line-height: 19px;
                  text-align: center;
                  color: #272727;
                  padding-top: 8px;
                  .img-save {
                    margin-top: -5px;
                  }
                }
              }
  
              .btn-share {
                background-color: #f6f6f6;
                border: none;
                border-radius: 10px;
                width: 150px;
                .text-share {
                  font-family: "Nunito Sans";
                  font-style: normal;
                  font-weight: 700;
                  font-size: 14px;
                  line-height: 19px;
                  text-align: center;
                  color: #272727;
                  padding-top: 8px;
                  .img-share {
                    margin-top: -5px;
                  }
                }
              }
            }
          }
        }
  
        .row-rekrut {
          .col-deskripsi {
            margin-bottom: 20px;
            margin-top: 20px;
            .title-deskripsi {
              font-family: "Nunito Sans";
              font-style: normal;
              font-weight: 700;
              font-size: 24px;
              line-height: 33px;
              color: #161616;
              margin-bottom: 24px;
            }
            .list-deskripsi li {
              font-family: "Nunito Sans";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 22px;
              letter-spacing: -0.014em;
              color: #999999;
            }
          }
  
          .col-requirement {
            margin-bottom: 20px;
            .title-requirement {
              font-family: "Nunito Sans";
              font-style: normal;
              font-weight: 700;
              font-size: 24px;
              line-height: 33px;
              color: #161616;
              margin-bottom: 24px;
            }
            .list-requirement li {
              font-family: "Nunito Sans";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 22px;
              letter-spacing: -0.014em;
              color: #999999;
            }
          }
  
          .col-summary {
            .title-summary {
              font-family: "Nunito Sans";
              font-style: normal;
              font-weight: 700;
              font-size: 24px;
              line-height: 33px;
              color: #161616;
              margin-bottom: 24px;
            }
            .container-summary {
              .row-summary {
                .col-summary {
                  margin-bottom: 15px;
                  .title-summary {
                    font-family: "Nunito Sans";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    color: #161616;
                  }
                  .text-summary {
                    font-family: "Nunito Sans";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    letter-spacing: -0.014em;
                    color: #999999;
                    margin-top: -25px;
                  }
                }
              }
            }
          }
        }
      }
    }
}

/*reponsive*/
@media screen and (min-width: 240px) and (max-width: 580px) {
/*detail vacancy*/
.detail {
      margin-top: 74px;
      .img-detail {
        width: 100%;
        height: 140px;
      }
      .container-detail {
        margin-top: -50px;
        .detail-content {
          .row-detail {
            .col-detail {
              .card-detail {
                border: none;
                width: 50%;
                 margin-top: 5px;
                //  margin-left: auto;
                //  margin-right: auto;
                margin-bottom: -20px;
                box-shadow: 4px 4px 4px #e9e9e9;
                .img-detail {
                 height: 20px;
                }
              }
            }
          }
  
          .row-detail-title {
            .col-detail-title-1 {
              .title-head {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 700;
                font-size: 21px;
                margin-top: 40px;
                color: #272727;
                margin-bottom: 16px;
              }
            }
  
            .col-detail-title-2 {
              .title-text-1 {
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: -0.014em;
                color: #272727;
                margin-bottom: 12px;
                .img-loc {
                  height: 24px;
                  width: 24px;
                  margin-right: 10px;
                  margin-top: -5px;
                }
              }
            }
            .col-detail-title-3 {
              .row-detail-title-3 {
                .col-title-text-1 {
                  .title-text-1 {
                    font-family: "Nunito Sans";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 22px;
                    letter-spacing: -0.014em;
                    color: #272727;
                    margin-bottom: 12px;
                    .img-work {
                      height: 24px;
                      width: 24px;
                      margin-right: 10px;
                      margin-top: -5px;
                    }
                  }
                }
                .col-title-text-2 {
                  .title-text-2 {
                    font-family: "Nunito Sans";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 22px;
                    letter-spacing: -0.014em;
                    color: #272727;
                    margin-bottom: 12px;
                    float: left;
  
                    .img-work {
                      height: 24px;
                      width: 24px;
                      margin-right: 10px;
                      margin-top: -5px;
                    }
                  }
                }
              }
            }
          }
  
          .row-button {
            margin-top: 10px;
            margin-bottom: 25px;
            .col-button-1 {
              .btn-aplly {
                background-color: #1572a1;
                border-radius: 10px;
                border: none;
                width: 100%;
                margin-bottom: 10px;
                .text-aplly {
                  font-family: "Nunito Sans";
                  font-style: normal;
                  font-weight: 700;
                  font-size: 14px;
                  line-height: 19px;
                  text-align: center;
                  color: #ededed;
                  padding-top: 6px;
                  padding-right: 10px;
                  padding-left: 10px;
                }
              }
              .btn-apllied {
                margin-top: -20px;
                background-color: #ffffff;
                border: none;
                width: 100%;
                height: 80px;
                .text-apllied {
                  font-family: "Nunito Sans";
                  font-style: normal;
                  font-weight: 700;
                  font-size: 14px;
                  line-height: 19px;
                  text-align: center;
                  color: #009c41;
                  padding-top: 20px;
                  padding-right: 20px;
                  padding-left: 20px;
                }
                
              }
            }
            .col-button-2 {
              .det-btn {
                float: left;
                width: 100%;
                .btn-save {
                  background-color: #f6f6f6;
                  border: none;
                  border-radius: 10px;
                  width: 100%;
                  margin-bottom: 10px;
                  .text-save {
                    font-family: "Nunito Sans";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 19px;
                    text-align: center;
                    color: #272727;
                    padding-top: 8px;
                    .img-save {
                      margin-top: -5px;
                    }
                  }
                }
  
                .btn-share {
                  background-color: #f6f6f6;
                  border: none;
                  border-radius: 10px;
                  width: 100%;
                  .text-share {
                    font-family: "Nunito Sans";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 19px;
                    text-align: center;
                    color: #272727;
                    padding-top: 8px;
                    .img-share {
                      margin-top: -5px;
                    }
                  }
                }
              }
            }
          }
  
          .row-rekrut {
            .col-deskripsi {
              margin-bottom: 20px;
              margin-top: 20px;
              .title-deskripsi {
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 700;
                font-size: 21px;
                line-height: 33px;
                color: #161616;
                margin-bottom: 24px;
              }
              .list-deskripsi li {
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: -0.014em;
                color: #999999;
              }
            }
  
            .col-requirement {
              margin-bottom: 20px;
              .title-requirement {
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 700;
                font-size: 21px;
                line-height: 33px;
                color: #161616;
                margin-bottom: 24px;
              }
              .list-requirement li {
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: -0.014em;
                color: #999999;
              }
            }
  
            .col-summary {
              .title-summary {
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 700;
                font-size: 21px;
                line-height: 33px;
                color: #161616;
                margin-bottom: 24px;
              }
              .container-summary {
                .row-summary {
                  .col-summary {
                    margin-bottom: 15px;
                    .title-summary {
                      font-family: "Nunito Sans";
                      font-style: normal;
                      font-weight: 700;
                      font-size: 20px;
                      color: #161616;
                    }
                    .text-summary {
                      font-family: "Nunito Sans";
                      font-style: normal;
                      font-weight: 400;
                      font-size: 16px;
                      line-height: 22px;
                      letter-spacing: -0.014em;
                      color: #999999;
                      margin-top: -25px;
                    }
                  }
                }
              }
            }
          }
        }
      }
}
}
  
@media screen and (min-width: 580px) and (max-width: 750px) {
/*detail vacancy*/
.detail {
      margin-top: 74px;
      .img-detail {
        width: 100%;
        height: 140px;
      }
      .container-detail {
        margin-top: -50px;
        .detail-content {
          .row-detail {
            .col-detail {
              .card-detail {
                border: none;
                width: 40%;
                 margin-top: 5px;
                //  margin-left: auto;
                //  margin-right: auto;
                margin-bottom: -20px;
                box-shadow: 4px 4px 4px #e9e9e9;
                .img-detail {
                 height: 20px;
                }
              }
            }
          }
  
          .row-detail-title {
            .col-detail-title-1 {
              .title-head {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 700;
                font-size: 21px;
                margin-top: 40px;
                color: #272727;
                margin-bottom: 16px;
              }
            }
  
            .col-detail-title-2 {
              .title-text-1 {
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: -0.014em;
                color: #272727;
                margin-bottom: 12px;
                .img-loc {
                  height: 24px;
                  width: 24px;
                  margin-right: 10px;
                  margin-top: -5px;
                }
              }
            }
            .col-detail-title-3 {
              .row-detail-title-3 {
                .col-title-text-1 {
                  .title-text-1 {
                    font-family: "Nunito Sans";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 22px;
                    letter-spacing: -0.014em;
                    color: #272727;
                    margin-bottom: 12px;
                    .img-work {
                      height: 24px;
                      width: 24px;
                      margin-right: 10px;
                      margin-top: -5px;
                    }
                  }
                }
                .col-title-text-2 {
                  .title-text-2 {
                    font-family: "Nunito Sans";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 22px;
                    letter-spacing: -0.014em;
                    color: #272727;
                    margin-bottom: 12px;
                    float: left;
  
                    .img-work {
                      height: 24px;
                      width: 24px;
                      margin-right: 10px;
                      margin-top: -5px;
                    }
                  }
                }
              }
            }
          }
  
          .row-button {
            margin-top: 10px;
            margin-bottom: 25px;
            .col-button-1 {
              .btn-aplly {
                background-color: #1572a1;
                border-radius: 10px;
                border: none;
                width: 100%;
                margin-bottom: 10px;
                .text-aplly {
                  font-family: "Nunito Sans";
                  font-style: normal;
                  font-weight: 700;
                  font-size: 14px;
                  line-height: 19px;
                  text-align: center;
                  color: #ededed;
                  padding-top: 6px;
                  padding-right: 20px;
                  padding-left: 20px;
                }
              }
              .btn-apllied {
                margin-top: -20px;
                background-color: #ffffff;
                border: none;
                width: 100%;
                height: 80px;
                .text-apllied {
                  font-family: "Nunito Sans";
                  font-style: normal;
                  font-weight: 700;
                  font-size: 14px;
                  line-height: 19px;
                  text-align: center;
                  color: #009c41;
                  padding-top: 20px;
                  padding-right: 20px;
                  padding-left: 20px;
                }
                
              }
            }
            .col-button-2 {
              .det-btn {
                float: left;
                width: 100%;
                .btn-save {
                  background-color: #f6f6f6;
                  border: none;
                  border-radius: 10px;
                  width: 100%;
                  margin-bottom: 10px;
                  .text-save {
                    font-family: "Nunito Sans";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 19px;
                    text-align: center;
                    color: #272727;
                    padding-top: 8px;
                    .img-save {
                      margin-top: -5px;
                    }
                  }
                }
  
                .btn-share {
                  background-color: #f6f6f6;
                  border: none;
                  border-radius: 10px;
                  width: 100%;
                  .text-share {
                    font-family: "Nunito Sans";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 19px;
                    text-align: center;
                    color: #272727;
                    padding-top: 8px;
                    .img-share {
                      margin-top: -5px;
                    }
                  }
                }
              }
            }
          }
  
          .row-rekrut {
            .col-deskripsi {
              margin-bottom: 20px;
              margin-top: 20px;
              .title-deskripsi {
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 700;
                font-size: 21px;
                line-height: 33px;
                color: #161616;
                margin-bottom: 24px;
              }
              .list-deskripsi li {
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: -0.014em;
                color: #999999;
              }
            }
  
            .col-requirement {
              margin-bottom: 20px;
              .title-requirement {
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 700;
                font-size: 21px;
                line-height: 33px;
                color: #161616;
                margin-bottom: 24px;
              }
              .list-requirement li {
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: -0.014em;
                color: #999999;
              }
            }
  
            .col-summary {
              .title-summary {
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 700;
                font-size: 21px;
                line-height: 33px;
                color: #161616;
                margin-bottom: 24px;
              }
              .container-summary {
                .row-summary {
                  .col-summary {
                    margin-bottom: 15px;
                    .title-summary {
                      font-family: "Nunito Sans";
                      font-style: normal;
                      font-weight: 700;
                      font-size: 20px;
                      color: #161616;
                    }
                    .text-summary {
                      font-family: "Nunito Sans";
                      font-style: normal;
                      font-weight: 400;
                      font-size: 16px;
                      line-height: 22px;
                      letter-spacing: -0.014em;
                      color: #999999;
                      margin-top: -25px;
                    }
                  }
                }
              }
            }
          }
        }
      }
}
}
  
@media screen and (min-width: 750px) and (max-width: 1199px) {
/*detail vacancy*/
.detail {
      margin-top: 74px;
      .img-detail {
        width: 100%;
      }
      .container-detail {
        margin-top: -50px;
        .detail-content {
          .row-detail {
            .col-detail {
              .card-detail {
                border: none;
                box-shadow: 4px 4px 4px #e9e9e9;
                width: 50%;
                  margin-top: 5px;
                .img-detail {
                  padding-top: 30px;
                  padding-bottom: 30px;
                  padding-left: 40px;
                  padding-right: 40px;
                }
              }
            }
          }
  
          .row-detail-title {
            .col-detail-title-1 {
              .title-head {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                margin-top: 40px;
                color: #272727;
                margin-bottom: 16px;
              }
            }
  
            .col-detail-title-2 {
              .title-text-1 {
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: -0.014em;
                color: #272727;
                margin-bottom: 12px;
                .img-loc {
                  height: 24px;
                  width: 24px;
                  margin-right: 10px;
                  margin-top: -5px;
                }
              }
            }
            .col-detail-title-3 {
              .row-detail-title-3 {
                .col-title-text-1 {
                  .title-text-1 {
                    font-family: "Nunito Sans";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    letter-spacing: -0.014em;
                    color: #272727;
                    margin-bottom: 12px;
                    .img-work {
                      height: 24px;
                      width: 24px;
                      margin-right: 10px;
                      margin-top: -5px;
                    }
                  }
                }
                .col-title-text-2 {
                  .title-text-2 {
                    font-family: "Nunito Sans";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    letter-spacing: -0.014em;
                    color: #272727;
                    margin-bottom: 12px;
                    float: left;
                    .img-work {
                      height: 24px;
                      width: 24px;
                      margin-right: 10px;
                      margin-top: -5px;
                    }
                  }
                }
              }
            }
          }
  
          .row-button {
            margin-top: 10px;
            margin-bottom: 25px;
            .col-button-1 {
              .btn-aplly {
                background-color: #1572a1;
                border-radius: 10px;
                border: none;
                width: 50%;
                .text-aplly {
                  font-family: "Nunito Sans";
                  font-style: normal;
                  font-weight: 700;
                  font-size: 14px;
                  line-height: 19px;
                  text-align: center;
                  color: #ededed;
                  padding-top: 6px;
                  padding-right: 20px;
                  padding-left: 20px;
                }
              }
              .btn-apllied {
                background-color: #ffffff;
                border: none;
                width: 100%;
                height: 80px;
                .text-apllied {
                  font-family: "Nunito Sans";
                  font-style: normal;
                  font-weight: 700;
                  font-size: 14px;
                  line-height: 19px;
                  text-align: center;
                  color: #009c41;
                  padding-top: 20px;
                  padding-right: 20px;
                  padding-left: 20px;
                }
                
              }
            }
            .col-button-2 {
              .det-btn {
                float: right;
                .btn-save {
                  background-color: #f6f6f6;
                  border: none;
                  border-radius: 10px;
                  width: 150px;
                  margin-right: 16px;
                  .text-save {
                    font-family: "Nunito Sans";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 19px;
                    text-align: center;
                    color: #272727;
                    padding-top: 8px;
                    .img-save {
                      margin-top: -5px;
                    }
                  }
                }
  
                .btn-share {
                  background-color: #f6f6f6;
                  border: none;
                  border-radius: 10px;
                  width: 150px;
                  .text-share {
                    font-family: "Nunito Sans";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 19px;
                    text-align: center;
                    color: #272727;
                    padding-top: 8px;
                    .img-share {
                      margin-top: -5px;
                    }
                  }
                }
              }
            }
          }
  
          .row-rekrut {
            .col-deskripsi {
              margin-bottom: 20px;
              margin-top: 20px;
              .title-deskripsi {
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 33px;
                color: #161616;
                margin-bottom: 24px;
              }
              .list-deskripsi li {
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: -0.014em;
                color: #999999;
              }
            }
  
            .col-requirement {
              margin-bottom: 20px;
              .title-requirement {
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 33px;
                color: #161616;
                margin-bottom: 24px;
              }
              .list-requirement li {
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: -0.014em;
                color: #999999;
              }
            }
  
            .col-summary {
              .title-summary {
                font-family: "Nunito Sans";
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 33px;
                color: #161616;
                margin-bottom: 24px;
              }
              .container-summary {
                .row-summary {
                  .col-summary {
                    margin-bottom: 15px;
                    .title-summary {
                      font-family: "Nunito Sans";
                      font-style: normal;
                      font-weight: 700;
                      font-size: 20px;
                      color: #161616;
                    }
                    .text-summary {
                      font-family: "Nunito Sans";
                      font-style: normal;
                      font-weight: 400;
                      font-size: 16px;
                      line-height: 22px;
                      letter-spacing: -0.014em;
                      color: #999999;
                      margin-top: -25px;
                    }
                  }
                }
              }
            }
          }
        }
      }
}
}  
