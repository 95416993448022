// reset
.container-resetpass {
    margin-top: 120px;
    .row-reset {
      .col-reset {
        .title-reset {
          .title-content {
            font-family: "Montserrat", sans-serif;
            font-size: 30px;
            font-weight: bold;
            color: #272727;
            .title-content-1 {
              color: #1572a1;
              margin-bottom: 20px;
            }
          }
        }
        .title-reset2{
         margin-bottom: 40px;
         .title-content2 {
           
           margin-top: -30px;
           color: #7D7D7Da1;
           font-size: 15px;
         }
       }
        .card-reset {
          border: none;
          .card-body {
            width: 80%;
            .email {
              .form-label {
                font-family: "Nunito Sans", sans-serif;
                font-size: 16px;
                color: #0c0c0c;
                font-weight: 600;
              }
              .form-email {
                border: 1px solid #1572a1;
                border-radius: 10px;
              }
            }
            .row-btn {
              .col-btn {
               margin-top: 20px;
                .button-reset {
                  .btn-reset {
                    background-color: #1572a1;
                    width: 100%;
                    border-radius: 10px;
                    .title-reset {
                      font-family: "Nunito Sans", sans-serif;
                      color: #f9f9f9;
                      font-size: 16px;
                      font-weight: bold;
                    }
                  }
                  .btn-reset:hover {  
                    background-color: #064463;
                    width: 100%;
                    border-radius: 10px;
                    .title-reset {
                      font-family: "Nunito Sans", sans-serif;
                      color: #f9f9f9;
                      font-size: 16px;
                      font-weight: bold;
                    }
                  }
                }
              }
              .col-btn {
                .button-signup {
                  .btn-signup {
                    background-color: #f6f6f6;
                    width: 100%;
                    border-radius: 10px;
                    border: 1px solid #1572a1;
                    .title-signup {
                        text-decoration: none;
                        font-family: "Nunito Sans", sans-serif;
                        color: #1572a1;
                        font-size: 16px;
                        font-weight: bold;
                    }
                  }
                }
              }
            }
            .back-login{
                .link-back-login{
                    text-decoration: none;
                    .title-back-login{
                        font-family: 'Nunito Sans';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 22px;
                        color: #1572A1;
                        margin-top: 20px;
                    }
                }
              
            }
          }
        }
      }
      .col-img-reset {
        .img-reset {
          .img-reset-content {
            height: 340px;
            width: relative;
            margin-left: 120px;
          }
        }
      }
    }
   }
   
   /*reponsive*/
   @media screen and (min-width: 240px) and (max-width: 580px) {
   // reset
   .container-resetpass {
    margin-top: 120px;
    .row-reset {
      .col-reset {
        margin-top: 10px;
        .title-reset {
            text-align: center;
          .title-content {
            font-family: "Montserrat", sans-serif;
            font-size: 20px;
            font-weight: bold;
            color: #272727;
            .title-content-1 {
              color: #1572a1;
            }
          }
        }
        .title-reset2{
         .title-content2 {
           margin-top: 10px;
           color: #7D7D7Da1;
           font-size: 14px;
           text-align: center;
         }
       }
        .card-reset {
          .card-body {
            width: 100%;
            .email {
              .form-label {
                font-family: "Nunito Sans", sans-serif;
                font-size: 16px;
                color: #0c0c0c;
                font-weight: 600;
              }
              .form-email {
                border: 1px solid #1572a1;
                border-radius: 10px;
              }
            }
            .row-btn {
              .col-btn {
               margin-top: 20px;
                .button-reset {
                  .btn-reset {
                    background-color: #1572a1;
                    width: 100%;
                    border-radius: 10px;
                    .title-reset {
                      font-family: "Nunito Sans", sans-serif;
                      color: #f9f9f9;
                      font-size: 16px;
                      font-weight: bold;
                    }
                  }
                  .btn-reset:hover {  
                    background-color: #064463;
                    width: 100%;
                    border-radius: 10px;
                    .title-reset {
                      font-family: "Nunito Sans", sans-serif;
                      color: #f9f9f9;
                      font-size: 16px;
                      font-weight: bold;
                    }
                  }
                }
              }
              .col-btn {
                .button-signup {
                  .btn-signup {
                    background-color: #f6f6f6;
                    width: 100%;
                    border-radius: 10px;
                    border: 1px solid #1572a1;
                    .title-signup {
                        text-decoration: none;
                        font-family: "Nunito Sans", sans-serif;
                        color: #1572a1;
                        font-size: 16px;
                        font-weight: bold;
                    }
                  }
                }
              }
            }
            .back-login{
                .link-back-login{
                    text-decoration: none;
                    .title-back-login{
                        font-family: 'Nunito Sans';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 22px;
                        color: #1572A1;
                        margin-top: 10px;
                    }
                }
              
            }
          }
        }
      }
      .col-img-reset {
        .img-reset {
            margin-top: 30px;
          .img-reset-content {
            height: 80%;
            width: 80%;
            display: block;
            margin: 0 auto;
          }
        }
      }
    }
   }
   
   }
   
   @media screen and (min-width: 580px) and (max-width: 750px) {
   // login
   .container-resetpass {
    margin-top: 120px;
    .row-reset {
      .col-reset {
        margin-top: 10px;
        .title-reset {
            text-align: center;
          .title-content {
            font-family: "Montserrat", sans-serif;
            font-size: 25px;
            font-weight: bold;
            color: #272727;
            .title-content-1 {
              color: #1572a1;
            }
          }
        }
        .title-reset2{
         .title-content2 {
           color: #7D7D7Da1;
           font-size: 15px;
           text-align: center;
         }
       }
        .card-reset {
          .card-body {
            width: 100%;
            .email {
              .form-label {
                font-family: "Nunito Sans", sans-serif;
                font-size: 16px;
                color: #0c0c0c;
                font-weight: 600;
              }
              .form-email {
                border: 1px solid #1572a1;
                border-radius: 10px;
              }
            }
            .row-btn {
              .col-btn {
               margin-top: 20px;
                .button-reset {
                  .btn-reset {
                    background-color: #1572a1;
                    width: 100%;
                    border-radius: 10px;
                    .title-reset {
                      font-family: "Nunito Sans", sans-serif;
                      color: #f9f9f9;
                      font-size: 16px;
                      font-weight: bold;
                    }
                  }
                  .btn-reset:hover {  
                    background-color: #064463;
                    width: 100%;
                    border-radius: 10px;
                    .title-reset {
                      font-family: "Nunito Sans", sans-serif;
                      color: #f9f9f9;
                      font-size: 16px;
                      font-weight: bold;
                    }
                  }
                }
              }
              .col-btn {
                .button-signup {
                  .btn-signup {
                    background-color: #f6f6f6;
                    width: 100%;
                    border-radius: 10px;
                    border: 1px solid #1572a1;
                    .title-signup {
                        text-decoration: none;
                        font-family: "Nunito Sans", sans-serif;
                        color: #1572a1;
                        font-size: 16px;
                        font-weight: bold;
                    }
                  }
                }
              }
            }
            .back-login{
                .link-back-login{
                    text-decoration: none;
                    .title-back-login{
                        font-family: 'Nunito Sans';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 22px;
                        color: #1572A1;
                        margin-top: 10px;
                    }
                }
              
            }
          }
        }
      }
      .col-img-reset {
        .img-reset {
            margin-top: 40px;
          .img-reset-content {
            height: 80%;
            width: 80%;
            display: block;
            margin: 0 auto;
          }
        }
      }
    }
   }
   
   }
   
   @media screen and (min-width: 750px) and (max-width: 1199px) {
   // login
   .container-resetpass {
    margin-top: 120px;
    .row-reset {
      .col-reset {
        margin-top: 10px;
        .title-reset {
          .title-content {
            font-family: "Montserrat", sans-serif;
            font-size: 28px;
            font-weight: bold;
            color: #272727;
            .title-content-1 {
              color: #1572a1;
            }
          }
        }
        .title-reset2{
         .title-content2 {
           color: #7D7D7Da1;
           font-size: 14px;
         }
       }
        .card-reset {
          .card-body {
            width: 100%;
            .email {
              .form-label {
                font-family: "Nunito Sans", sans-serif;
                font-size: 16px;
                color: #0c0c0c;
                font-weight: 600;
              }
              .form-email {
                border: 1px solid #1572a1;
                border-radius: 10px;
              }
            }
            .row-btn {
              .col-btn {
               margin-top: 20px;
                .button-reset {
                  .btn-reset {
                    background-color: #1572a1;
                    width: 100%;
                    border-radius: 10px;
                    .title-reset {
                      font-family: "Nunito Sans", sans-serif;
                      color: #f9f9f9;
                      font-size: 16px;
                      font-weight: bold;
                    }
                  }
                  .btn-reset:hover {  
                    background-color: #064463;
                    width: 100%;
                    border-radius: 10px;
                    .title-reset {
                      font-family: "Nunito Sans", sans-serif;
                      color: #f9f9f9;
                      font-size: 16px;
                      font-weight: bold;
                    }
                  }
                }
              }
              .col-btn {
                .button-signup {
                  .btn-signup {
                    background-color: #f6f6f6;
                    width: 100%;
                    border-radius: 10px;
                    border: 1px solid #1572a1;
                    .title-signup {
                        text-decoration: none;
                        font-family: "Nunito Sans", sans-serif;
                        color: #1572a1;
                        font-size: 16px;
                        font-weight: bold;
                    }
                  }
                }
              }
            }
            .back-login{
                .link-back-login{
                    text-decoration: none;
                    .title-back-login{
                        font-family: 'Nunito Sans';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 22px;
                        color: #1572A1;
                        margin-top: 10px;
                    }
                }
              
            }
          }
        }
      }
      .col-img-reset {
        .img-reset {
            margin-top: 30px;
          .img-reset-content {
            height: 80%;
            width: 80%;
            display: block;
            margin: 0 auto;
          }
        }
      }
    }
   }
   
   } 
   
