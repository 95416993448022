//liststatus
.liststatus{
    .container-liststatus{
        .row-liststatus{
            margin-top: 120px;
            .col-img-liststatus{
                .card-img-liststatus{
                    border: none;
                    .img-liststatus{
                        height: 100%;
                        width: 55%;
                        display: block;
                        margin: 0 auto;
                    }
                }
            }
            .col-liststatus{
                .card-liststatus{
                    border-top: none;
                    border-right: none;
                    border-left: none;
                    border-bottom: 2px solid #1572a1;
                    border-radius: 0px;
                    margin-top: 25px;
                    .row-card-liststatus{
                        margin-top: 10px;
                        .col-card-liststatus{
                            font-family: "Nunito Sans";
                            font-style: normal;
                            .title-vacancy{
                                font-size: 16px;
                                font-weight: 700;
                            }
                            .title-status{
                                font-size: 14px;
                            }
                        }
                        .col-card-btn{
                            font-family: "Nunito Sans";
                            font-style: normal;
                            display: block;
                            margin:auto 0;
                            .link-btn{
                                text-decoration: none;
                                .btn-detail{
                                    background-color: #1572a1;
                                    color: #fff;
                                    font-size: 14px;
                                    border-radius: 5px;
                                    font-weight: 700;
                                    display: block;
                                    margin:auto 0;
                                }
                            }
                       
                        }
                        .col-card-btn:hover{
                            font-family: "Nunito Sans";
                            font-style: normal;
                            display: block;
                            margin:auto 0;
                            .link-btn{
                                text-decoration: none;
                                .btn-detail{
                                    background-color: #053c57;
                                    color: #fff;
                                    font-size: 14px;
                                    border-radius: 5px;
                                    font-weight: 700;
                                    display: block;
                                    margin:auto 0;
                                }
                            }
                       
                        }
                    }
                }
            }
           
        }
    }
}

@media screen and (min-width: 240px) and (max-width: 580px) {
//liststatus
.liststatus{
    .container-liststatus{
        .row-liststatus{
            margin-top: 120px;
            .col-img-liststatus{
                .card-img-liststatus{
                    border: none;
                    .img-liststatus{
                        height: 100%;
                        width: 55%;
                        display: block;
                        margin: 0 auto;
                    }
                }
            }
            .col-liststatus{
                margin-top: 30px;
                padding: 0 20px;
                .card-liststatus{
                    border-top: none;
                    border-right: none;
                    border-left: none;
                    border-bottom: 2px solid #1572a1;
                    border-radius: 0px;
                    .row-card-liststatus{
                        margin-top: 10px;
                        .col-card-liststatus{
                            font-family: "Nunito Sans";
                            font-style: normal;
                            .title-vacancy{
                                font-size: 14px;
                                font-weight: 700;
                            }
                            .title-status{
                                font-size: 12px;
                            }
                        }
                        .col-card-btn{
                            font-family: "Nunito Sans";
                            font-style: normal;
                            display: block;
                            margin:auto 0;
                            .link-btn{
                                text-decoration: none;
                                .btn-detail{
                                    background-color: #1572a1;
                                    color: #fff;
                                    font-size: 12px;
                                    border-radius: 5px;
                                    font-weight: 700;
                                    display: block;
                                    margin:auto 0;
                                }
                            }
                       
                        }
                    }
                }
            }
           
        }
    }
}
}

@media screen and (min-width: 580px) and (max-width: 768px) {
//liststatus
.liststatus{
    .container-liststatus{
        .row-liststatus{
            margin-top: 120px;
            .col-img-liststatus{
                .card-img-liststatus{
                    border: none;
                    .img-liststatus{
                        height: 100%;
                        width: 55%;
                        display: block;
                        margin: 0 auto;
                    }
                }
            }
            .col-liststatus{
                margin-top: 30px;
                padding: 0 40px;
                .card-liststatus{
                    border-top: none;
                    border-right: none;
                    border-left: none;
                    border-bottom: 2px solid #1572a1;
                    border-radius: 0px;
                    .row-card-liststatus{
                        margin-top: 10px;
                        .col-card-liststatus{
                            font-family: "Nunito Sans";
                            font-style: normal;
                            .title-vacancy{
                                font-size: 16px;
                                font-weight: 700;
                            }
                            .title-status{
                                font-size: 14px;
                            }
                        }
                        .col-card-btn{
                            font-family: "Nunito Sans";
                            font-style: normal;
                            display: block;
                            margin:auto 0;
                            .link-btn{
                                text-decoration: none;
                                .btn-detail{
                                    background-color: #1572a1;
                                    color: #fff;
                                    font-size: 14px;
                                    border-radius: 5px;
                                    font-weight: 700;
                                    display: block;
                                    margin:auto 0;
                                }
                            }
                       
                        }
                    }
                }
            }
           
        }
    }
}
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
//liststatus
.liststatus{
    .container-liststatus{
        .row-liststatus{
            margin-top: 120px;
            .col-img-liststatus{
                .card-img-liststatus{
                    border: none;
                    .img-liststatus{
                        height: 100%;
                        width: 50%;
                        display: block;
                        margin: 0 auto;
                    }
                }
            }
            .col-liststatus{
                margin-top: 30px;
                .card-liststatus{
                    border-top: none;
                    border-right: none;
                    border-left: none;
                    border-bottom: 2px solid #1572a1;
                    border-radius: 0px;
                    .row-card-liststatus{
                        margin-top: 10px;
                        .col-card-liststatus{
                            font-family: "Nunito Sans";
                            font-style: normal;
                            .title-vacancy{
                                font-size: 16px;
                                font-weight: 700;
                            }
                            .title-status{
                                font-size: 14px;
                            }
                        }
                        .col-card-btn{
                            font-family: "Nunito Sans";
                            font-style: normal;
                            display: block;
                            margin:auto 0;
                            .link-btn{
                                text-decoration: none;
                                .btn-detail{
                                    background-color: #1572a1;
                                    color: #fff;
                                    font-size: 14px;
                                    border-radius: 5px;
                                    font-weight: 700;
                                    display: block;
                                    margin:auto 0;
                                }
                            }
                       
                        }
                    }
                }
            }
           
        }
    }
}
}