/*status*/
.status {
    .container-status {
      .row-status {
        .col-status {
          margin-top: 100px;
          .status-image {
            width: 100%;
            height: 200px;
          }
          .status-title {
            font-family: "Nunito Sans";
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            text-align: center;
            margin-top: 28px;
            color: #272727;
          }
          
        } 
        .row-text{
          .col-text{
            text-align: center;
            .text-muted{
             font-size: 18px;
             margin-bottom: 20px;
             .user{
              font-weight: 700;
             }
            }
            .click{
              text-decoration: none;
              color: #000;
              font-weight: 700;
            }
            .click:hover{
              text-decoration: none;
              color: rgb(38, 99, 168);
              font-weight: 700;
            }
          }
        }
      }
  
      .row-step-bulat{
        .col-bulat{
          .card-step{
            height: 60px;
            width: 60px;
            border-radius: 50%;
            background-color: rgb(241, 241, 241);
            margin: 0 auto;
  
            .admin{
              z-index: 999;
              height: 30px;
              width: 20px;
              margin-right: auto;
              margin-left:auto;
              margin-top: -18px;
              margin-bottom: 10px;
              color: #fff;
            }
            .psikotes{
              z-index: 999;
              height: 30px;
              width: 30px;
              margin-right: auto;
              margin-left:auto;
              margin-top: -18px;
              margin-bottom: 10px;
              color: #fff;
            }
            .interview{
              z-index: 999;
              height: 30px;
              width: 30px;
              margin-right: auto;
              margin-left:auto;
              margin-top: -18px;
              margin-bottom: 10px;
            }
            .interview1{
              z-index: 999;
              height: 30px;
              width: 30px;
              margin-right: auto;
              margin-left:auto;
              margin-top: -18px;
              margin-bottom: 10px;
            }
            .offering{
              z-index: 999;
              height: 30px;
              width: 30px;
              margin-right: auto;
              margin-left:auto;
              margin-top: -18px;
              margin-bottom: 10px;
            }
            .contract{
              z-index: 999;
              height: 30px;
              width: 30px;
              margin-right: auto;
              margin-left:auto;
              margin-top: 15px;
              margin-bottom: 10px;
            }
          }
          .indicator-line{
            border-bottom: 4px solid #CADAFC;
            width: 220px;
            z-index: -999;
            margin-top: 28px;
          }
        }
      }
  
      .row-step-content{
        margin-top: -60px;
        .col-step-content{
          text-align: center;
          .card-step-content-acc{
            height: 60px;
            width: 60px;
            border-radius: 50%;
            background-color: #29a500;
            margin: 0 auto;
          }
          .card-step-content-reject{
            height: 60px;
            width: 60px;
            border-radius: 50%;
            background-color: #b91515;
            // background-color: #fa4040;
            margin: 0 auto;
          }
          .card-step-content-proses{
            height: 60px;
            width: 60px;
            border-radius: 50%;
            background-color: #1572A1;
            margin: 0 auto;
          }
          .title1-acc{
            margin-top: 10px;
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            color: #000000;
          }
          .title2-acc{
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            color: #238d00;
          }
          .title3-acc{
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            color: #000000;
          }
          .title1-reject{
            margin-top: 10px;
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            color: #000000;
          }
          .title2-reject{
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            color: #a11515;
          }
          .title3-reject{
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            color: #000000;
          }
          .title1-proses{
            margin-top: 10px;
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            color: #000000;
          }
          .title2-proses{
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            color: #1572A1;
          }
          .title3-proses{
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            color: #000000;
          }
        }
      }
  
      .row-step-bulat-mob{
        display: none;
      }
      .row-step-content-mob{
        display: none;
      }
    }
}

/*reponsive*/
@media screen and (min-width: 240px) and (max-width: 580px) {
/*status*/
.status {
    .container-status {
      .row-status {
        .col-status {
          margin-top: 100px;
          .status-image {
            width: 70%;
            height: 80%;
            display: block;
            margin: 0 auto;
            height: relative;
          }
          .status-title {
            font-family: "Nunito Sans";
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            text-align: center;
            margin-top: 28px;
            padding-bottom: 30px;
            color: #272727;
          }
          
        } 
        .row-text{
          .col-text{
            text-align: center;
            .text-muted{
             font-size: 14px;
             margin-bottom: 20px;
             .user{
              font-weight: 700;
             }
            }
            .click{
              text-decoration: none;
              color: #000;
              font-weight: 700;
            }
            .click:hover{
              text-decoration: none;
              color: rgb(38, 99, 168);
              font-weight: 700;
            }
          }
        }
      }
  
      .row-step-bulat{
       display: none;
      }
  
      .row-step-content{
       display: none;
      }
  
      .row-step-content-mob{
        display: block;
        .container-content{
          .table-content{
            margin-top: 80px;
          .thead-content{    
            .tr-content{
              margin-top: -50px;
              
              .approve{
                .th-content-ikon{
                  width: 10px;
                  .card-content-approved{
                    border: none;
                    height: 15px;
                    width: 15px;
                    margin-top: -25px;
                    margin-right: -0px;
                    border-radius: 50%;
                    background-color: #29a500;
                    box-shadow: 0px 0px 20px #29a500
                  }
  
               
  
                }
    
                .th-content-desk-approved{
                  padding-left: 30px;
                  padding-right: 10px;
                  width: 100%;
                  bottom: 0 ;
                  margin-left: 6px;
                  margin-top: 4px;
                  padding-bottom: 15px;
                  border-left: 2px solid #CADAFC;
                  height: relative;
                 
                .title0-approved{
                  font-size: 10px;
                  color: #fff;
                  
                }
                .title1-approved{
                  font-family: 'Nunito Sans';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 13px;
                  color: #000000;
                  margin-top: -35px;
    
                }
                .title2-approved{
                  font-family: 'Nunito Sans';    
                  font-size: 13px;
                  color: #29a500;
    
                }
                .title3-approved{
                  font-family: 'Nunito Sans';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 13px;
                  color: #000000;
                }
    
                }
  
                .icon{
                  margin-bottom: -20px;
                  margin-left: -39px;
                  border: none;
                  height: 15px;
                  width: 15px;
                  border-radius: 50%;
                  background-color: #29a500;
              
                }
              }
  
              .reject{
                .th-content-ikon{
                  width: 10px;
                  // border: 1px solid #000;
                  .card-content-rejected{
                    border: none;
                    height: 15px;
                    width: 15px;
                    margin-top: -25px;
                    margin-right: -9px;
                    border-radius: 50%;
                    background-color: #b91515;
                    box-shadow: 0px 0px 20px #b91515;
                  }
                }
    
                .th-content-desk-rejected{
                  padding-left: 40px;
                  padding-right: 10px;
                  width: 100%;
                  margin-top: 2px;
                .title0-rejected{
                  font-size: 10px;
                  color: #fff;
                  
                }
                .title1-rejected{
                  font-family: 'Nunito Sans';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 13px;
                  color: #000000;
                  margin-top: -35px;
    
                }
                .title2-rejected{
                  font-family: 'Nunito Sans';    
                  font-size: 13px;
                  color: #b91515;
    
                }
                .title3-rejected{
                  font-family: 'Nunito Sans';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 13px;
                  color: #000000;
                }
    
                }
              }
  
              .process{
                .th-content-ikon{
                  width: 10px;
                  // border: 1px solid #000;
                  .card-content-process{
                    border: none;
                    height: 15px;
                    width: 15px;
                    margin-top: -25px;
                    margin-right: -9px;
                    border-radius: 50%;
                    background-color: #1572A1;
                    box-shadow: 0px 0px 20px #1572A1;
                  }
                }
    
                .th-content-desk-process{
                  padding-left: 40px;
                  padding-right: 10px;
                  margin-top: 1px;
                  width: 100%;
                .title0-process{
                  font-size: 10px;
                  color: #fff;
                  
                }
                .title1-process{
                  font-family: 'Nunito Sans';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 12.5px;
                  color: #000000;
                  margin-top: -35px;
    
                }
                .title2-process{
                  font-family: 'Nunito Sans';    
                  font-size: 12.5px;
                  color: #1572A1;
    
                }
                .title3-process{
                  font-family: 'Nunito Sans';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 12.5px;
                  color: #000000;
                }
    
                }
              }
  
              .finish{
                .th-content-ikon{
                  width: 10px;
                  .card-content-finish{
                    border: none;
                    height: 15px;
                    width: 15px;
                    margin-top: -25px;
                    margin-right: -9px;
                    border-radius: 50%;
                    background-color: #29a500;
                    box-shadow: 0px 0px 20px #29a500
                  }
  
               
  
                }
    
                .th-content-desk-finish{
                  padding-left: 30px;
                  padding-right: 10px;
                  width: 100%;
                  margin-top: 1px;
                  bottom: 0 ;
                 
                .title0-finish{
                  font-size: 10px;
                  color: #fff;
                  
                }
                .title1-finish{
                  font-family: 'Nunito Sans';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 13px;
                  color: #000000;
                  margin-top: -35px;
    
                }
                .title2-finish{
                  font-family: 'Nunito Sans';    
                  font-size: 13px;
                  color: #29a500;
    
                }
                .title3-finish{
                  font-family: 'Nunito Sans';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 13px;
                  color: #000000;
                }
    
                }
  
              }
            }
          }
         
            
          }
        }
      }
  
    
    }
}
}
  
@media screen and (min-width: 580px) and (max-width: 750px) {
/*status*/
.status {
    .container-status {
      .row-status {
        .col-status {
          margin-top: 100px;
          .status-image {
            width: 50%;
            height: 80%;
            display: block;
            margin: 0 auto;
            height: relative;
          }
          .status-title {
            font-family: "Nunito Sans";
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            text-align: center;
            margin-top: 28px;
            margin-bottom: 30px;
            color: #272727;
          }
          
        } 
        .row-text{
          .col-text{
            text-align: center;
            .text-muted{
             font-size: 16px;
             margin-bottom: 20px;
             .user{
              font-weight: 700;
             }
            }
            .click{
              text-decoration: none;
              color: #000;
              font-weight: 700;
            }
            .click:hover{
              text-decoration: none;
              color: rgb(38, 99, 168);
              font-weight: 700;
            }
          }
        }
      }
  
      .row-step-bulat{
       display: none;
      }
  
      .row-step-content{
       display: none;
      }
  
  
      .row-step-content-mob{
        display: block;
        padding-left: 16px;
        .container-content{
          .table-content{
            margin-top: 80px;
          .thead-content{    
            .tr-content{

              .approve{
                .th-content-ikon{
                  width: 10px;
                  .card-content-approved{
                    border: none;
                    height: 18px;
                    width: 18px;
                    margin-top: -75px;
                    margin-right: -10px;
                    border-radius: 50%;
                    background-color: #29a500;
                    box-shadow: 0px 0px 20px #29a500
                  }
  
                }
    
                .th-content-desk-approved{
                  padding-left: 30px;
                  padding-right: 10px;
                  padding-bottom: 15px;
                  width: 100%;
                  margin-left: 8px;
                  margin-top: -2px;
                  border-left: 2px solid #CADAFC;
                  height: relative;
                  .icon{
                    margin-bottom: -35px;
                    margin-left: -41px;
                    border: none;
                    height: 18px;
                    width: 18px;
                    border-radius: 50%;
                    background-color: #29a500;
              
                  }
                .title0-approved{
                  font-size: 10px;
                  color: #fff;
                  
                }
                .title1-approved{
                  font-family: 'Nunito Sans';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 15px;
                  color: #000000;
                  margin-top: -35px;
    
                }
                .title2-approved{
                  font-family: 'Nunito Sans';    
                  font-size: 15px;
                  color: #29a500;
                  font-weight: 700;
    
                }
                .title3-approved{
                  font-family: 'Nunito Sans';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 15px;
                  color: #000000;
                }
    
                }
              }
  
              .reject{
                .th-content-ikon{
                  width: 10px;
                  // border: 1px solid #000;
                  .card-content-rejected{
                    border: none;
                    height: 20px;
                    width: 20px;
                    margin-top: -75px;
                    margin-right: -9px;
                    border-radius: 50%;
                    background-color: #b91515;
                    box-shadow: 0px 0px 20px #b91515;
                  }
                }
    
                .th-content-desk-rejected{
                  padding-left: 40px;
                  padding-right: 10px;
                  margin-top: -2px;
                  width: 100%;
                .title0-rejected{
                  font-size: 10px;
                  color: #fff;
                  
                }
                .title1-rejected{
                  font-family: 'Nunito Sans';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 15px;
                  color: #000000;
                  margin-top: -35px;
    
                }
                .title2-rejected{
                  font-family: 'Nunito Sans';    
                  font-size: 15px;
                  font-weight: 700;
                  color: #b91515;
    
                }
                .title3-rejected{
                  font-family: 'Nunito Sans';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  color: #000000;
                }
    
                }
              }
  
              .process{
                .th-content-ikon{
                  width: 10px;
                  // border: 1px solid #000;
                  .card-content-process{
                    border: none;
                    height: 20px;
                    width: 20px;
                    margin-top: -75px;
                    margin-right: -9px;
                    border-radius: 50%;
                    background-color: #1572A1;
                    box-shadow: 0px 0px 20px #1572A1;
                  }
                }
    
                .th-content-desk-process{
                  padding-left: 40px;
                  padding-right: 10px;
                  margin-top: -1px;
                  width: 100%;
                .title0-process{
                  font-size: 10px;
                  color: #fff;
                  
                }
                .title1-process{
                  font-family: 'Nunito Sans';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 15px;
                  color: #000000;
                  margin-top: -35px;
    
                }
                .title2-process{
                  font-family: 'Nunito Sans';    
                  font-size: 15px;
                  color: #1572A1;
                  font-weight: 700;
    
                }
                .title3-process{
                  font-family: 'Nunito Sans';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 12.5px;
                  color: #000000;
                }
    
                }
              }

              .finish{
                .th-content-ikon{
                  width: 10px;
                  .card-content-finish{
                    border: none;
                    height: 18px;
                    width: 18px;
                    margin-top: -75px;
                    margin-right: -10px;
                    border-radius: 50%;
                    background-color: #29a500;
                    box-shadow: 0px 0px 20px #29a500
                  }
  
               
  
                }
    
                .th-content-desk-finish{
                  padding-left: 40px;
                  padding-right: 10px;
                  margin-top: 2px;
                  width: 100%;
                  bottom: 0 ;
                 
                .title0-finish{
                  font-size: 10px;
                  color: #fff;
                  
                }
                .title1-finish{
                  font-family: 'Nunito Sans';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  color: #000000;
                  margin-top: -35px;
    
                }
                .title2-finish{
                  font-family: 'Nunito Sans';    
                  font-size: 13px;
                  color: #29a500;
    
                }
                .title3-finish{
                  font-family: 'Nunito Sans';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 13px;
                  color: #000000;
                }
    
                }
  
                .icon{
                  margin-bottom: -20px;
                  margin-left: -39px;
                  border: none;
                  height: 20px;
                  width: 20px;
                  border-radius: 50%;
                  background-color: #29a500;
              
                }
              }
            }
          }
         
            
          }
        }
      }
  
    
    }
}
}
  
@media screen and (min-width: 750px) and (max-width: 991px){
/*status*/
.status {
    .container-status {
  
      .row-status {
        .col-status {
          margin-top: 100px;
          .status-image {
            width: 50%;
            height: 80%;
            display: block;
            margin: 0 auto;
            height: relative;
          }
          .status-title {
            font-family: "Nunito Sans";
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            text-align: center;
            margin-top: 28px;
            margin-bottom: 30px;
            color: #272727;
          }
          
        } 
        .row-text{
          .col-text{
            text-align: center;
            .text-muted{
             font-size: 16px;
             margin-bottom: 20px;
             .user{
              font-weight: 700;
             }
            }
            .click{
              text-decoration: none;
              color: #000;
              font-weight: 700;
            }
            .click:hover{
              text-decoration: none;
              color: rgb(38, 99, 168);
              font-weight: 700;
            }
          }
        }
      }
  
      .row-step-bulat{
       display: none;
      }
  
      .row-step-content{
       display: none;
      }
  
      .row-step-content-mob{
        display: block;
        padding-left: 16px;
        .container-content{
          .table-content{
            margin-top: 80px;
          .thead-content{    
            .tr-content{
  
              .approve{
                .th-content-ikon{
                  width: 10px;
                  .card-content-approved{
                    border: none;
                    height: 20px;
                    width: 20px;
                    margin-top: -75px;
                    margin-right: -11px;
                    border-radius: 50%;
                    background-color: #29a500;
                    box-shadow: 0px 0px 20px #29a500
                  }
  
                }
    
                .th-content-desk-approved{
                  padding-left: 30px;
                  padding-right: 10px;
                  width: 100%;
                  margin-left: 8px;
                  margin-top: 2px;
                  padding-bottom: 15px;
                  border-left: 2px solid #CADAFC;
                  height: relative;
                  .icon{
                    margin-bottom: -36px;
                    margin-left: -42px;
                    border: none;
                    height: 20px;
                    width: 20px;
                    border-radius: 50%;
                    background-color: #29a500;
                 
                  }
                .title0-approved{
                  font-size: 10px;
                  color: #fff;
                  
                }
                .title1-approved{
                  font-family: 'Nunito Sans';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  color: #000000;
                  margin-top: -35px;
    
                }
                .title2-approved{
                  font-family: 'Nunito Sans';    
                  font-size: 16px;
                  font-weight: 700;
                  color: #29a500;
    
                }
                .title3-approved{
                  font-family: 'Nunito Sans';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  color: #000000;
                }
    
                }
              }
  
              .reject{
                .th-content-ikon{
                  width: 10px;
                  // border: 1px solid #000;
                  .card-content-rejected{
                    border: none;
                    height: 20px;
                    width: 20px;
                    margin-top: -75px;
                    margin-right: -9px;
                    border-radius: 50%;
                    background-color: #b91515;
                    box-shadow: 0px 0px 20px #b91515;
                  }
                }
    
                .th-content-desk-rejected{
                  padding-left: 40px;
                  padding-right: 10px;
                  margin-top: 2px;
                  width: 100%;
                .title0-rejected{
                  font-size: 10px;
                  color: #fff;
                  
                }
                .title1-rejected{
                  font-family: 'Nunito Sans';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 15px;
                  color: #000000;
                  margin-top: -35px;
    
                }
                .title2-rejected{
                  font-family: 'Nunito Sans';    
                  font-weight: 700;
                  font-size: 15px;
                  color: #b91515;
    
                }
                .title3-rejected{
                  font-family: 'Nunito Sans';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  color: #000000;
                }
    
                }
              }
  
              .process{
                .th-content-ikon{
                  width: 10px;
                  // border: 1px solid #000;
                  .card-content-process{
                    border: none;
                    height: 20px;
                    width: 20px;
                    margin-top: -75px;
                    margin-right: -9px;
                    border-radius: 50%;
                    background-color: #1572A1;
                    box-shadow: 0px 0px 20px #1572A1;
                  }
                }
    
                .th-content-desk-process{
                  padding-left: 40px;
                  padding-right: 10px;
                  margin-top: 2px;
                  width: 100%;
                .title0-process{
                  font-size: 10px;
                  color: #fff;
                  
                }
                .title1-process{
                  font-family: 'Nunito Sans';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 15px;
                  color: #000000;
                  margin-top: -35px;
    
                }
                .title2-process{
                  font-family: 'Nunito Sans';    
                  font-weight: 700;
                  font-size: 15px;
                  color: #1572A1;
    
                }
                .title3-process{
                  font-family: 'Nunito Sans';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  color: #000000;
                }
    
                }
              }

              .finish{
                .th-content-ikon{
                  width: 10px;
                  .card-content-finish{
                    border: none;
                    height: 20px;
                    width: 20px;
                    margin-top: -75px;
                    margin-right: -9px;
                    border-radius: 50%;
                    background-color: #29a500;
                    box-shadow: 0px 0px 20px #29a500
                  }
  
               
  
                }
    
                .th-content-desk-finish{
                  padding-left: 40px;
                  padding-right: 10px;
                  margin-top: 2px;
                  width: 100%;
                  bottom: 0 ;
                 
                .title0-finish{
                  font-size: 10px;
                  color: #fff;
                  
                }
                .title1-finish{
                  font-family: 'Nunito Sans';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  color: #000000;
                  margin-top: -35px;
    
                }
                .title2-finish{
                  font-family: 'Nunito Sans';    
                  font-size: 13px;
                  color: #29a500;
    
                }
                .title3-finish{
                  font-family: 'Nunito Sans';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 13px;
                  color: #000000;
                }
    
                }
  
              }
            }
          }
         
            
          }
        }
      }
  
    
    }
}
}
