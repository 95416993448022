
/*content*/
.content {
    margin-top: 110px;
  .container-content {
    .announ {
      margin-bottom: 40px;
      .announ-alert{
        .row-alert{
          .col-alert{
          width: 80%;
          }
          .col-close{
            width: 20%;
            .btn-close{
              float: right;
              border-radius: 50%;
            }
          }
        }
      }
    }
    .row-content{
  
    .content-title {
      padding-top: 50px;
  
      .title-1 {
        font-family: "Montserrat", sans-serif;
        font-size: 35px;
        font-weight: bold;
        color: #272727;
        .title-2 {
          font-family: "Montserrat", sans-serif;
          font-size: 35px;
          font-weight: bold;
          color: #1572a1;
        }
      }
  
      .title-3 {
        font-family: "Nunito Sans", sans-serif;
        font-size: 16px;
        font-weight: 400;
        color: #999999;
        letter-spacing: -0.019em;
        line-height: 28px;
      }
  
      .button-join {
        background-color: #1572a1;
        border: none;
        border-radius: 10px;
        height: 50px;
        width: 150px;
        margin-top: 15px;
        margin-bottom: 16px;
        .join {
          color: #ffffff;
          font-family: "Nunito Sans", sans-serif;
          font-weight: bold;
          text-decoration: none;
          font-size: 14px;
        }
      }
  
      .button-join:hover {
        background-color: #014161;
        border: none;
        border-radius: 10px;
        height: 50px;
        width: 150px;
      }
      .drop-resume {
        .title-1 {
          font-family: "Nunito Sans", sans-serif;
          font-weight: 400;
          font-size: 14px;
          color: #999999;
          .title-2 {
            text-decoration: none;
            font-family: "Nunito Sans", sans-serif;
            font-weight: 400;
            font-size: 14px;
          }
        }
      }
    }
  
    .content-image {
      .img-dash{
        position: relative;
        animation: animation1 1s ease-out;
        width: 90%;
        padding-left: 40px;
      }
    }
    @keyframes animation1 {
      0% {
        opacity: 0;
        right: -700px;
      }

      100% {
        opacity: 1;
        right: 0;
      }
    }
  }
    .ava-vacancy {
  
      .ava-vacancy-title {
        .ava-vacancy-title-1 {
          color: #272727;
          font-family: "Montserrat", sans-serif;
          font-size: 30px;
          font-weight: bold;
          text-align: center;
          margin-top: 40px;
        }
      }
      .row-vacancy {
        margin-bottom: 80px;
        .col-vacancy {
          .card {
            transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
            height: 250px;
            width: 100%;
            background-color: #e3f6ff;
            border-radius: 10px;
            border: none;
            margin-top: 32px;
            .vacancy-img {
              height: 50px;
              width: 150px;
              position: relative;
              margin-top: auto;
              margin-bottom: auto;
              margin-right: auto;
              margin-left: auto;
            }
          }
          .card:hover {
            box-shadow: inset 350px 0 0 0 #54b3d6;;
            height: 250px;
            width: 100%;
            border-radius: 10px;
            border: none;
            margin-top: 32px;
            .vacancy-img {
              height: 50px;
              width: 150px;
              position: relative;
              margin-top: auto;
              margin-bottom: auto;
              margin-right: auto;
              margin-left: auto;
            }
          }
        }
      }
    }
  
    .get {
      margin-bottom: 90px;
      .get-title {
        .get-title-1 {
          color: #272727;
          font-family: "Montserrat", sans-serif;
          font-size: 30px;
          font-weight: bold;
          margin-top: 25px;
          text-align: center;
        }
      }
      .row-get {
        .col-get {
          .get-content {
            color: #999999;
            font-family: "Nunito Sans", sans-serif;
            font-size: 16px;
            font-weight: 500;
            margin-top: 20px;
            letter-spacing: -0.019em;
            line-height: 24px;
          }
          .get-img {
            .get-img-content {
              height: 356px;
              width: 556px;
            }
          }
        }
      }
    }
    .skil {
      .row-skil {
        margin-top: -30px;
        .col-skil {
          .card-skil {
            position: relative;
            animation: animation 2s ease-out;
            height: 480px;
            width: 100%;
            background-color: #e3f6ff;
            border: none;
            border-radius: 10px;
            margin-bottom: 30px;
            .image-skil {
              height: 160px;
              width: 200px;
              position: relative;
              margin-top: 35px;
              margin-left: auto;
              margin-right: auto;
            }
            .title-skil {
              font-size: 20px;
              font-weight: 600;
              color: #272727;
              font-family: "Nunito Sans", sans-serif;
              text-align: center;
              margin-bottom: 32px;
              margin-top: 40px;
            }
            .content-skil {
              font-size: 16px;
              font-weight: 400;
              color: #999999;
              font-family: "Nunito Sans", sans-serif;
              line-height: 22px;
              text-align: center;
              letter-spacing: -0.014em;
            }
          }
          @keyframes animation {
            0% {
              opacity: 0;
              left: -700px;
            }
      
            100% {
              opacity: 1;
              left: 0;
            }
          }
        }
      }
    }
  
    .office {
      .office-title {
        .head {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 700;
          font-size: 30px;
          text-align: center;
          letter-spacing: -0.022em;
          color: #272727;
          margin-top: 30px;
        }
        .content {
          font-family: "Nunito Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          text-align: center;
          letter-spacing: -0.019em;
          color: #999999;
          margin-top: 5px;
        }
      }

      .images{
        margin-top: 30px;
        text-align: center;
        .images-result{
          width: 70%;
          height: 600px;
        }
      }
      .flex-row{
        justify-content: center;
        display: flex;
        padding: 20px;
        overflow-x: scroll;
        overflow-y: hidden;
        .thumb{
          margin:0 7px;
          border: none;
          .thumb-result{
            width: 170px;
            height: 100px;
          }
        }
      
      }
      ::-webkit-scrollbar {
        height: 1px;
      }
      ::-webkit-scrollbar-track {
        background: #ffffff; 
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb {
        background: #888; 
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: #555; 
      }

      // .row-office {
      //   margin-top: 30px;
      //   .col-office-1 {
      //     .office1 {
      //       margin-bottom: 14px;
      //     }
      //     .office2 {
      //       margin-bottom: 14px;
      //     }
      //   }
  
      //   .col-office-2 {
      //     .office3 {
      //       width: 580px;
      //       height: 400px;
      //       margin-bottom: 14px;
      //       padding-left: 30px;
      //       padding-right: 30px;
      //     }
      //   }
  
      //   .col-office-3 {
      //     .office4 {
      //       margin-bottom: 14px;
      //     }
      //     .office5 {
      //       margin-bottom: 14px;
      //     }
      //   }
      // }
    }
  }
}

/*reponsive*/
@media screen and (min-width: 240px) and (max-width: 580px) {
/*content*/
.content {
    .container-content {
      .announ {
        .announ-alert{
          .row-alert{
            .col-close{
              width: 20%;
              .btn-close{
                float: right;
                border-radius: 50%;
              }
            }
          }
        }
      }
      .row-content{
  
      .content-title {
        margin-top: -70px;
        text-align: center;
        .title-1 {
          font-family: "Montserrat", sans-serif;
          font-size: 25px;
          font-weight: bold;
          color: #272727;
       
          .title-2 {
            font-family: "Montserrat", sans-serif;
            font-size: 25px;
            font-weight: bold;
            color: #1572a1;
          }
        }
    
        .title-3 {
          font-family: "Nunito Sans", sans-serif;
          font-size: 15px;
          font-weight: 400;
          color: #999999;
        }
    
        .button-join {
          background-color: #1572a1;
          border: none;
          border-radius: 10px;
          height: 40px;
          width: 140px;
          margin-top: 2px;
          margin-bottom: 16px;
          .join {
            color: #ffffff;
            font-family: "Nunito Sans", sans-serif;
            font-weight: bold;
            text-decoration: none;
            font-size: 12px;
          }
        }
    
        .button-join:hover {
          background-color: #014161;
          border: none;
          border-radius: 10px;
          height: 40px;
          width: 140px;
        }
        .drop-resume {
          .title-1 {
            font-family: "Nunito Sans", sans-serif;
            font-weight: 400;
            font-size: 12px;
            color: #999999;
            .title-2 {
              text-decoration: none;
              font-family: "Nunito Sans", sans-serif;
              font-weight: 400;
              font-size: 12px;
            }
          }
        }
      }
    
      .content-image {
        .img-dash{
          padding-top: 20px;
          padding-left: 30px;
          padding-right: 30px;
          display: block;
          margin: 0 auto;
        }
       
      }
    }
      .ava-vacancy {
        .ava-vacancy-title {
          .ava-vacancy-title-1 {
            color: #272727;
            font-family: "Montserrat", sans-serif;
            font-size: 25px;
            font-weight: bold;
            text-align: center;
            margin-top: 40px;
          }
        }
        .row-vacancy {
          margin-bottom: 120px;
          .col-vacancy {
            padding-left: 30px;
            padding-right: 30px;
            .card {
              transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
              height: 250px;
              width: 100%;
              background-color: #e3f6ff;
              border-radius: 10px;
              border: none;
              margin-top: 32px;
             
              .vacancy-img {
                height: 50px;
                width: 150px;
                position: relative;
                margin-top: auto;
                margin-bottom: auto;
                margin-right: auto;
                margin-left: auto;
              }
            }
            .card:hover {
              box-shadow: inset 450px 0 0 0 #54b3d6;;
              height: 250px;
              width: 100%;
              border-radius: 10px;
              border: none;
              margin-top: 32px;
              .vacancy-img {
                height: 50px;
                width: 150px;
                position: relative;
                margin-top: auto;
                margin-bottom: auto;
                margin-right: auto;
                margin-left: auto;
              }
            }
          }
        }
      }

      .get {
        margin-bottom: 50px;
        text-align: center;
        .get-title {
          .get-title-1 {
            color: #272727;
            font-family: "Montserrat", sans-serif;
            font-size: 25px;
            font-weight: bold;
          }
        }
        .row-get {
          .col-get {
            padding-left: 30px;
            padding-right: 30px;
            .get-content {
              color: #999999;
              font-family: "Nunito Sans", sans-serif;
              font-size: 15px;
              font-weight: 500;
              margin-top: 20px;
              letter-spacing: -0.019em;
              line-height: 24px;
              text-align: justify;
            }
            .get-img {
              .get-img-content {
                height: 300px;
                width: 100%;
              }
            }
          }
        }
      }

      .skil {
        .row-skil {
          .col-skil {
            padding-left: 30px;
            padding-right: 30px;
            .card-skil {
              height: relative;
              width: 100%;
              background-color: #e3f6ff;
              border: none;
      
              border-radius: 10px;
    
              .image-skil {
                height: 130px;
                width: 140px;
               
              }
              .title-skil {
                font-size: 18px;
                font-weight: 600;
                color: #272727;
                font-family: "Nunito Sans", sans-serif;
                text-align: center;
               
              }
              .content-skil {
                font-size: 14px;
                font-weight: 400;
                color: #999999;
                font-family: "Nunito Sans", sans-serif;
                text-align: center;
              }
            }
          }
        }
      }
    
      .office {
        .office-title {
          padding-left: 30px;
          padding-right: 30px;
          .head {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-size: 25px;
            text-align: center;
            color: #272727;
          }
          .content {
            font-family: "Nunito Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            text-align: center;
            color: #999999;
          }
        }
        .images{
          margin-top: 30px;
          text-align: center;
          .images-result{
            width: 100%;
            height: 350px;
          }
        }
        .flex-row{
          justify-content: center;
          display: flex;
          padding: 20px;
          overflow-x: scroll;
          overflow-y: hidden;
          .thumb{
            margin-left: 10px;
            border: none;
            .thumb-result{
              width: 105px;
            }
          }
        
        }
        ::-webkit-scrollbar {
          height: 0px;
        }
        ::-webkit-scrollbar-track {
          background: #ffffff; 
          border-radius: 10px;
        }
        ::-webkit-scrollbar-thumb {
          background: #888; 
          border-radius: 10px;
        }
        ::-webkit-scrollbar-thumb:hover {
          background: #555; 
        }
        // .row-office {
        //   margin-top: 30px;
        //   text-align: center;
        //   .col-office-1 {
            
        //     .office1 {
        //       margin-bottom: 14px;
        //       width: 83%;
        //       height: 250px;
        //     }
        //     .office2 {
        //       margin-bottom: 14px;
        //       width: 83%;
        //       height: 250px;
        //     }
        //   }
    
        //   .col-office-2 {
        //     .office3 {
        //       width: 96%;
        //       height: 250px;
        //       margin-bottom: 18px;
        //     }
        //   }
    
        //   .col-office-3 {
        //     .office4 {
        //       margin-bottom: 14px;
        //       width: 83%;
        //       height: 250px;
        //     }
        //     .office5 {
        //       width: 83%;
        //       height: 250px;
        //     }
        //   }
        // }
      }
    }
}
}
  
@media screen and (min-width: 580px) and (max-width: 750px) {
/*content*/
.content {
    .container-content {
      .row-content{
      padding-top: 20px;
      .content-title {
        padding-top: 50px;
        text-align: center;
        .title-1 {
          font-family: "Montserrat", sans-serif;
          font-size: 25px;
          font-weight: bold;
          color: #272727;
       
          .title-2 {
            font-family: "Montserrat", sans-serif;
            font-size: 25px;
            font-weight: bold;
            color: #1572a1;
          }
        }
    
        .title-3 {
          font-family: "Nunito Sans", sans-serif;
          font-size: 15px;
          font-weight: 400;
          color: #999999;
        }
    
        .button-join {
          background-color: #1572a1;
          border: none;
          border-radius: 10px;
          height: 40px;
          width: 140px;
          margin-top: 2px;
          margin-bottom: 16px;
          .join {
            color: #ffffff;
            font-family: "Nunito Sans", sans-serif;
            font-weight: bold;
            text-decoration: none;
            font-size: 12px;
          }
        }
    
        .button-join:hover {
          background-color: #014161;
          border: none;
          border-radius: 10px;
          height: 40px;
          width: 140px;
        }
        .drop-resume {
          .title-1 {
            font-family: "Nunito Sans", sans-serif;
            font-weight: 400;
            font-size: 12px;
            color: #999999;
            .title-2 {
              text-decoration: none;
              font-family: "Nunito Sans", sans-serif;
              font-weight: 400;
              font-size: 12px;
            }
          }
        }
      }
    
      .content-image {
        padding-top: 10px;
        padding-left: 30px;
        padding-right: 30px;
        height: 300px;
        width: 100%;
      }
    }
      .ava-vacancy {
        .ava-vacancy-title {
          .ava-vacancy-title-1 {
            color: #272727;
            font-family: "Montserrat", sans-serif;
            font-size: 25px;
            font-weight: bold;
            text-align: center;
            margin-top: 40px;
          }
        }
        .row-vacancy {
          margin-bottom: 120px;
          .col-vacancy {
            .card {
              height: 250px;
              width: 100%;
              background-color: #e3f6ff;
              border-radius: 10px;
              border: none;
              margin-top: 32px;
              .vacancy-img {
                height: 50px;
                width: 150px;
                position: relative;
                margin-top: auto;
                margin-bottom: auto;
                margin-right: auto;
                margin-left: auto;
              }
            }
            .card:hover {
              height: 250px;
              width: 100%;
              background-color: #c7eafb;
              border-radius: 10px;
              border: none;
              margin-top: 32px;
              .vacancy-img {
                height: 50px;
                width: 150px;
                position: relative;
                margin-top: auto;
                margin-bottom: auto;
                margin-right: auto;
                margin-left: auto;
              }
            }
          }
        }
      }
      .get {
        margin-bottom: 50px;
        text-align: center;
        .get-title {
          .get-title-1 {
            color: #272727;
            font-family: "Montserrat", sans-serif;
            font-size: 25px;
            font-weight: bold;
            margin-top: 40px;
          }
        }
        .row-get {
          .col-get {
            .get-content {
              color: #999999;
              font-family: "Nunito Sans", sans-serif;
              font-size: 14px;
              font-weight: 500;
              margin-top: 20px;
              text-align: justify;
            }
            .get-img {
              .get-img-content {
                height: 256px;
                width: 100%;
              }
            }
          }
        }
      }
      .skil {
        .row-skil {
          .col-skil {
            .card-skil {
              height: 400px;
              width: 100%;
              background-color: #e3f6ff;
              border: none;
              border-radius: 10px;
     
              padding-left: 6px;
              padding-right: 6px;
              .image-skil {
                height: 100px;
                width: 120px;
                position: relative;
                margin-left: auto;
                margin-right: auto;
              }
              .title-skil {
                font-size: 14px;
                font-weight: 600;
                color: #272727;
                font-family: "Nunito Sans", sans-serif;
                text-align: center;
             
              }
              .content-skil {
                font-size: 11px;
                font-weight: 400;
                color: #999999;
                font-family: "Nunito Sans", sans-serif;
                text-align: center;
              }
            }
          }
        }
      }
    
      .office {
        .office-title {
          .head {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-size: 25px;
            line-height: 48px;
            text-align: center;
            letter-spacing: -0.022em;
            color: #272727;
            margin-top: 44px;
            margin-bottom: 20px;
          }
          .content {
            font-family: "Nunito Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            text-align: center;
            letter-spacing: -0.019em;
            color: #999999;
          }
        }
        .images{
          margin-top: 30px;
          text-align: center;
          .images-result{
            width: 100%;
            height: 350px;
          }
        }
        .flex-row{
          justify-content: center;
          display: flex;
          padding: 20px;
          overflow-x: scroll;
          overflow-y: hidden;
          .thumb{
            margin-left: 10px;
            border: none;
            .thumb-result{
              width: 105px;
            }
          }
        
        }
        ::-webkit-scrollbar {
          height: 0px;
        }
        ::-webkit-scrollbar-track {
          background: #ffffff; 
          border-radius: 10px;
        }
        ::-webkit-scrollbar-thumb {
          background: #888; 
          border-radius: 10px;
        }
        ::-webkit-scrollbar-thumb:hover {
          background: #555; 
        }
        // .row-office {
        //   margin-top: 30px;
        //   text-align: center;
        //   .col-office-1 {
        //     .office1 {
        //       margin-bottom: 14px;
        //       width: 83%;
        //       height: 250px;
        //     }
        //     .office2 {
        //       margin-bottom: 14px;
        //       width: 83%;
        //       height: 250px;
        //     }
        //   }
    
        //   .col-office-2 {
        //     .office3 {
        //       width: 95%;
        //       height: 250px;
        //       margin-bottom: 14px;
        //     }
        //   }
    
        //   .col-office-3 {
        //     .office4 {
        //       margin-bottom: 14px;
        //       width: 83%;
        //       height: 250px;
        //     }
        //     .office5 {
        //       width: 83%;
        //       height: 250px;
        //     }
        //   }
        // }
      }
    }
}
}
  
@media screen and (min-width: 750px) and (max-width: 1199px) {
/*content*/
.content {
    .container-content {
      .row-content{ 
      .content-title {
        padding-top: 50px;
        text-align: center;
        .title-1 {
          font-family: "Montserrat", sans-serif;
          font-size: 25px;
          font-weight: bold;
          color: #272727;
       
          .title-2 {
            font-family: "Montserrat", sans-serif;
            font-size: 25px;
            font-weight: bold;
            color: #1572a1;
          }
        }
    
        .title-3 {
          font-family: "Nunito Sans", sans-serif;
          font-size: 15px;
          font-weight: 400;
          color: #999999;
        }
    
        .button-join {
          background-color: #1572a1;
          border: none;
          border-radius: 10px;
          height: 40px;
          width: 140px;
          margin-top: 2px;
          margin-bottom: 16px;
          .join {
            color: #ffffff;
            font-family: "Nunito Sans", sans-serif;
            font-weight: bold;
            text-decoration: none;
            font-size: 12px;
          }
        }
    
        .button-join:hover {
          background-color: #014161;
          border: none;
          border-radius: 10px;
          height: 40px;
          width: 140px;
        }
        .drop-resume {
          .title-1 {
            font-family: "Nunito Sans", sans-serif;
            font-weight: 400;
            font-size: 12px;
            color: #999999;
            .title-2 {
              text-decoration: none;
              font-family: "Nunito Sans", sans-serif;
              font-weight: 400;
              font-size: 12px;
            }
          }
        }
      }
    
      .content-image {
        .img-dash{
          padding-top: 40px;
          height: 300px;
        }
      }
    }
      .ava-vacancy {
        .ava-vacancy-title {
          .ava-vacancy-title-1 {
            color: #272727;
            font-family: "Montserrat", sans-serif;
            font-size: 25px;
            font-weight: bold;
            text-align: center;
            margin-top: 40px;
          }
        }
        .row-vacancy {
          margin-bottom: 120px;
          .col-vacancy {
            .card {
              width: 100%;
              background-color: #e3f6ff;
              border-radius: 10px;
              border: none;
              margin-top: 32px;
              .vacancy-img {
                height: 50px;
                width: 150px;
                position: relative;
                margin-top: auto;
                margin-bottom: auto;
                margin-right: auto;
                margin-left: auto;
              }
            }
            .card:hover {
              height: 250px;
              width: 100%;
              background-color: #c7eafb;
              border-radius: 10px;
              border: none;
              margin-top: 32px;
              .vacancy-img {
                height: 50px;
                width: 150px;
                position: relative;
                margin-top: auto;
                margin-bottom: auto;
                margin-right: auto;
                margin-left: auto;
              }
            }
          }
        }
      }
      .get {
        margin-bottom: 50px;
        text-align: center;
        .get-title {
          .get-title-1 {
            color: #272727;
            font-family: "Montserrat", sans-serif;
            font-size: 25px;
            font-weight: bold;
            margin-top: 40px;
          }
        }
        .row-get {
          .col-get {
            .get-content {
              color: #999999;
              font-family: "Nunito Sans", sans-serif;
              font-size: 14px;
              font-weight: 500;
              margin-top: 20px;
              text-align: justify;
            }
            .get-img {
              .get-img-content {
                height: 256px;
                width: 100%;
              }
            }
          }
        }
      }
      .skil {
        .row-skil {
          .col-skil {
            .card-skil {
              height: relative;
              width: 100%;
              background-color: #e3f6ff;
              border: none;
              border-radius: 10px;
              padding-top: 10px;
              padding-left: 6px;
              padding-right: 6px;
              .image-skil {
                height: 100px;
                width: 120px;
                position: relative;
                margin-left: auto;
                margin-right: auto;
              }
              .title-skil {
                font-size: 18px;
                font-weight: 600;
                color: #272727;
                font-family: "Nunito Sans", sans-serif;
                text-align: center;
                margin-top: 40px;
              }
              .content-skil {
                font-size: 12px;
                font-weight: 400;
                color: #999999;
                font-family: "Nunito Sans", sans-serif;
                text-align: center;
              }
            }
          }
        }
      }
    
      .office {
        .office-title {
          .head {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-size: 25px;
            line-height: 48px;
            text-align: center;
            letter-spacing: -0.022em;
            color: #272727;
            margin-top: 44px;
            margin-bottom: 20px;
          }
          .content {
            font-family: "Nunito Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
            text-align: center;
            letter-spacing: -0.019em;
            color: #999999;
          }
        }

        .images{
          margin-top: 30px;
          text-align: center;
          .images-result{
            width: 100%;
            height: 400px;
          }
        }
        .flex-row{
          justify-content: center;
          display: flex;
          padding: 20px;
          overflow-x: scroll;
          overflow-y: hidden;
          .thumb{
            margin-left: 10px;
            border: none;
            .thumb-result{
              width: 105px;
            }
          }
        
        }
        ::-webkit-scrollbar {
          height: 0px;
        }
        ::-webkit-scrollbar-track {
          background: #ffffff; 
          border-radius: 10px;
        }
        ::-webkit-scrollbar-thumb {
          background: #888; 
          border-radius: 10px;
        }
        ::-webkit-scrollbar-thumb:hover {
          background: #555; 
        }
        // .row-office {
        //   margin-top: 30px;
        //   text-align: center;
        //   .col-office-1 {
        //     .office1 {
        //       margin-bottom: 14px;
        //       width: 100%;
        //       height: 250px;
        //     }
        //     .office2 {
        //       margin-bottom: 14px;
        //       width: 100%;
        //       height: 250px;
        //     }
        //   }
    
        //   .col-office-2 {
        //     .office3 {
        //       width: 100%;
        //       height: 98%;
        //       margin-bottom: 14px;
        //     }
        //   }
    
        //   .col-office-3 {
        //     .office4 {
        //       margin-bottom: 14px;
        //       width: 100%;
        //       height: 250px;
         
        //     }
        //     .office5 {
        //       width: 100%;
        //       height: 100%;
        //     }
        //   }
        // }
      }
    }
}
  
} 
   
