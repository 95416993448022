/*navbar*/
.navbar-atas{
    z-index: 999;
  .navbar {
    background-color: #ffffff;
    margin-bottom: 55px;
    top: 0;
    width: 100%;
    border: none;
  
  
    .navbar-brand img {
      width: 150px;
      height: 42px;
    }
    .navbar-nav {
      font-family: "Nunito Sans", sans-serif;
      font-size: 16px;
      font-weight: 700;
      color: #044f9f;
      text-align: center;
    }
  
    .navbar-collapse .navbar-nav .nav-link:hover {
      font-family: "Nunito Sans", sans-serif;
      font-size: 16px;
      font-weight: 700;
      color: #044f9f;
      text-align: center;
    }
  
    .navbar-collapse .navbar-nav a.nav-link .active {
      font-family: "Nunito Sans", sans-serif;
      font-size: 16px;
      font-weight: 700;
      color: #044f9f;
      text-align: center;
    }
  
    .navbar-collapse .navbar-nav a {
      font-family: "Nunito Sans", sans-serif;
      font-size: 16px;
      font-weight: 700;
      color: #000000;
      text-align: center;
      text-decoration: none;
    }
  
    .navbar-nav-mobile {
      display: none;
    }
  
   
  }
}

/*reponsive*/
@media screen and (min-width: 240px) and (max-width: 580px) { 
/*navbar*/
.navbar-atas{
    z-index: -999;
  .navbar {
    background-color: #ffffff;
    margin-bottom: 55px;
    top: 0;
    width: 100%;
    border: none;
    .navbar-brand img {
      width: 105px;
      height: 30px;
    }
    .navbar-nav {
  display: none;
    }
  
    .navbar-nav-mobile {
      display: block;
      font-family: "Nunito Sans", sans-serif;
      font-size: 16px;
      font-weight: 700;
      color: #044f9f;
      text-align: center;
    }
  
    .navbar-collapse .navbar-nav-mobile .nav-link:hover {
      font-family: "Nunito Sans", sans-serif;
      font-size: 16px;
      font-weight: 700;
      color: #044f9f;
      text-align: center;
    }
  
    .navbar-collapse .navbar-nav-mobile a.nav-link .active {
      font-family: "Nunito Sans", sans-serif;
      font-size: 16px;
      font-weight: 700;
      color: #044f9f;
      text-align: center;
    }
  
    .navbar-collapse .navbar-nav-mobile a {
      font-family: "Nunito Sans", sans-serif;
      font-size: 16px;
      font-weight: 700;
      color: #000000;
      text-align: center;
      text-decoration: none;
    }
  }
}

}
  
@media screen and (min-width: 580px) and (max-width: 750px) {

/*navbar*/
.navbar-atas{
    z-index: -999;
  .navbar {
    background-color: #ffffff;
    margin-bottom: 55px;
    top: 0;
    width: 100%;
    border: none;
    .navbar-brand img {
      width: 140px;
      height: 40px;
    }
    .navbar-nav {
  display: none;
    }
  
    .navbar-nav-mobile {
      display: block;
      font-family: "Nunito Sans", sans-serif;
      font-size: 16px;
      font-weight: 700;
      color: #044f9f;
      text-align: center;
    }
  
    .navbar-collapse .navbar-nav-mobile .nav-link:hover {
      font-family: "Nunito Sans", sans-serif;
      font-size: 16px;
      font-weight: 700;
      color: #044f9f;
      text-align: center;
    }
  
    .navbar-collapse .navbar-nav-mobile a.nav-link .active {
      font-family: "Nunito Sans", sans-serif;
      font-size: 16px;
      font-weight: 700;
      color: #044f9f;
      text-align: center;
    }
  
    .navbar-collapse .navbar-nav-mobile a {
      font-family: "Nunito Sans", sans-serif;
      font-size: 16px;
      font-weight: 700;
      color: #000000;
      text-align: center;
      text-decoration: none;
    }
  }
}
  
}

@media screen and (min-width: 750px) and (max-width: 991px){
/*navbar*/
.navbar-atas{
      z-index: -999;
    .navbar {
      background-color: #ffffff;
      margin-bottom: 55px;
      top: 0;
      width: 100%;
      border: none;
      .navbar-brand img {
        width: 140px;
        height: 40px;
      }
      .navbar-nav {
    display: none;
      }
    
      .navbar-nav-mobile {
        display: block;
        font-family: "Nunito Sans", sans-serif;
        font-size: 16px;
        font-weight: 700;
        color: #044f9f;
        text-align: center;
      }
    
      .navbar-collapse .navbar-nav-mobile .nav-link:hover {
        font-family: "Nunito Sans", sans-serif;
        font-size: 16px;
        font-weight: 700;
        color: #044f9f;
        text-align: center;
      }
    
      .navbar-collapse .navbar-nav-mobile a.nav-link .active {
        font-family: "Nunito Sans", sans-serif;
        font-size: 16px;
        font-weight: 700;
        color: #044f9f;
        text-align: center;
      }
    
      .navbar-collapse .navbar-nav-mobile a {
        font-family: "Nunito Sans", sans-serif;
        font-size: 16px;
        font-weight: 700;
        color: #000000;
        text-align: center;
        text-decoration: none;
      }
    }
}
    
}

