/*verifikasi*/
.verifikasi {
    .container {
      .row-verifikasi {
        .col-verifikasi {
          .verifikasi-image {
            height: relative;
            width: 60%;
            margin: 0 auto;
            display: block;
          }
          .verifikasi-title {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            text-align: center;
            color: #272727;
          }
        }
      }
      .message {
        text-align: center;
        font-family: "Nunito Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #999999;
        .message-1 {
          color: #272727;
        }
      }
      .countdown{
        text-align: center;
        font-size: 18px;
        .hours{
          color: red;
        }
      }
      .verifikasi-btn {
        margin-top: -20px;
        display: grid;
        place-items: center;
        .verifikasi-btn-1 {
          width: 181px;
          height: 30px;
          border: none;
          border-radius: 10px;
          background-color: #1572a1;
          color: #f9f9f9;
        }
      }
    }
}

/*reponsive*/
@media screen and (min-width: 240px) and (max-width: 580px) {
/*verifikasi*/
.verifikasi {
      .container {
        .row-verifikasi {
          .col-verifikasi {
            margin-top: 100px;
            .verifikasi-image {
              height: relative;
              width: 80%;
              padding-right: 10px;
              padding-left: 10px;
            }
            .verifikasi-title {
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              text-align: center;
              margin-top: 28px;
              color: #272727;
            }
          }
        }
        .message {
          text-align: center;
          font-family: "Nunito Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #999999;
          margin-top: 28px;
          .message-1 {
            color: #272727;
          }
        } .countdown{
          text-align: center;
          font-size: 14px;
          .hours{
            color: red;
          }
        }
        .verifikasi-btn {
          display: grid;
          place-items: center;
          .verifikasi-btn-1 {
            width: 120px;
            height: 30px;
            border: none;
            border-radius: 10px;
            background-color: #1572a1;
            margin-top: 30px;
            font-size: 12px;
            color: #f9f9f9;
          }
        }
      }
}
}
  
@media screen and (min-width: 580px) and (max-width: 750px) {
/*verifikasi*/
.verifikasi {
      .container {
        .row-verifikasi {
          .col-verifikasi {
            margin-top: 100px;
            .verifikasi-image {
              height: relative;
              width: 80%;
              padding-right: 60px;
              padding-left: 60px;
            }
            .verifikasi-title {
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              text-align: center;
              margin-top: 28px;
              color: #272727;
            }
          }
        }
        .message {
          text-align: center;
          font-family: "Nunito Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #999999;
          margin-top: 28px;
          .message-1 {
            color: #272727;
          }
        }
        .countdown{
          text-align: center;
          font-size: 16px;
          .hours{
            color: red;
          }
        }
        .verifikasi-btn {
          display: grid;
          place-items: center;
          .verifikasi-btn-1 {
            width: 120px;
            height: 30px;
            border: none;
            border-radius: 10px;
            background-color: #1572a1;
            margin-top: 30px;
            font-size: 12px;
            color: #f9f9f9;
          }
        }
      }
}
  
}
  
@media screen and (min-width: 750px) and (max-width: 1199px) {
/*verifikasi*/
.verifikasi {
      .container {
        .row-verifikasi {
          .col-verifikasi {
            margin-top: 100px;
            .verifikasi-image {
              height: relative;
              width: 80%;
              padding-right: 40px;
              padding-left: 40px;
            }
            .verifikasi-title {
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 700;
              font-size: 24px;
              text-align: center;
              margin-top: 28px;
              color: #272727;
            }
          }
        }
        .message {
          text-align: center;
          font-family: "Nunito Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #999999;
          margin-top: 28px;
          .message-1 {
            color: #272727;
          }
        }  
        .countdown{
          text-align: center;
          font-size: 18px;
          .hours{
            color: red;
          }
        }
        .verifikasi-btn {
          display: grid;
          place-items: center;
          .verifikasi-btn-1 {
            width: 120px;
            height: 40px;
            border: none;
            border-radius: 10px;
            background-color: #1572a1;
            margin-top: 30px;
            font-size: 12px;
            color: #f9f9f9;
          }
        }
      }
}

}  
